import Login from "../../components/Login"
import { Container, SigninContainer } from "./SignInElements"

const SignIn = () => {
    return (
        <Container>
            <SigninContainer>
                <Login/>
            </SigninContainer>
        </Container>
    )
}

export default SignIn
