import http from "../http-common";
import authHeader from "./auth-header";

class GeneralaService{


    addGeneralLinks(data){
        return http.post(`/generalLinks/add-link`, JSON.stringify(data),{headers: authHeader(), withCredentials: true});

    }

    getGeneralLinks(){
        return http.get(`/generalLinks/all`);
    }


    updateGeneralLinks(id, data){
        return http.put(`/generalLinks/update/${id}`, JSON.stringify(data),{headers: authHeader(), withCredentials: true});

    }


    addSocialLinks(data){
        return http.post(`/socialLinks/add-link`, JSON.stringify(data),{headers: authHeader(), withCredentials: true});

    }

    getSocialLinks(){
        return http.get(`/socialLinks/all`);
    }


    updateSocialLinks(id, data){
        return http.put(`/socialLinks/update/${id}`, JSON.stringify(data),{headers: authHeader(), withCredentials: true});

    }


    

  
}


export default new GeneralaService();