import styled from "styled-components";

export const TermsContainer = styled.div`
    width: 100%;
    max-width: 1100px;

    @media screen and (max-width: 768px){
        margin: 20px;
    }
    animation: slide-in-top 500ms;
`

export const TermsTitle = styled.h1`
    color: #263238;
`

export const TermsDescription = styled.p`
    font-weight: 200;
    font-size: 1rem;
    line-height: 1.5;
    word-spacing: .2rem;
    color: #455A64;
`