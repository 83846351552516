import { Link as LinkR } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  background-image: linear-gradient(#fff, #b3e5fc);
  animation: slide-in-top 500ms;
`;
export const ContentContainer = styled.div`
  width: 100%;
  max-width: 1100px;
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
`;

export const Linkpath = styled.p`
  color: #212121;
`;

export const DetailsContainer = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 24px;
  align-items: center;
`;

export const SpecialDetailsContainer = styled.div`
  display: flex;
  gap: 2rem;
  margin-top: 24px;
  align-items: center;
  align-self: center;
`;
export const CourseIcon = styled.img`
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  width: 120px;
  height: 120px;
  object-fit: cover;
  background: #fff;
  border-radius: 12px;
`;
export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const CourseTitle = styled.h2`
  color: #08569a;
  margin: 0;
  text-transform: capitalize;
`;

export const CourseDescription = styled.p`
  color: #bdbdbd;
  margin: 0;
`;

export const ContactContainer = styled.div`
  display: flex;
  gap: 2rem;
`;
export const DetailsItem = styled.p`
  background: #fff;
  padding: 12px 18px;
  border-radius: 8px;
  margin: 0;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  cursor: default;
`;

export const CourseLink = styled(LinkR)`
   background: #0476BD;
  padding: 12px 18px;
  border-radius: 8px;
  margin: 0;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
`