import {
  CopyrightText,
  FooterContainer,
  NavButton,
  NavButtons,
  SocialLink,
  SocialLogo,
  SocialLogos,
  WLogo,
} from "./FooterElements";
import logo from "../../assets/logos/logo_white.svg";
import logoFb from "../../assets/logos/ic_fb.svg";
import logoInsta from "../../assets/logos/ic_insta.svg";
import logoTwitter from "../../assets/logos/ic_twitter.svg";
import logoYoutube from "../../assets/logos/ic_youtube.svg";
import generalService from "../../services/general.service";
import { useEffect, useState } from "react";

const Footer = () => {
  const [links, setLinks] = useState({
    fb: "",
    insta: "",
    twitter: "",
    youtube: "",
  });

  useEffect(() => {
    getTestLink();
  }, []);
  const getTestLink = () => {
    generalService
      .getSocialLinks()
      .then((res) => {
        setLinks({
          fb: res.data.fb,
          insta: res.data.insta,
          twitter: res.data.twitter,
          youtube: res.data.youtube,
        });
      })
      .catch(() => {});
  };
  return (
    <FooterContainer>
      <WLogo src={logo} />
      <NavButtons>
        <NavButton to="/">Home</NavButton>
        <NavButton to="/chiraku">Chiraku</NavButton>
        <NavButton to="/courses/categories">Courses</NavButton>
        <NavButton to="/publications">Publications</NavButton>
        <NavButton to="/contact">Contact Us</NavButton>
      </NavButtons>
      <NavButtons>
        <NavButton to="/privacy-policy">Privacy Policy</NavButton>
        <NavButton to="/terms-and-conditions">Terms & Conditions</NavButton>
      </NavButtons>
      <SocialLogos>
        {links.fb && (
          <SocialLink target="_blank" href={links.fb}>
            <SocialLogo src={logoFb} />
          </SocialLink>
        )}
        {links.insta && (
          <SocialLink target="_blank" href={links.insta}>
            <SocialLogo src={logoInsta} />
          </SocialLink>
        )}
        {links.twitter && (
          <SocialLink target="_blank" href={links.twitter}>
            <SocialLogo src={logoTwitter} />
          </SocialLink>
        )}
        {links.youtube && (
          <SocialLink
            target="_blank"
            href={links.youtube}
          >
            <SocialLogo src={logoYoutube} />
          </SocialLink>
        )}
      </SocialLogos>

      <CopyrightText>
        ©2022-23 All rights reserved, Cubit Academy.
      </CopyrightText>
    </FooterContainer>
  );
};

export default Footer;
