import styled from "styled-components";

export const ContentContainer = styled.div`
  width: 100%;
  max-width: 1100px;
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  animation: slide-in-top 500ms;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const AchieverContainer = styled.div`
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media screen and (max-width: 768px) {
    width: 100%;
    gap: .5rem;
  }
`;
export const CenterPanel = styled.div`
  flex: 5;
  
`;

export const RightPanel = styled.div`
  flex: 2;
  background: #f5f5f5;
  padding: 12px;
  border-radius: 12px;
  text-align: center;
`;
export const TestimonialContainer = styled.div``;

export const RightPanelTitle = styled.h4``;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const TabContainer =styled.div`

`
export const Desc = styled.p`
  color: #616161;
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5625;
  margin-bottom: 15px;
  padding: 1rem;
`;

export const EmbedContainer = styled.div`
display: flex;
flex-direction: column;
gap: 24px;
align-items: center;
    flex:1;
    @media screen and (max-width: 768px){
        width: 100%;
    }
    animation: slide-in-right 500ms;
`