import styled from "styled-components";

export const Container = styled.div`
    padding: 1rem;
    background: #E1F5FE;
    flex: 1;
    text-align: center;
    margin-top: 12px;
    animation: zoom-in 500ms;
`

export const MainTitle = styled.h2`
    text-transform: capitalize;
`
export const SubTitle = styled.p`
    text-transform: capitalize;
    color: #A6A6A6;
    font-weight: 500;
`
export const SubTitleCotainer = styled.div`
    display: flex;
    gap: .5rem;
`