import styled from "styled-components";

export const FormAreaContainer = styled.div`
    flex: 1;
    gap: 1rem;
`

export const FormContainer = styled.div`
    background: #fff;
    border-radius: .5rem;
    border: 1px solid #EDEDED;
    padding: 1rem;
    margin-top: 1rem;
`
export const FormItem = styled.div`
    margin-bottom: 1rem;
    display: flex;
    margin-top: 1rem;

    @media screen and (max-width: 768px){
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }
`
export const FormTitle = styled.p`

    width: 20%;
    font-weight: 500;

    @media screen and (max-width: 768px){
        width: 100%;
    }
    
`
export const ImagePickerContainer = styled.div`

    background: #F8FAFC;
    border: 1px dashed #D5D5D5;
    width: 180px;
    height: 180px;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #D1D1D1;
    cursor: pointer;
    position: relative;
    overflow: hidden;
`
export const PickerIcon = styled.img`
    width: 75px;
`

export const PickerText = styled.p`
    position: absolute;
    bottom: 1rem;
    
`

export const PickedImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    
`

export const InputText = styled.input`
    outline: none;
    border: none;
    border-radius: 4px;
    background: #F8FAFC;
    padding: 10px 18px;
    font-size: 1rem;
    font-weight: 500;
    width: 45%;
    &::placeholder{
        font-weight: 500;
        
    }
    @media screen and (max-width: 768px){
        width: 100%;
    }
`

export const InputTextArea = styled.textarea`
    outline: none;
    border: none;
    border-radius: 4px;
    background: #F8FAFC;
    padding: 10px 18px;
    font-size: 1rem;
    font-weight: 500;
    width: 45%;
    &::placeholder{
        font-weight: 500;
        
    }
    @media screen and (max-width: 768px){
        width: 100%;
    }
`

export const PriceInputContainer = styled.div`
    background: #F8FAFC;
    border-radius: 4px;
    padding: 10px  16px;
    display: flex;
    width: 45%;
    @media screen and (max-width: 768px){
        width: 100%;
    }
`
export const CurrencyType = styled.p`
    padding-right: 16px;
    margin: 0;
    font-weight: 500;
`
export const CategoriesInput = styled.select`
    width: 100%;
    border: 0;
    background: #F8FAFC;
    font-size: bold;
    outline: none;
    font-weight: 500;
    font-size: 1rem;
    cursor: pointer;
`
export const CategoriesOption = styled.option`
    
    &:first-child{
        color: rgba(0,0,0,0.3);
    }
    
`

export const ButtonContainer = styled.div`
    display: flex;
    gap: 1rem;
    margin-left: 15%;
    margin-top: 1rem;
`
export const BtnCancel = styled.button`
    color: #FC3535;
    box-sizing:border-box;
    border: 2px solid #FC3535;
    background: #fff;
    border-radius: 4px;
    padding: 8px 16px;
    font-weight: bold;
    cursor: pointer;
`
export const BtnSubmit = styled.button`
    color: #fff;
    box-sizing:border-box;
    font-size: 1rem;
    border:0;
    background: #262626;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
`
export const BtnUpload = styled.button`
    color: #fff;
    box-sizing:border-box;
    font-size: 1rem;
    background: #262626;
    border:0;
    border-radius: 4px;
    padding: 8px 16px;
    font-weight: bold;
    cursor: pointer;
    align-self: center;
    margin-left: 1rem;
`