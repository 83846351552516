import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { ADD_NEWS, EDIT_NEWS } from "../../actions/types";
import icPicker from "../../assets/icons/ic_image_picker.svg";
import DashContentTitle from "../../components/DashContentTitles";
import ModalResult from "../../components/ModalResult";
import SelectInput from "../../components/SelectInput/SelectInput";
import TextareaInput from "../../components/TextareaInput/Textareainput";
import TextinputHorizontal from "../../components/TextInputHorizontal/TextinputHorizontal";
import categoryService from "../../services/category.service";
import courseService from "../../services/course.service";
import newsService from "../../services/news.service";
import ProductsService from "../../services/products-service";
import UploadImagesService from "../../services/upload-files-service";
import {
  BtnCancel,
  BtnSubmit,
  BtnUpload,
  ButtonContainer, FormAreaContainer,
  FormContainer,
  FormItem,
  FormTitle,
  ImagePickerContainer, PickedImage,
  PickerIcon,
  PickerText
} from "./AddNewsElements";

const AddNews = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id} = useParams();

  const imageInput = useRef(null);

  const [modal, showModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const [data, setData] = useState({
    photo: "",
    title: "",
    description: "",
    link: "",
    vacancies: "",
    lDate: "",
    sDate: "",
    course: "",
  });

  const [fieldstatustext, setFieldstatusText] = useState({
    photo: "",
    title: "",
    description: "",
    link: "",
    vacancies: "",
    lDate: "",
    sDate: "",
    course: "",
  });

  const [pId, setPId] = useState();

  const [courseNames, setCourseNames] = useState([]);

  const [newsType, setNewsType] = useState("");
  const [category, setCategory] = useState({
    name: "",
    _id:""
  });
  const [newsTypes, setNewsTypes] = useState([]);
  const [photo, setPhoto] = useState();
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [uploadImageStatus, setUploadImageStatus] = useState(false);

  useEffect(() => {
    getNewsTypes();
    getCourseNames();
    if (id) {
      dispatch({
        type: EDIT_NEWS,
      });
  
      getDetails();
    } else {
      dispatch({
        type: ADD_NEWS,
      });
  
    }
    
  }, []);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  const getDetails = () => {
    newsService
      .getNewsById(id)
      .then((res) => {
        
        // setData({
        //   photo: res.data.photo,
        //   title: res.data.title,
        //   description: res.data.description,
        // });
        setData(res.data);
        setNewsType(res.data.category._id);
        setPId(res.data._id);
        setPhoto(res.data.photo);
      })
      .catch(() => {});
  };

  const getCourseNames = (catID) => {
    courseService.getAllCourses().then((res) => {
      setCourseNames(
        res.data.map((cat) => ({ value: cat._id, title: cat.title }))
      );
    });
  };

  const getNewsTypes = (catID) => {
    categoryService.getAllNewsTypes().then((res) => {
      
      setNewsTypes(
        res.data.map((cat) => ({ value: cat._id, title: cat.name }))
      );
      setNewsType(res.data.find((item) => item.name === "Job Notification")._id)
      setCategory(res.data.filter((item)=> item.name === "Job Notification")[0]);
    });
  };

  const onChangePhoto = (e) => {
    if (e.target.files && e.target.files[0]) {
      setPhoto(URL.createObjectURL(e.target.files[0]));
      setSelectedPhoto(e.target.files[0]);
      setData({
        ...data,
        photo: "",
      });
    }
  };

  const handleInputChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    setData({
      ...data,
      [name]: value,
    });
  };

  const handleModalClose = (e) => {
    showModal(false);
    if (id) {
      navigate(-1);
    }
  };

  const handleCategoryChange = (e) => {
    setNewsType(e.target.value);
  };

  const addNewProduct = () => {
    if (validateForm()) {
      let news;

      newsType == category._id
        ? (news = {
            photo: data.photo,
            title: data.title,
            description: data.description,
            category: newsType,
            link: data.link,
            vacancies: data.vacancies,
            date: data.lDate,
          })
        : (news = {
            photo: data.photo,
            title: data.title,
            description: data.description,
            category: newsType,
            course: data.course,
            date: data.sDate,
          });
      newsService
        .addNews(news)
        .then((res) => {
          setModalMessage("News added successfully!");
          showModal(true);
          onReset();
        })
        .catch((err) => console.log(err));
    }
  };

  const updateProduct = () => {
    if (validateForm()) {
      newsService
      .updateNews(id, data)
      .then((res) => {
        setModalMessage("News details updated successfully!");
        showModal(true);
      })
      .catch((err) => console.log(err));
    }
  };

  const deleteProduct = () => {
    ProductsService.deleteProduct(category, pId)
      .then((res) => {
        //    setAlert(true);
        //    props.onDelete(res.data._id);
        let nameSplit = data.photos[0].split("/");
        let key = nameSplit[nameSplit.length - 1];
        ProductsService.deleteUploadedImages([key])
          .then((result) => {})
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  const onCancel = () => {
    if (id) {
      navigate(-1);
      return;
    }

    if (data.photo !== "") {
      let nameSplit = data.photo.split("/");
      let key = nameSplit[nameSplit.length - 1];
      UploadImagesService.deleteUploadedImages([key])
        .then((result) => {})
        .catch((err) => console.log(err));
      setData({
        photo: "",
        title: "",
        description: "",
        link: "",
        vacancies: "",
        lDate: "",
        sDate: "",
        course: "",
      });
    } else {
      setData({
        photo: "",
        title: "",
        description: "",
        link: "",
        vacancies: "",
        lDate: "",
        sDate: "",
        course: "",
      });
    }

    setPhoto(null);
    setSelectedPhoto(null);
  };

  const onReset = () => {
    setData({
      photo: "",
      title: "",
      description: "",
      link: "",
      vacancies: "",
      lDate: "",
      sDate: "",
      course: "",
    });
    setFieldstatusText({
      photo: "",
      title: "",
      description: "",
      link: "",
      vacancies: "",
      lDate: "",
      sDate: "",
      course: "",
    });
    setPhoto(null);
    setSelectedPhoto(null);
  };

  const uploadImage = () => {
    if (selectedPhoto === null) {
      setFieldstatusText({
        ...fieldstatustext,
        photos: {
          text: "No photos selected!",
          color: "#B71C1C",
        },
      });
      return;
    }

    UploadImagesService.upload(selectedPhoto, (event) => {
      setUploadImageStatus(true);
    })
      .then((res) => {
        setData({
          ...data,
          photo: res.data.Location,
        });
        setFieldstatusText({
          ...fieldstatustext,
          photos: {
            text: "Photos Uploaded Successfully!",
            color: "#2E7D32",
          },
        });
        setUploadImageStatus(false);
      })
      .catch((err) => {
        setFieldstatusText({
          ...fieldstatustext,
          photos: {
            text: "Please try agin. Photos not uploaded!",
            color: "#F57F17",
          },
        });
        setUploadImageStatus(false);
      });
  };

  const validateForm = () => {
    if (data.photo === "") {
      setFieldstatusText({
        ...fieldstatustext,
        photo: {
          text: "No Photo uploaded!",
          color: "#2E7D32",
        },
      });
      alert("No Photo uploaded!");
      return false;
    }

    if (data.title === "") {
      setFieldstatusText({
        ...fieldstatustext,
        title: "* required",
      });
      alert("Title required!");
      return false;
    }

    if (data.course === "" && newsType == 'batch') {
      setFieldstatusText({
        ...fieldstatustext,
        course: "* required",
      });
      alert("Course required!");
      return false;
    }
    return true;
  };

  return (
    <FormAreaContainer>
      {id ? (
        <DashContentTitle title="Update product" />
      ) : (
        <DashContentTitle title="add products" />
      )}

      <FormContainer>
        <FormItem>
          <FormTitle>Cover Image :</FormTitle>

          <ImagePickerContainer onClick={() => imageInput.current.click()}>
            {photo ? (
              <>
                <PickedImage src={photo} />
                <PickerText
                  style={{
                    background: "rgba(0,0,0,0.3)",
                    padding: ".3rem .6rem",
                    borderRadius: ".3rem",
                  }}
                >
                  Change Image
                </PickerText>
              </>
            ) : (
              <>
                <PickerIcon src={icPicker} />
                <PickerText>Upload Image</PickerText>
              </>
            )}
          </ImagePickerContainer>
          {data.photo === "" && photo && (
            <BtnUpload onClick={uploadImage}>Upload</BtnUpload>
          )}

          <input
            ref={imageInput}
            type="file"
            accept="image/png, image/jpeg"
            style={{ visibility: "hidden" }}
            onChange={(e) => onChangePhoto(e)}
          />
        </FormItem>
        <TextinputHorizontal
          type="text"
          textTransform="capitalize"
          title="title"
          error={fieldstatustext["title"]}
          placeholder="News Title"
          name="title"
          maxLength={200}
          value={data.title}
          onChange={(e) => handleInputChange(e)}
        />
        <TextareaInput
          type="text"
          title="description"
          error={fieldstatustext["description"]}
          placeholder="Description"
          name="description"
          rows="4"
          value={data.description}
          onChange={(e) => handleInputChange(e)}
        />
        {/* <FormItem>
          <FormTitle>News Category :</FormTitle>
          <PriceInputContainer>
            <CategoriesInput
              name="NewsCategory"
              value={newsType}
              onChange={(e) => {
                handleCategoryChange(e);
              }}
            >
              <CategoriesOption value="">Choose category</CategoriesOption>
              <CategoriesOption value="job">Job Notification</CategoriesOption>
              <CategoriesOption value="batch">New Batch</CategoriesOption>
            </CategoriesInput>
          </PriceInputContainer>
        </FormItem> */}

<SelectInput
              title="news Category"
              error={fieldstatustext["course"]}
              name="category"
              placeholder="Choose news Type"
              options={newsTypes}
              value={newsType}
              onChange={(e) => handleCategoryChange(e)}
            />

        {newsType == category._id && (
          <>
            <TextinputHorizontal
              type="text"
              title="Notification link"
              error={fieldstatustext["link"]}
              placeholder="Notification link"
              name="link"
              maxLength={600}
              value={data.link}
              onChange={(e) => handleInputChange(e)}
            />

            <TextinputHorizontal
              type="text"
              title="No. of vacancies"
              error={fieldstatustext["vacancies"]}
              placeholder="0"
              name="vacancies"
              maxLength={6000}
              value={data.vacancies}
              onChange={(e) => handleInputChange(e)}
            />

            <TextinputHorizontal
              type="date"
              title="last date to apply"
              error={fieldstatustext["lDate"]}
              name="lDate"
              value={data.lDate}
              onChange={(e) => handleInputChange(e)}
            />
          </>
        )}

        {newsType !== category._id && (
          <>
            <SelectInput
              title="Course Name"
              error={fieldstatustext["course"]}
              name="course"
              placeholder="Choose Course Name"
              options={courseNames}
              value={data.course}
              onChange={(e) => handleInputChange(e)}
            />

            <TextinputHorizontal
              type="date"
              title="startDate"
              error={fieldstatustext["sDate"]}
              name="sDate"
              value={data.sDate}
              onChange={(e) => handleInputChange(e)}
            />
          </>
        )}
        <FormItem>
          <ButtonContainer>
            <BtnCancel onClick={onCancel}>Cancel</BtnCancel>
            {id ? (
              <BtnSubmit onClick={updateProduct}>Update</BtnSubmit>
            ) : (
              <BtnSubmit onClick={addNewProduct}>Submit</BtnSubmit>
            )}
          </ButtonContainer>
        </FormItem>
      </FormContainer>
      <ModalResult
        open={modal}
        message={modalMessage}
        onClose={handleModalClose}
      />
    </FormAreaContainer>
  );
};

export default AddNews;
