import styled from "styled-components";

export const Container = styled.div`
    padding: 1rem;
    flex: 1;
    text-align: center;
    margin-top: 12px;
    animation: zoom-in 500ms;
    /* background-image: url(${(props) => props.bg}),linear-gradient(
              #fff,
              #f5f5f5);
    background-size: contain;
    background-repeat: no-repeat; */

`

export const BgImage = styled.img`
    width: 360px;
    @media screen and (max-width: 768px){
       max-width: 80%;
    }
`

export const MainTitle = styled.h2`
    text-transform: capitalize;
`
export const SubTitle = styled.p`
    text-transform: capitalize;
    color: #A6A6A6;
    font-weight: 500;
`
export const SubTitleCotainer = styled.div`
    display: flex;
    gap: .5rem;
`