import styled from "styled-components";

export const InputBox = styled.input`
  outline: none;
  border: ${(props) => (props.error === "" ? "none" : "1px solid #E53935")};
  border-radius: 4px;
  background: #f8fafc;
  padding: 10px 18px;
  font-size: 1rem;
  font-weight: 500;
  width: 356px;
  min-width: 258px;
`;

export const InputBoxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;

export const PrefixText = styled.p`
  margin: 0;
  padding: 8px;
  background: #f8fafc;
  border-radius: 6px;
  width: 26px;
`;
