import React from "react";
import {
  AboutContainer,
  AboutDescription,
  AboutTitle,
  Container,
  DescDescription,
  DescTitleContainer,
} from "./AboutElements";

const About = () => {
  return (
    <Container>
      <AboutContainer>
        <AboutDescription flexd="column">
          <DescTitleContainer background="#81D4FA" from="left">
            <AboutTitle>
              Ownership <br /> & <br /> Background
            </AboutTitle>
          </DescTitleContainer>
          <DescDescription style={{ paddingLeft: "2rem" }} from="-3rem">
            Established in the year 2017, CUBIT- an Institute lead by
            experienced and eminent faculty for competitive exam offers coaching
            for various competitive examinations Conducted by SSC, Bank and
            Kerala PSC. This institute is a new venture to cater to the demand
            and expectation of the students aspiring for competitive exams and
            focuses to be the best coaching centre in Kerala.
          </DescDescription>
        </AboutDescription>

        <AboutDescription flexd="column-reverse">
          <DescDescription from="3rem" style={{ paddingRight: "3rem" }}>
            Cubit Academy aims to develop amongst its students a competitive
            attitude along with sound academic base with quality teaching and
            individual attention as its hallmark. Today we are an established
            and leading institute which is continuously guiding the students to
            achieve their goals. Our Faculties have created a Splendid Study
            Material which shall facilitate students to study the Course Content
            of all Competitive Exams in a easy way and shall enable them to
            score good marks. Our renowned faculties are best in class and have
            yielded great results. We have a zest to provide best in class
            education and to create a concrete base for students to develop
            their knowledge in a skillful manner and ensure their success.
          </DescDescription>
          <DescTitleContainer background="#B3E5FC" from="right">
            <AboutTitle>
              Nature Of
              <br /> Business
            </AboutTitle>
          </DescTitleContainer>
        </AboutDescription>

        <AboutDescription flexd="column">
          <DescTitleContainer background="#E1F5FE" from="left">
            <AboutTitle>
              Mission <br /> & <br /> Vision
            </AboutTitle>
          </DescTitleContainer>
          <DescDescription style={{ paddingLeft: "2rem" }} from="-3rem">
            At Cubit Academy, we focus on building a strong foundation of
            knowledge and concepts in students for their success and provide an
            excellent platform for the preparation of competitive exams. The
            best academic support and personal care which we provide to the
            students helps them meet their career goals and objectives. Our
            highly qualified and most experienced faculties are dedicated and
            committed to student’s overall success. Regardless of your
            background, Cubit Academy provides a strong platform in preparation.
            Our focus is on developing in each student a strong academic
            foundation, critical problem solving ability, and providing
            competitive environment. To our students, we impart value-based
            career education, abundant resources, and individual attention.
            Apart from being just a coaching center, Cubit Academy also focuses
            on the all round development of the students through expert guidance
            and unmatched study materials.
          </DescDescription>
        </AboutDescription>
      </AboutContainer>
    </Container>
  );
};

export default About;
