import http from '../http-common';
import authHeader from "./auth-header";

class UploadImagesService {


    upload(file, onUploadProgress){
        let formData = new FormData();
        formData.append('image', file);

        const user = JSON.parse(localStorage.getItem('cubiten'));

        if (user && user.accessToken) {
            return http.post("/upload-image", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    'x-access-token': user.accessToken
                },
                onUploadProgress,
                withCredentials: true
            });
        } else {
            return;
        }       
    }


    // delete uploaded images from storage
    deleteUploadedImages(images){
        const files = { files: images}
        const data =  JSON.stringify(files);

        return http.put('/images/delete-images', data, { headers: authHeader(), withCredentials: true });
    }
}

export default new UploadImagesService();