import React from "react";
import PrefixedInput from "../PrefixedInput/PrefixedInput";
import PrefixedInputBox from "../PrefixedInputBox/PrefixedInputBox";
import {
  InputBox,
  InputBoxContainer,
  InputContainer,
  InputError,
  InputTitle,
} from "./TextInputBoxElements";

const TextInputBox = (props) => {
  const {
    error,
    onChange,
    placeholder,
    name,
    maxLength,
    value,
    type,
    textTransform,
    min,
    max,
    disabled
  } = props;
  return (
    <InputContainer>
      <InputBoxContainer>
        {type == "tel" ? (
          <PrefixedInputBox
            textTransform={textTransform}
            error={error}
            type={type}
            min={min}
            max={max}
            placeholder={placeholder}
            name={name}
            maxLength={maxLength}
            value={value}
            onChange={(e) => onChange(e)}
          />
        ) : (
          <InputBox
            textTransform={textTransform}
            error={error}
            type={type}
            min={min}
            max={max}
            placeholder={placeholder}
            name={name}
            maxLength={maxLength}
            value={value}
            disabled={disabled}
            onChange={(e) => onChange(e)}
          />
        )}

        <InputError>{error}</InputError>
      </InputBoxContainer>
    </InputContainer>
  );
};

export default TextInputBox;
