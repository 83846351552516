import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Navigate, Routes } from "react-router-dom";

import DashboardMenu from "../../components/DashboardMenu";
import DashboardOverview from "../../components/DashboardOverview/DashboardOverview";
import AddAchievers from "../AddAchievers/AddAchievers";
import AddDemo from "../AddDemo/AddDemo";
import AddMaterials from "../AddMaterials/AddMaterials";
import AddNewCourse from "../AddNewCourse/AddNewCourse";
import AddNews from "../AddNews/AddNews";
import AddPublications from "../AddPublications/AddPublications";
import AddTestimonial from "../AddTestimonial/AddTestimonial";
import ChirakuEntries from "../ChirakuEntries/ChirakuEntries";
import Enquiries from "../Enquiries/Enquiries";
import ManageChiraku from "../ManageChiraku/ManageChiraku";
import MockTestLink from "../MockTestLink/MockTestLink";
import MyAchievers from "../MyAchievers/MyAchievers";
import MyCourses from "../MyCourses/MyCourses";
import MyDemoClasses from "../MyDemoClasses/MyDemoClasses";
import MyMaterials from "../MyMaterials/MyMaterials";
import MyNews from "../MyNews/MyNews";
import MyPublications from "../MyPublications/MyPublications";
import MyTestimonials from "../MyTestimonials/MyTestimonials";

import { Container, DashContentContainer } from "./DashboardElements";

const Dashboard = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  // const _ScrollToTop = (props) => {
  //     const { pathname } = useLocation();
  //     useEffect(() => {
  //         window.scrollTo(0, 0);
  //     }, [pathname]);
  //     return props.children
  // }
  // const ScrollToTop = withRouter(_ScrollToTop)

  return (
    <Container>
      <DashboardMenu />
      <DashContentContainer>
        <Routes>
          <Route path="/" case element={<DashboardOverview />} />

          <Route path="/enquiries" case element={<Enquiries />} />

          <Route path="/general" case element={<MockTestLink />} />

          <Route path="/chiraku/edit-details" case element={<ManageChiraku/>} />
          <Route path="/chiraku/view-entries" case element={<ChirakuEntries/>} />

          <Route path="/courses/add-course" element={<AddNewCourse />} />
          <Route path="/courses/my-courses" element={<MyCourses />} />
          <Route path="/courses/edit-courses/:id" element={<AddNewCourse />} />

          <Route path="/achievers/add-achievers" element={<AddAchievers />} />
          <Route path="/achievers/my-achievers" element={<MyAchievers />} />
          <Route path="/achievers/edit-achievers/:id" element={<AddAchievers />} />

          <Route path="/publications/add-publications" element={<AddPublications />} />
          <Route path="/publications/my-publications" element={<MyPublications />} />
          <Route path="/publications/edit-publications/:id" element={<AddPublications />} />

          <Route path="news/add-news" element={<AddNews />} />
          <Route path="/news/my-news" element={<MyNews />} />
          <Route path="/news/edit-news/:id" element={<AddNews />} />

          <Route path="/testimonial/add-testimonial" element={<AddTestimonial />} />
          <Route path="/testimonials/my-testimonials" element={<MyTestimonials />} />
          <Route path="/testimonials/edit-testimonials/:id" element={<AddTestimonial />} />

          <Route path="/demo/add-demo-classes" element={<AddDemo />} />
          <Route path="/demo/my-demo-classes" element={<MyDemoClasses />} />
          <Route path="/demo/edit-demo-classes/:id" element={<AddDemo />} />

          <Route path="/materials/add-materials" element={<AddMaterials />} />
          <Route path="/materials/my-materials" element={<MyMaterials />} />
          <Route path="/materials/edit-materials/:id" element={<AddMaterials />} />
        </Routes>
      </DashContentContainer>
    </Container>
  );
};

export default Dashboard;
