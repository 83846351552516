import http from "../http-common";
import authHeader from "./auth-header";

class TestimonialService{


    addNewTestimonial(data){
        return http.post(`/testimonial/add-new-testimonial`, JSON.stringify(data),{headers: authHeader(), withCredentials: true});

    }


    getAllTestimonials(){
        return http.get(`/testimonial/all`);
    }

    getAllChirakuTestimonials(){
        return http.get(`/testimonial/chiraku/all`);
    }

    getTestimonial(id){
        return http.get(`/testimonial/${id}`);
    }

    updateTestimonial(id, data){
        return http.put(`/testimonial/update/${id}`, JSON.stringify(data),{headers: authHeader(), withCredentials: true});

    }

    deleteTestimonial(id){
        return http.delete(`/testimonial/delete/${id}`,{headers: authHeader(), withCredentials: true});
    }

    

  
}


export default new TestimonialService();