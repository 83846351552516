import styled from "styled-components";


export const InputBox = styled.input`
   outline: none;
    border: ${props => props.error === "" ? "none" : "1px solid #E53935"};
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    border-radius: 4px;
    background: #fff;
    padding: 12px 18px;
    font-size: 1rem;
    font-weight: 500;
    width: 506px;
    &::placeholder{
        font-weight: 500;
        
    }
    @media screen and (max-width: 768px){
        width: 246px;
    }
    
`

export const InputBoxContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 3px;
`




export const PrefixText = styled.p`
    margin: 0;
    padding: 12px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    border-radius: 6px;
    width: 26px;
    background: #fff;

`