import React from 'react'
import { Container } from "../../globalStyle"
import { CompAddressContainer, CompDetails, CompIcon, CompLogo, CompSubTitle, CompTitle, CompTitleContainer, ConatcUsContainer, ContactCover, ContactUsTitle,DetailsContainer, TxtLabel } from './ContactUsElements'
import ContactSection from "../../components/ContactSection";
import logo from '../../assets/logos/logo.svg';
import ContactSectionForm from '../../components/ContactSectionForm';
const ContactUs = () => {
    return (
        <Container>
            <ConatcUsContainer>
                {/* <ContactCover src={cover}/> */}
                <DetailsContainer>
                    {/* <ContactUsTitle>Contact Us</ContactUsTitle> */}
                    <ContactSectionForm />
                    {/* <CompDetails>
                        <CompLogo to="/">
                            <CompIcon src={logo} />
                        </CompLogo>
                        <CompAddressContainer>
                            <TxtLabel>City Gallery, YMCA</TxtLabel>
                            <TxtLabel>Near Paragon Hotel, Kannur Road</TxtLabel>
                            <TxtLabel>Kozhikode - 673001</TxtLabel>
                            <TxtLabel>cubitcalicut@gmail.com | +91 894 33 66 588</TxtLabel>
                            
                        </CompAddressContainer>
                    </CompDetails> */}
                </DetailsContainer>
                
            </ConatcUsContainer>
        </Container>
    )
}

export default ContactUs
