import styled from "styled-components";
import {Link as LinkR} from'react-router-dom';

export const ServicesSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background: #e1f5fe;
  @media screen and (max-width: 768px) {
    padding: 1rem;
  }
`;
export const ServicesContents = styled.div`
  margin-bottom: 1rem;
  display: flex;
  gap: 1rem;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 2rem;
  width: 1100px;

  position: relative;
  @media screen and (max-width: 768px) {
    gap: 1rem;
    width: 100%;
  }
`;
export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

export const TitlePartOne = styled.h2`
  color: #01579b;
`;
export const TitlePartTwo = styled.h2`
  color: #40c4ff;
`;

export const BtnView = styled(LinkR)`
    text-decoration: none;
    color: #fff;
    background: #08569A;
    padding: 10px 18px;
    border-radius: 4px;
    text-transform: capitalize;
    font-weight: 600;
    transition: all 400ms;
    &:hover{
        box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    }
`
