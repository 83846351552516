import styled from "styled-components";

export const ModalContainer = styled.div`
    background: #fff;
    padding: 24px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
`


export const ModalTitle = styled.h2`
margin: 0;
margin-bottom: 10px;
color: #263238;

`

export const Message = styled.p`

`

export const Cover = styled.img`
    width: 150px;
    margin: auto;
`


export const BtnOk = styled.button`
    transition: all 200ms;
    cursor: pointer;
    font-size: 1rem;
    background: ${props => props.color};
    color: #fff;
    border: 0;
    padding: 7px 15px;
    border-radius: 3px;
    &:hover{
        opacity: 0.9;
    }
`

export const ButtonConatiner = styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;

`