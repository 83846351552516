import styled from "styled-components";

export const ContentContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 1rem;
    animation: zoom-in  400ms;
    justify-content: space-around;

`