import styled from "styled-components";
import {Link as LinkR} from 'react-router-dom';

export const LoginContainer = styled.div`
    background: #fff;
    padding: 40px;
    height: 100%;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    border-radius: 3px;

`

export const LoginInTitle = styled.h2`
    color: #262626;

`

export const FieldContainer = styled.h2`
    color: #455A64;
    font-weight: 200;
`

export const FieldName = styled.p`
    font-size: 1rem;
    margin: 0;

`

export const TextInput = styled.input`
    font-size: 1rem;
    padding: 5px 10px;
    border: 0;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 3px;
    outline: none;
`

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    
`

export const BtnLogin = styled.button`
    border: 0;
    background: #262626;
    color: #fff;
    padding: 10px 20px;
    border-radius: 3px;
    cursor: pointer;
    text-decoration: none;
    font-size:1rem;

    &:hover{
        background: #262626;
        opacity: 0.9;
    }
`

export const BtnForgot = styled(LinkR)`
    cursor: pointer;
    margin-top: 10px;
    font-weight: 200;
    font-size: .8rem;
    color: #90A4AE;
    text-decoration: none;
`

export const InputStatusText = styled.p`
    color: #B71C1C;
    margin: 0;
    font-size: 0.8rem;
    margin-top: .2rem;
`