import { FaPhoneAlt } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import { MdPlace } from "react-icons/md";
import { format } from "date-fns";
import {
  AddressData,
  AddressItem,
  BtnKnowMore,
  CourseName,
  DataContainer,
  MessageContainer,
  Row,
  SDate,
  SDesc,
  ServiceItemContainer,
  SIcon,
  SSubject,
  STitle,
} from "./ChirakuEntryItemElements";

const ChirakuEntryItem = (props) => {
  const { data } = props;
  return (
    <ServiceItemContainer to={`/courses/course/${data._id}`}>
      <SDate>{format(new Date(data.date), "dd MMM yyy p")}</SDate>
      <Row>
        <DataContainer>
          <STitle>{data.name}</STitle>
          <AddressItem>
            <MdPlace />
            <AddressData>{data.place}</AddressData>
          </AddressItem>
        </DataContainer>
        <DataContainer>
          <AddressItem>
            <FaPhoneAlt />
            <AddressData>+91 {data.phone}</AddressData>
          </AddressItem>

          {data.email && (
            <AddressItem>
              <IoMail />
              <AddressData>{data.email}</AddressData>
            </AddressItem>
          )}
        </DataContainer>
        <CourseName>{data.course}</CourseName>
      </Row>
    </ServiceItemContainer>
  );
};

export default ChirakuEntryItem;
