import styled from "styled-components";

export const DashContainer = styled.div`
    border-radius: .5rem;
    background: #fff;
    width: 100%;
    border: 1px solid #EDEDED;
    display: flex;
`
export const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 8px;
    margin: 1rem;
    border-radius: 8px;
    background: #FFFFFF;
    border: 1px solid #DBDBDB;
    @media screen and (max-width: 768px){
       width: 100%;
    }
`
export const SearchInput = styled.input`
    border:0;
    outline:none;
    font-size: 1rem;
    width: 300px;
    @media screen and (max-width: 768px){
       width: 100%;
    }
`
export const SearchIcon = styled.img`
    cursor: pointer;
`