import styled from "styled-components";

export const InputContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const InputTitle = styled.p`
  width: 20%;
    font-weight: 500;
    text-transform: capitalize;

    @media screen and (max-width: 768px){
        width: 100%;
    }
`;

export const InputBoxContainer = styled.div``;

export const InputError = styled.p`
  font-size: 0.9rem;
  margin-top: 5px;
  color: #e53935;
  font-weight: 300;
`;

export const CategoriesInput = styled.select`
  outline: none;
    border: ${props => props.error === "" ? "none" : "1px solid #E53935"};
    border-radius: 4px;
    background: #F8FAFC;
    padding: 10px 18px;
    font-size: 1rem;
    font-weight: 500;
    width: 400px;
    &::placeholder{
        font-weight: 500;
        
    }
    @media screen and (max-width: 768px){
        width: 100%;
    }
  text-transform: uppercase;
`;
export const CategoriesOption = styled.option`
  text-transform: uppercase;

  &:first-child {
    color: rgba(0, 0, 0, 0.3);
  }
`;
