import { Tooltip } from "@mui/material";
import React, { useRef, useState } from "react";
import { MdAdd, MdCancel, MdDelete, MdEdit, MdInsertDriveFile, MdUpdate } from "react-icons/md";
import { ActionButton } from "../MyTestimonialItem/MyTestimonialItemElements";
import PrefixedInput from "../PrefixedInput/PrefixedInput";
import icPicker from "../../assets/icons/ic_image_picker.svg";
import UploadImagesService from "../../services/upload-files-service";
import {
  BtnUpload,
  ButtonContainer,
  Description,
  FieldContainer,
  ImagePickerContainer,
  ImagePreviewContainer,
  InputBox,
  InputBoxContainer,
  InputBoxTextarea,
  InputContainer,
  InputError,
  InputStatus,
  InputTitle,
  PickedImage,
  PickerIcon,
  PickerText,
  Title,
  UploadContainer,
} from "./MultiFieldInputElemants";
import courseService from "../../services/course.service";
import { TxtFileName } from "../../pages/AddMaterials/AddMaterialsElements";

const MultiFieldInput = (props) => {
  const { index, title, name, onAdd, onUpdate, onDelete, preview, content } =
    props;

  const [edit, setEdit] = useState(!preview);
  const [photo, setPhoto] = useState("");
  const [fileType, setFileType] = useState("");
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const imageInput = useRef(null);
  const [uploadImageStatus, setUploadImageStatus] = useState(false);

  const [data, setData] = useState({
    title: "",
    photo: "",
  });

  const [fieldstatustext, setFieldstatusText] = useState({
    title: "",
    photo: {
      text: "",
      color: "",
    },
  });

  const handleInputChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    setData({
      ...data,
      [name]: value,
    });
  };

  const onAddItem = () => {
    if (validateForm()) {
      onAdd(name, data);
      setData({
        title: "",
        photo: "",
      });

      setFieldstatusText({
        title: "",
        photo: "",
      });
    }
  };

  const onDeleteItem = () => {
    if (content.photo !== "") {
      let nameSplit = content.photo.split("/");
      let key = nameSplit[nameSplit.length - 1];
      UploadImagesService.deleteUploadedImages([key])
        .then((result) => {
          onDelete(name, index);
        })
        .catch((err) => console.log(err));
      setData({
        photo: "",
        title: "",
      });
    } else {
      setData({
        photo: "",
        title: "",
      });
    }

    setPhoto("");
    setSelectedPhoto(null);
  };

  const onUpdateItem = () => {
    if (validateForm()) {
      onUpdate(name, data, index);
      setEdit(false);
    }
  };

  const onEditItem = () => {
    setEdit(true);
    setData({
      title: content.title,
      photo: content.photo,
    });
    setPhoto(content.photo);
  };

  const validateForm = () => {
    // if (data.photo === "" && photo != "") {
    //   setFieldstatusText({
    //     ...fieldstatustext,
    //     photo: {
    //       text: "No Photo uploaded!",
    //       color: "#2E7D32",
    //     },
    //   });
    //   alert("No Photo uploaded!");
    //   return false;
    // }

    return true;
  };

  const onChangePhoto = (e) => {
    if (e.target.files && e.target.files[0]) {
      setPhoto(URL.createObjectURL(e.target.files[0]));
      console.log(URL.createObjectURL(e.target.files[0]));
      setFileType(e.target.files[0].type);
      setSelectedPhoto(e.target.files[0]);
      setData({
        ...data,
        photo: "",
      });
    }
  };

  const uploadImage = () => {
    if (selectedPhoto === null) {
      setFieldstatusText({
        ...fieldstatustext,
        photo: {
          text: "No files selected!",
          color: "#B71C1C",
        },
      });
      return;
    }

    UploadImagesService.upload(selectedPhoto, (event) => {
      setUploadImageStatus(true);
    })
      .then((res) => {
        setData({
          ...data,
          photo: res.data.Location,
        });
        setFieldstatusText({
          ...fieldstatustext,
          photo: {
            text: "File Uploaded Successfully!",
            color: "#2E7D32",
          },
        });
        setUploadImageStatus(false);
      })
      .catch((err) => {
        setFieldstatusText({
          ...fieldstatustext,
          photo: {
            text: "Please try agin. File not uploaded!",
            color: "#F57F17",
          },
        });
        setUploadImageStatus(false);
      });
  };

  return (
    <InputContainer>
      {!edit ? (
        <>
          <FieldContainer>
            <InputBoxContainer>
              <Title>{content.title}</Title>
            </InputBoxContainer>

            <InputBoxContainer>
              {content.photo && (
                <ImagePreviewContainer>
                  {content.photo.substring(content.photo.lastIndexOf('/')+1).split(".").reverse()[0] === "pdf" ? (
                        <TxtFileName>
                          {content ? content.photo.split("/").reverse()[0] : selectedPhoto.name}
                        </TxtFileName>
                      ) : (
                        <PickedImage src={content.photo} />
                      )}
                </ImagePreviewContainer>
              )}
            </InputBoxContainer>
          </FieldContainer>
          <ButtonContainer>
            <Tooltip title="edit">
              <ActionButton
                bg="#B71C1C"
                aria-label=""
                component="span"
                size="small"
                onClick={onEditItem}
              >
                <MdEdit />
              </ActionButton>
            </Tooltip>
            <Tooltip title="delete">
              <ActionButton
                bg="#B71C1C"
                aria-label=""
                component="span"
                size="small"
                onClick={onDeleteItem}
              >
                <MdDelete />
              </ActionButton>
            </Tooltip>
          </ButtonContainer>
        </>
      ) : (
        <>
          {title && <InputTitle>{title} :</InputTitle>}
          <FieldContainer>
            <InputBoxContainer>
              <InputBox
                type="text"
                textTransform="capitalize"
                title="title"
                error={fieldstatustext["title"]}
                placeholder="Subject Title"
                name="title"
                maxLength={200}
                value={data.title}
                onChange={(e) => handleInputChange(e)}
              />

              <InputStatus color={fieldstatustext?.photo.color}>{fieldstatustext?.photo.text}</InputStatus>
            </InputBoxContainer>

            <InputBoxContainer>
              <UploadContainer>
                <ImagePickerContainer
                  onClick={() => imageInput.current.click()}
                >
                  {photo ? (
                    <>
                      {fileType === "application/pdf" || (content?.photo.substring(content?.photo.lastIndexOf('/')+1).split(".").reverse()[0] === "pdf") ? (
                        <TxtFileName>
                          {" "}
                          <MdInsertDriveFile />
                          {content ? content?.photo.split("/").reverse()[0] : selectedPhoto.name}
                        </TxtFileName>
                      ) : (
                        <PickedImage src={photo} />
                      )}
                      <PickerText
                        style={{
                          background: "rgba(0,0,0,0.3)",
                          padding: ".3rem .6rem",
                          borderRadius: ".3rem",
                        }}
                      >
                        Change File
                      </PickerText>
                    </>
                  ) : (
                    <>
                      <PickerIcon src={icPicker} />
                      <PickerText>Upload File</PickerText>
                    </>
                  )}
                  
                </ImagePickerContainer>
                {data.photo === "" && photo && (
                  <BtnUpload onClick={uploadImage}>Upload</BtnUpload>
                )}
              </UploadContainer>
              <InputError>{fieldstatustext["title"]}</InputError>
              <input
                ref={imageInput}
                type="file"
                accept="image/png, image/jpeg, application/pdf"
                style={{ visibility: "hidden" }}
                onChange={(e) => onChangePhoto(e)}
              />
            </InputBoxContainer>
          </FieldContainer>
          <ButtonContainer>
            {title ? (
              <Tooltip title="add">
                <ActionButton
                  bg="#B71C1C"
                  aria-label=""
                  component="span"
                  size="small"
                  onClick={onAddItem}
                >
                  <MdAdd />
                </ActionButton>
              </Tooltip>
            ) : (
              <>
                <Tooltip title="update">
                  <ActionButton
                    bg="#B71C1C"
                    aria-label=""
                    component="span"
                    size="small"
                    onClick={onUpdateItem}
                  >
                    <MdUpdate />
                  </ActionButton>
                </Tooltip>
                <Tooltip title="cancel">
                  <ActionButton
                    bg="#B71C1C"
                    aria-label=""
                    component="span"
                    size="small"
                    onClick={(e) => setEdit(false)}
                  >
                    <MdCancel />
                  </ActionButton>
                </Tooltip>
              </>
            )}
          </ButtonContainer>
        </>
      )}
    </InputContainer>
  );
};

export default MultiFieldInput;
