import React from 'react'
// leader 
import { Backdrop,} from '@mui/material';
import loaderImage from '../../assets/logos/logo.svg'
import { LoaderMain } from './LoaderElements';



const Loader = (props) => {

    return (
        <Backdrop open={props.loader} >
            <LoaderMain src={loaderImage}/>
          </Backdrop>
    )
}

export default Loader
