import { useEffect, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { Navigate} from "react-router-dom"
import DashContentTitle from "../../components/DashContentTitles"
import { AllProductsContainer, Container, PhonesList, ProductsContainer, ProductsSection, SubNavBar} from "./MyDemoClassesElements"
import noDataCover from '../../assets/images/no_data.svg'
import Loader from '../../components/Loader';
import courseService from "../../services/course.service"
import CourseItem from "../../components/CourseItem"
import { ErrorText, NodataCover, NoDataMessageContainer } from "../../globalStyle"
import DashActionbar from "../../components/DashActionBar"
import { VIEW_ACHIEVERS, VIEW_DEMO, VIEW_TESTIMONIALS } from "../../actions/types"
import publicationService from "../../services/publication.service"
import PublicationItem from "../../components/PublicationItem"
import achieverService from "../../services/achiever.service"
import MyAchieversItem from "../../components/MyAchieversItem"
import testimonialService from "../../services/testimonial.service"
import YoutubeEmbed from "../../components/YoutubeEmbed"
import MyTestimonialItem from "../../components/MyTestimonialItem"
import demoClassService from "../../services/demoClass.service"
import MyDemoItem from "../../components/MyDemoItem"

const MyDemoClasses = (props) => {

    const { user: currentUser } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const [demoClasses, setDemoClasses] = useState([]);
    const [loader, setLoader] = useState(false);

    useEffect(() => {

        dispatch({
            type: VIEW_DEMO,
          });

        getDemoClasses();
      }, []
    );


    if (!currentUser) {
        return <Navigate to="/login" />;
    }


    const getDemoClasses = () => {
        setDemoClasses([]);
        setLoader(true);
        demoClassService.getAllDemoClasses()
        .then((res) => {
            
            setDemoClasses(res.data);
            setLoader(false);
        }).catch(() => {
                setLoader(false);
        });
        
    }




    const searchProducts = (tag) => {
        if(tag !== ''){
            courseService.getAllCourses()
            .then((res) => {
                setDemoClasses(res.data.products);
            });
        } else {
            
            getDemoClasses();
        }
        
    }

    const handleDeleteProduct = (e) => {
        const  newCourses = demoClasses.filter( el => el._id !== e);
        setDemoClasses(newCourses);
    }



    const testimonialsList = demoClasses.map((item, index) => {
        return <MyDemoItem
        key={item._id}
        data={item}
        edit={true}
        onDeleteItem = {handleDeleteProduct}
        />
    })
    
    return (
        <Container>
            {/* <DashActionbar onChange= {(tag)=> searchProducts(tag)}/> */}
            <AllProductsContainer>
                <DashContentTitle title= "all products"/>
                <ProductsSection>
                    <ProductsContainer>
                        <PhonesList>
                            {demoClasses && testimonialsList}
                            {(demoClasses.length === 0 && loader === false) ? (
                            <NoDataMessageContainer>
                                <NodataCover src={noDataCover}/>
                                <ErrorText>No achievers found!</ErrorText>
                            </NoDataMessageContainer>
                            ):(null)}
                        </PhonesList>
                        
                    </ProductsContainer>
                </ProductsSection>
            <Loader loader={loader}/>
            </AllProductsContainer>
        </Container>
        
    )
}

export default MyDemoClasses;
