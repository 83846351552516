import DashContentTitle from "../../components/DashContentTitles";
import { useDispatch, useSelector } from "react-redux";
import {
  BtnCancel,
  BtnSubmit,
  BtnUpload,
  ButtonContainer,
  CategoriesInput,
  CategoriesOption,
  CurrencyType,
  FormAreaContainer,
  FormContainer,
  FormItem,
  EmbedContainer,
} from "./MockTestLinkElements";
import icPicker from "../../assets/icons/ic_image_picker.svg";
import { useEffect, useRef, useState } from "react";
import ProductsService from "../../services/products-service";
import UploadImagesService from "../../services/upload-files-service";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import ModalResult from "../../components/ModalResult";
import ModalConfirm from "../../components/ModalConfirm";
import {
  ADD_TESTIMONIALS,
  EDIT_TESTIMONIALS,
  GENERAL,
} from "../../actions/types";
import categoryService from "../../services/category.service";
import SelectInput from "../../components/SelectInput/SelectInput";
import TextinputHorizontal from "../../components/TextInputHorizontal/TextinputHorizontal";
import TextareaInput from "../../components/TextareaInput/Textareainput";
import courseService from "../../services/course.service";
import achieverService from "../../services/achiever.service";
import testimonialService from "../../services/testimonial.service";
import YoutubeEmbed from "../../components/YoutubeEmbed";
import generalService from "../../services/general.service";

const MockTestLink = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [modal, showModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const [confirmModal, showConfirmModal] = useState(false);
  const [modalData, setModalData] = useState({
    message: "",
    title: "",
  });
  const handleConfirmModalClose = (e) => {
    if (e) {
      type === "social" ? updateSocialsLinks() : updateGeneralLinks();
    }
    showConfirmModal(false);
  };

  const [generalData, setGeneralData] = useState({
    introLink: "",
    testLink: "",
    generalID: "",
  });

  const [socialData, setSocialData] = useState({
    fb: "",
    insta: "",
    twitter: "",
    youtube: "",
    socialID: "",
  });

  const [fieldstatustext, setFieldstatusText] = useState({
    introLink: "",
    testLink: "",
    fb: "",
    insta: "",
    twitter: "",
    youtube: "",
  });

  const [link, setLink] = useState("");
  const [Sociallink, setSocialLink] = useState("");
  const [type, setType] = useState("");

  useEffect(() => {
    dispatch({
      type: GENERAL,
    });
    getSocialDetails();
    getGeneralDetails();
  }, []);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  const getGeneralDetails = () => {
    generalService
      .getGeneralLinks()
      .then((res) => {
        setGeneralData({
          ...generalData,
          introLink: res.data.introLink,
          testLink: res.data.testLink,
          generalID: res.data._id,
        });
        setLink(res.data.introLink);
      })
      .catch(() => {});
  };

  const getSocialDetails = () => {
    generalService
      .getSocialLinks()
      .then((res) => {
        setSocialData({
          ...socialData,
          fb: res.data.fb,
          insta: res.data.insta,
          youtube: res.data.youtube,
          twitter: res.data.twitter,
          socialID: res.data._id,
        });
        setSocialLink(
          res.data.fb + res.data.insta + res.data.youtube + res.data.twitter
        );
      })
      .catch(() => {});
  };

  const handleGeneralInputChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    setGeneralData({
      ...generalData,
      [name]: value,
    });
  };

  const handleSocialInputChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    setSocialData({
      ...socialData,
      [name]: value,
    });
  };

  const handleModalClose = (e) => {
    showModal(false);
  };

  const addSocialLinks = () => {
    if (validateForm()) {
      const req = {
        fb: socialData.fb,
        insta: socialData.insta,
        youtube: socialData.youtube,
        twitter: socialData.twitter,
      };
      generalService
        .addSocialLinks(req)
        .then((res) => {
          setModalMessage("Social media links added successfully!");
          showModal(true);
        })
        .catch((err) => console.log(err));
    }
  };

  const addNewGeneralLinks = () => {
    if (validateForm()) {
      const req = {
        introLink: generalData.introLink,
        testLink: generalData.testLink,
      };
      generalService
        .addGeneralLinks(req)
        .then((res) => {
          setModalMessage("General Links added successfully!");
          showModal(true);
        })
        .catch((err) => console.log(err));
    }
  };

  const updateGeneralLinks = () => {
    if (validateForm()) {
      const req = {
        introLink: generalData.introLink,
        testLink: generalData.testLink,
      };
      generalService
        .updateGeneralLinks(generalData.generalID, req)
        .then((res) => {
          setModalMessage("General links updated successfully!");
          showModal(true);
        })
        .catch((err) => console.log(err));
    }
  };

  const updateSocialsLinks = () => {
    if (validateForm()) {
      const req = {
        fb: socialData.fb,
        insta: socialData.insta,
        youtube: socialData.youtube,
        twitter: socialData.twitter,
      };
      generalService
        .updateSocialLinks(socialData.socialID, req)
        .then((res) => {
          setModalMessage("Social media links updated successfully!");
          showModal(true);
        })
        .catch((err) => console.log(err));
    }
  };

  const validateForm = () => {
    return true;
  };

  return (
    <FormAreaContainer>
      <DashContentTitle />

      <FormContainer>
        <TextinputHorizontal
          type="text"
          textTransform="capitalize"
          title="Intro Video link"
          error={fieldstatustext["introLink"]}
          placeholder="Paste url"
          name="introLink"
          maxLength={600}
          value={generalData.introLink}
          onChange={(e) => handleGeneralInputChange(e)}
        />

        <TextinputHorizontal
          type="text"
          textTransform="capitalize"
          title="Mock test link"
          error={fieldstatustext["testLink"]}
          placeholder="Paste url"
          name="testLink"
          maxLength={600}
          value={generalData.testLink}
          onChange={(e) => handleGeneralInputChange(e)}
        />
        <FormItem>
          <ButtonContainer>
            {link ? (
              <BtnSubmit
                onClick={(e) => {
                  setModalData({
                    message: "Are you sure want to update ?",
                    title: "Update",
                  });

                  setType("general");
                  showConfirmModal(true);
                }}
              >
                Update
              </BtnSubmit>
            ) : (
              <BtnSubmit onClick={addNewGeneralLinks}>Submit</BtnSubmit>
            )}
          </ButtonContainer>
        </FormItem>
      </FormContainer>

      <FormContainer>
        <TextinputHorizontal
          type="text"
          textTransform="capitalize"
          title="Facebook Link"
          error={fieldstatustext["fb"]}
          placeholder="Facebook url"
          name="fb"
          value={socialData.fb}
          onChange={(e) => handleSocialInputChange(e)}
        />
        <TextinputHorizontal
          type="text"
          textTransform="capitalize"
          title="Instagram Link"
          error={fieldstatustext["insta"]}
          placeholder="Instagram url"
          name="insta"
          value={socialData.insta}
          onChange={(e) => handleSocialInputChange(e)}
        />
        <TextinputHorizontal
          type="text"
          textTransform="capitalize"
          title="youtube link"
          error={fieldstatustext["youtube"]}
          placeholder="Youtube url"
          name="youtube"
          value={socialData.youtube}
          onChange={(e) => handleSocialInputChange(e)}
        />
        <TextinputHorizontal
          type="text"
          textTransform="capitalize"
          title="twitter link"
          error={fieldstatustext["twitter"]}
          placeholder="Twitter url"
          name="twitter"
          value={socialData.twitter}
          onChange={(e) => handleSocialInputChange(e)}
        />
        <FormItem>
          <ButtonContainer>
            {Sociallink ? (
              <BtnSubmit
                onClick={(e) => {
                  setModalData({
                    message: "Are you sure want to update ?",
                    title: "Update",
                  });
                  setType("social");
                  showConfirmModal(true);
                }}
              >
                Update
              </BtnSubmit>
            ) : (
              <BtnSubmit onClick={addSocialLinks}>Submit</BtnSubmit>
            )}
          </ButtonContainer>
        </FormItem>
      </FormContainer>

      <ModalResult
        open={modal}
        message={modalMessage}
        onClose={handleModalClose}
      />
      <ModalConfirm
        open={confirmModal}
        title={modalData.title}
        message={modalData.message}
        ok="Yes"
        cancel="No"
        onClose={handleConfirmModalClose}
      />
    </FormAreaContainer>
  );
};

export default MockTestLink;
