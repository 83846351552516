import { useSelector } from "react-redux";
import {
  Container,
  MainTitle,
  SubTitle,
  SubTitleCotainer,
} from "./pageTitleElements";

const PageTitle = (props) => {
  const { title, subTitle } = props;
  return (
    <Container>
      <MainTitle>{title}</MainTitle>
      <SubTitle>{subTitle}</SubTitle>
    </Container>
  );
};

export default PageTitle;
