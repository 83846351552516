import styled from "styled-components";

export const InputContainer = styled.div`
  display: flex;
  align-items: flex-start;
  @media screen and (max-width: 768px) {
    // width: 300px;
    width: 100%;
    justify-content: center;
  }
`;

export const InputTitle = styled.p`
  width: 20%;
  font-weight: 500;
  text-transform: capitalize;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const InputBox = styled.textarea`
  outline: none;
  border: ${(props) => (props.error === "" ? "none" : "1px solid #E53935")};
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  border-radius: 4px;
  background: #fff;
  padding: 10px 18px;
  font-size: 1rem;
  resize: none;
  font-weight: 500;
  width: 560px;
  &::placeholder {
    font-weight: 500;
  }
  @media screen and (max-width: 768px) {
    width: 300px;
  }
`;

export const InputBoxContainer = styled.div``;

export const InputError = styled.p`
  font-size: 0.9rem;
  margin-top: 5px;
  color: #e53935;
  font-weight: 300;
`;
