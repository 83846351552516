import styled from "styled-components";

export const InputContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const InputTitle = styled.p`
  width: 20%;
  font-weight: 500;
  text-transform: capitalize;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const InputBox = styled.input`
  outline: none;
  border: ${(props) => (props.error === "" ? "none" : "1px solid #E53935")};
  border-radius: 4px;
  background: #f8fafc;
  padding: 10px 18px;
  font-size: 1rem;
  font-weight: 500;
  width: 400px;
  &::placeholder {
    font-weight: 500;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const InputBoxContainer = styled.div`
  flex: 1;
`;

export const InputError = styled.p`
  font-size: 0.9rem;
  margin: 0;
  margin-top: 5px;
  color: #e53935;
  font-weight: 300;
`;
