import React from "react";
import {
    Container,
  EmbedContainer,
  EmbedDesc,
  EmbedIframe,
  EmbedTitle,
} from "./DemoYoutubeEmbedElements";

const DemoYoutubeEmbed = ({ link, title, description }) => {
  const splittedLink = link.split("/");
    const embedId = splittedLink[splittedLink.length - 1];
  return (
    <Container>
      <EmbedContainer>
        <EmbedIframe
          width="853"
          height="480"
          src={`https://www.youtube.com/embed/${embedId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </EmbedContainer>
      { title && <EmbedTitle>{title}</EmbedTitle>}
      { description && <EmbedDesc>{description}</EmbedDesc>}
    </Container>
  );
};

export default DemoYoutubeEmbed;
