import styled from "styled-components";

export const PDFWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  direction: ltr;
  unicode-bidi: isolate;
  aspect-ratio: auto 1190 / 1684;
  height: unset !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  border-radius: 8px;
`;

export const BtnNavContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #717171;
  justify-content: center;
  height: 80px;
`;
export const BtnNav = styled.div`
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  border: none;
`;
