import { useState } from "react";
import { useNavigate } from "react-router-dom";
import publicationService from "../../services/publication.service";
import {
  DataContainer,
  PhotoContainer, ServiceItemContainer,
  SIcon,
  SPrice,
  STitle
} from "./PublicationItemElements";

const PublicationItem = (props) => {
  const { data, edit, onDeleteItem } = props;

  const navigate = useNavigate();

  const [confirmModal, showConfirmModal] = useState(false);
  const [modalData, setModalData] = useState({
    message: "",
    title: "",
  });

  const handleConfirmModalClose = (e) => {
    if (e) {
      onDelete();
    }
    showConfirmModal(false);
  };

  const [expand, setExpand] = useState(false);

  const onDelete = () => {
    publicationService
      .deletePublication(data._id)
      .then((res) => {
        
        onDeleteItem(data._id);
        showConfirmModal(false);
      })
      .catch((err) => console.log(err));
  };

  return (
    <ServiceItemContainer to={`/publications/publication/${data._id}`}>
      <PhotoContainer>
      <SIcon src={data.photo} />
      </PhotoContainer>
      
      <DataContainer>
        <STitle>{data.title}</STitle>
        {data.price && <SPrice>{"\u20B9 " + data.price.toFixed(2)}</SPrice>}
      </DataContainer>
      
    </ServiceItemContainer>
  );
};

export default PublicationItem;
