import styled from "styled-components";

export const ServicesSectionContainer  = styled.div`
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    background-image: linear-gradient(#fff, #fafafa);
    @media screen and (max-width: 768px){
      padding: 1rem 0;
    }
`
export const ServicesContents = styled.div`
    width: 1100px;
    @media screen and (max-width: 768px){
        gap: 0.5rem;
        width: 100%;
    }
`
export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
`

export const TitlePartOne = styled.h2`
    color: #01579B;
`
export const TitlePartTwo = styled.h2`
    color: #40C4FF;
`
export const ContentTitle = styled.h3`
    
`

