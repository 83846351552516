import React from "react";
import Carousel from 'react-material-ui-carousel'
import YoutubeEmbed from "../YoutubeEmbed";

const CarouselTestimonials = (props) => {

    const {items} = props;

    const itemsList = items.map((item, index) => {
        return <YoutubeEmbed link={item.link} title={item.title} description={item.description}/>;
      });
    

    return (
        <Carousel animation="slide" autoPlay={false} >
            {
                itemsList
            }
        </Carousel>
    )
};

export default CarouselTestimonials;
