import React from 'react'
import { BtnOk, ButtonConatiner, Cover,Message, ModalContainer } from './ModalElements'
import { Dialog, DialogTitle } from '@mui/material';
import cover from '../../assets/images/done_cover.svg'


const ModalResult = (props) => {

    const { onClose, open} = props;


    return (
    
        <Dialog onClose={() => onClose(false)} open={open} >
            
                <DialogTitle >Successful!</DialogTitle>
                <ModalContainer>
                    <Cover src={cover}/>
                    <Message>{props.message}</Message>
                    <ButtonConatiner>
                        <BtnOk color="#262626" onClick={() => props.onClose(true)}>OK</BtnOk>
                    </ButtonConatiner>
            </ModalContainer>
        </Dialog>
    )
}

export default ModalResult
