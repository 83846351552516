import http from "../http-common";
import authHeader from "./auth-header";

class ChirakuRegistrationService{

    getAllEntries(){
        return http.get(`/chiraku/entries/all`, {headers: authHeader(), withCredentials: true});
    }




    registerNew(data){
        return http.post(`/public/chiraku/register`, JSON.stringify(data),{headers: authHeader(), withCredentials: true});

    }

    

  
}


export default new ChirakuRegistrationService();