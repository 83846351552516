import styled from "styled-components";
import {NavLink as LinkR} from 'react-router-dom';
export const MobileNavContainer = styled.div`
    
`

export const MobileNavOption = styled(LinkR)`
    text-decoration: none;
    color: #03579B;
`

export const NavTitle = styled.p`
    margin: 0;
    padding: 20px 24px;
    text-align: center;
    margin-top: 2px;
    transition: all 200ms;
    font-weight: 600;
    background: rgba(255, 255, 255, 0.9);

    &:hover{
        background: rgba(255, 255, 255, 0.2);
        color: #212121;
    }
`

