import styled from "styled-components";

export const InputContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.5rem;
`;

export const InputTitle = styled.p`
  width: 20%;
  font-weight: 500;
  text-transform: capitalize;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const InputBox = styled.input`
  outline: none;
  border: ${(props) => (props.error === "" ? "none" : "1px solid #E53935")};
  border-radius: 4px;
  background: #f8fafc;
  padding: 10px 18px;
  font-size: 1rem;
  font-weight: 500;
  width: 500px;
  &::placeholder {
    font-weight: 500;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const InputBoxTextarea = styled.textarea`
  outline: none;
  border: ${(props) => (props.error === "" ? "none" : "1px solid #E53935")};
  border-radius: 4px;
  background: #f8fafc;
  padding: 10px 18px;
  font-size: 1rem;
  font-weight: 500;
  resize: none;
  width: 500px;
  &::placeholder {
    font-weight: 500;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const InputBoxContainer = styled.div`
  flex: 1;
`;

export const InputError = styled.p`
  font-size: 0.9rem;
  margin: 0;
  margin-top: 5px;
  color: #e53935;
  font-weight: 300;
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 12px;
  padding: 12px;
  margin-right: 1rem;
`;

export const Title = styled.p`
  width: 500px;
  font-weight: 400;
  margin: 0;
`;
export const Description = styled.p`
  width: 500px;
  font-size: 14px;
  font-weight: 300;
  color: #424242;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`;


export const ImagePickerContainer = styled.div`

    background: #F8FAFC;
    border: 1px dashed #D5D5D5;
    width: 180px;
    height: 180px;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #D1D1D1;
    cursor: pointer;
    position: relative;
    overflow: hidden;
`
export const PickerIcon = styled.img`
    width: 75px;
`

export const PickerText = styled.p`
    position: absolute;
    bottom: 1rem;
    
`

export const PickedImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    
`

export const BtnUpload = styled.button`
    color: #fff;
    box-sizing:border-box;
    font-size: 1rem;
    background: #262626;
    border:0;
    border-radius: 4px;
    padding: 8px 16px;
    font-weight: bold;
    cursor: pointer;
    align-self: center;
    margin-left: 1rem;
`
