import React from "react";
import {
  BtnCall,
  CallContainer,
  ContactContainer,
  Container,
  ContentContainer,
  CourseDescription,
  CourseIcon,
  CourseLink,
  CourseTitle,
  Desc,
  DetailsContainer,
  DetailsItem,
  Linkpath,
  SpecialDetailsContainer,
  TitleContainer,
  TxtPrice,
  WhatsappContainer,
} from "./PublicationHeroSectionElements";
import { format } from "date-fns";
import { MdPhone } from "react-icons/md";
import { WhatsappIcon } from "../../globalStyle";
import logoWhatsapp from "../../assets/logos/logo_whatsapp.svg";

const PublicationHeroSection = (props) => {
  const { title, photo, price, desc, call, whatsapp } = props;
  const uri = encodeURI("https://wa.me/+91"+whatsapp+"?text=I'm inquiring about " + title + " publication");

  return (
    <Container>
      <ContentContainer>
        <Linkpath>
          Home / Publications
        </Linkpath>
        <DetailsContainer>
          <CourseIcon src={photo} />
          <TitleContainer>
            <CourseTitle>{title}</CourseTitle>
            <Desc>{desc}</Desc>
            {price && <TxtPrice>{"\u20B9 " + price.toFixed(2)}</TxtPrice>}
            <ContactContainer>
              <CallContainer href={"tel:+91" + call}>
                <MdPhone/>
                <BtnCall>Call Now</BtnCall>
              </CallContainer>
              <WhatsappContainer href={uri}>
                <WhatsappIcon src={logoWhatsapp} />
              </WhatsappContainer>
            </ContactContainer>
          </TitleContainer>
        </DetailsContainer>
      </ContentContainer>
    </Container>
  );
};

export default PublicationHeroSection;
