import styled from "styled-components";

export const LoaderMain = styled.img`
  
  animation: zoominOut 2s infinite;

  @keyframes zoominOut {
    0% {
      transform: scale(0.8);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.8);
    }
  }
`;

export const LoaderBackdrop = styled.div`
  display: ${(props) => (props.open ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #bbe1fa2b;
  direction: ltr;
  unicode-bidi: isolate;
  aspect-ratio: auto 1190 / 1684;
  border-radius: 12px;
`;
