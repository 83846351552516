import { useEffect, useState } from "react"
import { useSelector } from 'react-redux'
import { Navigate} from "react-router-dom"
import DashContentTitle from "../../components/DashContentTitles"
import { AllProductsContainer, Container, PhonesList, ProductsContainer, ProductsSection, SubNavBar} from "./PublicationsElements"
import noDataCover from '../../assets/images/no_data.svg'
import Loader from '../../components/Loader';
import courseService from "../../services/course.service"
import CourseItem from "../../components/CourseItem"
import { ErrorText, NodataCover, NoDataMessageContainer } from "../../globalStyle"
import DashActionbar from "../../components/DashActionBar"
import PageTitle from "../../components/PageTitles"
import publicationService from "../../services/publication.service"
import PublicationItem from "../../components/PublicationItem"

const Publications = (props) => {

    
    const [publications, setPublications] = useState([]);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        getPublications();
      }, []
    );




    const getPublications = () => {
        setPublications([]);
        setLoader(true);
        publicationService.getAllPublications()
        .then((res) => {
            
            setPublications(res.data);
            setLoader(false);
        }).catch(() => {
                setLoader(false);
        });
        
    }




    const searchProducts = (tag) => {
        if(tag !== ''){
            courseService.getAllCourses()
            .then((res) => {
                setPublications(res.data.products);
            });
        } else {
            
            getPublications();
        }
        
    }

    const handleDeleteProduct = (e) => {
        const  newCourses = publications.filter( el => el._id !== e);
        setPublications(newCourses);
    }



    const coursesList = publications.map((item, index) => {
        return <PublicationItem
        key={item._id}
        data={item}
        />
    })
    
    return (
        <Container>
            {/* <DashActionbar onChange= {(tag)=> searchProducts(tag)}/> */}
            <AllProductsContainer>
                <PageTitle title= "Books" subTitle="Our Books"/>
                <ProductsSection>
                    <ProductsContainer>
                        <PhonesList>
                            {publications && coursesList}
                            {(publications.length === 0 && loader === false) ? (
                            <NoDataMessageContainer>
                                <NodataCover src={noDataCover}/>
                                <ErrorText>No publications found!</ErrorText>
                            </NoDataMessageContainer>
                            ):(null)}
                        </PhonesList>
                        
                    </ProductsContainer>
                </ProductsSection>
            <Loader loader={loader}/>
            </AllProductsContainer>
        </Container>
        
    )
}

export default Publications;
