import { Tooltip } from "@mui/material";
import { useState } from "react";
import {
  MdDelete,
  MdEdit,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import publicationService from "../../services/publication.service";
import ModalConfirm from "../ModalConfirm";
import { TxtPrice } from "../PublicationHeroSection/PublicationHeroSectionElements";
import {
  ActionButton,
  ActionButtons,
  BtnExpand,
  BtnKnowMore,
  DataContainer,
  SDesc,
  ServiceItemContainer,
  SIcon,
  STitle,
} from "./MyPublicationItemElements";

const MyPublicationItem = (props) => {
  const { data, edit, onDeleteItem } = props;

  const navigate = useNavigate();

  const [confirmModal, showConfirmModal] = useState(false);
  const [modalData, setModalData] = useState({
    message: "",
    title: "",
  });

  const handleConfirmModalClose = (e) => {
    if (e) {
      onDelete();
    }
    showConfirmModal(false);
  };

  const [expand, setExpand] = useState(false);

  const onDelete = () => {
    publicationService
      .deletePublication(data._id)
      .then((res) => {
        
        onDeleteItem(data._id);
        showConfirmModal(false);
      })
      .catch((err) => console.log(err));
  };

  return (
    <ServiceItemContainer >
      <SIcon src={data.photo} />
      <DataContainer>
        <STitle>{data.title}</STitle>
        {data.price && <TxtPrice>{"\u20B9 " + data.price.toFixed(2)}</TxtPrice>}
        <SDesc expand={expand}>{data.description}</SDesc>
        {edit && (
          <BtnExpand
            size="small"
            color="info"
            endIcon={expand ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
            onClick={() => setExpand(!expand)}
          >
            {expand ? "View less" : "View more"}
          </BtnExpand>
        )}
      </DataContainer>
      {edit && (
        <ActionButtons>
          <Tooltip title="edit">
            <ActionButton
              bg="#757575"
              aria-label=""
              component="span"
              size="small"
              onClick={(e) => {
                e.preventDefault();
                navigate(
                  `/dashboard/publications/edit-publications/${data._id}`
                );
              }}
            >
              <MdEdit />
            </ActionButton>
          </Tooltip>
          <Tooltip title="delete">
            <ActionButton
              bg="#B71C1C"
              aria-label=""
              component="span"
              size="small"
              onClick={(e) => {
                e.preventDefault();
                setModalData({
                  message: "Are you sure want to delete ?",
                  title: "Delete",
                });
                showConfirmModal(true);
              }}
            >
              <MdDelete />
            </ActionButton>
          </Tooltip>
        </ActionButtons>
      )}
      <ModalConfirm
        open={confirmModal}
        title={modalData.title}
        message={modalData.message}
        ok="Yes"
        cancel="No"
        onClose={handleConfirmModalClose}
      />
    </ServiceItemContainer>
  );
};

export default MyPublicationItem;
