import "./App.css";
import Home from "./pages/Home/Home";
import Navbar from "./components/Navbar";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import SignIn from './pages/SignIn/SignIn';
import Dashboard from "./pages/Dashboard/Dashboard";
import { useSelector } from "react-redux";
import { history } from "./helpers/history";
import Courses from "./pages/Courses/Courses";
import ContactUs from "./pages/ContactUs/ContactUs";
import Footer from "./components/Footer";
import Course from "./pages/Course/Course";
import News from "./pages/News/News";
import ScrollToTop from "./scrollToTop";
import Publications from "./pages/Publications/Publications";
import Publication from "./pages/Publication/Publication";
import About from "./pages/about/About";
import Chiraku from "./pages/Chiraku/Chiraku";
import PrivacyPolicies from "./pages/PrivacyPolicies/PrivacyPolicies";
import TermsAndConditions from "./pages/TermsNConditions/TermsNConditions";
import Categories from "./pages/Categories/Categories";

function App() {
 
  const { isLoggedIn } = useSelector((state) => state.auth);
  
  return (
    <Router history={history}>
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route  path="/" caseSensitive  element={<Home/>} />
        <Route path="/login" element={<SignIn/>} />
        <Route path="/courses/categories" element={<Categories/>} />
        <Route path="/courses/:category/courses" element={<Courses/>} />
        <Route path="/chiraku" element={<Chiraku/>} />
        <Route path="/courses/course/:id" element={<Course/>} />
        <Route path="/publications" element={<Publications/>} />
        <Route path="/news/news/:id" element={<News/>} />
        <Route path="/publications/publication/:id" element={<Publication/>} />
        <Route path="/contact" element={<ContactUs/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/dashboard/*" element={<Dashboard/>} />
        <Route path="/privacy-policy" element={<PrivacyPolicies/>} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions/>} />
      </Routes>
      {isLoggedIn ? null : <Footer />}
    </Router>
  );
}

export default App;
