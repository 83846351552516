import { Link as LinkR} from "react-router-dom";
import styled from "styled-components";

export const Container = styled(LinkR)`
    text-decoration: none;
    background: #fff;
    border-radius: .5rem;
    border: 1px solid #EDEDED;
    padding: 1rem;
    display: flex;
    flex: 0 0 calc(50% - 2.8rem);
    justify-content: center;
    transition: all 300ms;
    /* animation: slide-in-top 600ms; */
    &:hover{
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
        opacity: 0.9;
    }
`

export const MenuTitle = styled.h3`
    text-transform: uppercase;
    color: #212121;
`