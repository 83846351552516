import ServiceItem from "../ServiceItem";
import {
  CarouselContainer,
  ServicesSectionContainer,
  TitleContainer,
  TitlePartOne,
  TitlePartTwo,
} from "./TestimonialSectionElements";
import { useEffect, useState } from "react";
import CarouselAuto from "../CarouselAuto";
import achieverService from "../../services/achiever.service";
import AchieverItem from "../AchieverItem";
import CarouselAchievers from "../CarouselAchievers";
import YoutubeEmbed from "../YoutubeEmbed";
import testimonialService from "../../services/testimonial.service";
import CarouselTestimonials from "../CarouselTestimonials";

const TestimonialSection = () => {
  const [testimonials, setTestimonials] = useState([]);


  useEffect(()=> {
    getAllTestimonials();
  },[])

  const getAllTestimonials = () => {
    testimonialService
      .getAllTestimonials()
      .then((res) => {
        
        setTestimonials(res.data);
      })
      .catch(() => {});
  };

  const testimonialList = testimonials.map((item, index) => {
    return <YoutubeEmbed link={item.link} title={item.title} description={item.description}/>;
  });


  return (
    <ServicesSectionContainer>
      <TitleContainer>
        <TitlePartOne>OUR</TitlePartOne>
        <TitlePartTwo>TESTIMONIALS</TitlePartTwo>
      </TitleContainer>
      <CarouselContainer>
        {testimonials && <CarouselTestimonials items={testimonials}/>}
      </CarouselContainer>
    </ServicesSectionContainer>
  );
};

export default TestimonialSection;
