import DashContentTitle from "../../components/DashContentTitles";
import { useDispatch, useSelector } from "react-redux";
import {
  BtnCancel,
  BtnSubmit,
  BtnUpload,
  ButtonContainer,
  CategoriesInput,
  CategoriesOption,
  CurrencyType,
  FormAreaContainer,
  FormContainer,
  FormItem,
  FormTitle,
  ImagePickerContainer,
  InputText,
  PickedImage,
  PickerIcon,
  PickerText,
  PriceInputContainer,
} from "./AddAchieversElements";
import icPicker from "../../assets/icons/ic_image_picker.svg";
import { useEffect, useRef, useState } from "react";
import ProductsService from "../../services/products-service";
import UploadImagesService from "../../services/upload-files-service";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import ModalResult from "../../components/ModalResult";
import ModalConfirm from "../../components/ModalConfirm";
import { ADD_ACHIEVERS, ADD_COURSE, EDIT_ACHIEVERS } from "../../actions/types";
import categoryService from "../../services/category.service";
import SelectInput from "../../components/SelectInput/SelectInput";
import TextinputHorizontal from "../../components/TextInputHorizontal/TextinputHorizontal";
import TextareaInput from "../../components/TextareaInput/Textareainput";
import courseService from "../../services/course.service";
import achieverService from "../../services/achiever.service";

const AddAchievers = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const imageInput = useRef(null);

  const [modal, showModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const [data, setData] = useState({
    photo: "",
    title: "",
    category: "",
    course: "",
  });

  const [fieldstatustext, setFieldstatusText] = useState({
    photo: {
      text: "",
      color: "",
    },
    title: "",
    category: "",
    course: "",
  });



  const [categories, setCategories] = useState([]);
  const [courseNames, setCourseNames] = useState([]);

  const [photo, setPhoto] = useState();
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [uploadImageStatus, setUploadImageStatus] = useState(false);

  const [confirmModal, showConfirmModal] = useState(false);
  const [modalData, setModalData] = useState({
    message: "",
    title: "",
  });
  const handleConfirmModalClose = (e) => {
    if (e) {
      updateProduct();
    }
    showConfirmModal(false);
  };

  useEffect(() => {
    
    getCategories();

    if (id) {
      dispatch({
        type: EDIT_ACHIEVERS,
      });
      getDetails();
    } else {
      dispatch({
        type: ADD_ACHIEVERS,
      });
    }

    
  }, []);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  const getDetails = () => {
    achieverService
      .getAchievers(id)
      .then((res) => {
        
        setData({
          photo: res.data.photo,
          title: res.data.title,
          category: res.data.category,
          course: res.data.course
        });
        getCourseNames(res.data.category);
        setPhoto(res.data.photo);
      })
      .catch(() => {});
  };

  const getCategories = () => {
    categoryService.getAllCourseCategories().then((res) => {
      setCategories(
        res.data.map((cat) => ({ value: cat._id, title: cat.name }))
      );
    });
  };

  const getCourseNames = (catID) => {
    courseService.getCoursesNameByCatID(catID).then((res) => {
      setCourseNames(
        res.data.map((cat) => ({ value: cat._id, title: cat.title }))
      );
    });
  };

  const onChangePhoto = (e) => {
    if (e.target.files && e.target.files[0]) {
      setPhoto(URL.createObjectURL(e.target.files[0]));
      setSelectedPhoto(e.target.files[0]);
      setData({
        ...data,
        photo: "",
      });
    }
  };

  const handleInputChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    setData({
      ...data,
      [name]: value,
    });

    if (name == "category") {
      getCourseNames(value);
    }
  };

  const handleModalClose = (e) => {
    showModal(false);
    if (id) {
      navigate(-1);
      return;
    }
  };

  const addNewProduct = () => {
    if (validateForm()) {
      
      achieverService
        .addNewAchiever(data)
        .then((res) => {
          setModalMessage("Achiever added successfully!");
          showModal(true);
          onReset();
        })
        .catch((err) => console.log(err));
    }
  };

  const updateProduct = () => {
    if (validateForm()) {
      achieverService
        .updateAchievers(id, data)
        .then((res) => {
          setModalMessage("Achievers details updated successfully!");
          showModal(true);
        })
        .catch((err) => console.log(err));
    }
  };

  const onCancel = () => {
    if (id) {
      navigate(-1);
      return;
    }

    if (data.photo !== "") {
      let nameSplit = data.photo.split("/");
      let key = nameSplit[nameSplit.length - 1];
      ProductsService.deleteUploadedImages([key])
        .then((result) => {})
        .catch((err) => console.log(err));
      setData({
        photo: "",
        title: "",
        category: "",
        course: "",
      });
    } else {
      setData({
        photo: "",
        title: "",
        category: "",
        course: "",
      });
    }

    setPhoto(null);
    setSelectedPhoto(null);
  };

  const onReset = () => {
    setData({
      photo: "",
      title: "",
      category: "",
      course: "",
    });
    setFieldstatusText({
      photo: "",
      title: "",
      category: "",
      course: "",
    });
    setPhoto(null);
    setSelectedPhoto(null);
  };

  const uploadImage = () => {
    if (selectedPhoto === null) {
      setFieldstatusText({
        ...fieldstatustext,
        photo: {
          text: "No photos selected!",
          color: "#B71C1C",
        },
      });
      return;
    }

    UploadImagesService.upload(selectedPhoto, (event) => {
      setUploadImageStatus(true);
    })
      .then((res) => {
        setData({
          ...data,
          photo: res.data.Location,
        });
        setFieldstatusText({
          ...fieldstatustext,
          photo: {
            text: "Photos Uploaded Successfully!",
            color: "#2E7D32",
          },
        });
        setUploadImageStatus(false);
      })
      .catch((err) => {
        setFieldstatusText({
          ...fieldstatustext,
          photo: {
            text: "Please try agin. Photos not uploaded!",
            color: "#F57F17",
          },
        });
        setUploadImageStatus(false);
      });
  };

  const validateForm = () => {
    if (data.photo === "") {
      setFieldstatusText({
        ...fieldstatustext,
        photo: {
          text: "No Photo uploaded!",
          color: "#2E7D32",
        },
      });
      alert("No Photo uploaded!");
      return false;
    }

    if (data.title === "") {
      setFieldstatusText({
        ...fieldstatustext,
        title: "* required",
      });
      alert("Title required!");
      return false;
    }
    if (data.category === "") {
      setFieldstatusText({
        ...fieldstatustext,
        category: "* required",
      });
      alert("Category required!");
      return false;
    }

    return true;
  };

  return (
    <FormAreaContainer>
      <DashContentTitle />

      <FormContainer>
        <FormItem>
          <FormTitle>Achiever's Image :</FormTitle>

          <ImagePickerContainer onClick={() => imageInput.current.click()}>
            {photo ? (
              <>
                <PickedImage src={photo} />
                <PickerText
                  style={{
                    background: "rgba(0,0,0,0.3)",
                    padding: ".3rem .6rem",
                    borderRadius: ".3rem",
                  }}
                >
                  Change Image
                </PickerText>
              </>
            ) : (
              <>
                <PickerIcon src={icPicker} />
                <PickerText>Upload Image</PickerText>
              </>
            )}
          </ImagePickerContainer>
          {data.photo === "" && photo && (
            <BtnUpload onClick={uploadImage}>Upload</BtnUpload>
          )}

          <input
            ref={imageInput}
            type="file"
            accept="image/png, image/jpeg"
            style={{ visibility: "hidden" }}
            onChange={(e) => onChangePhoto(e)}
          />
        </FormItem>
        <TextinputHorizontal
          type="text"
          textTransform="capitalize"
          title="title"
          error={fieldstatustext["title"]}
          placeholder="Enter Title"
          name="title"
          maxLength={200}
          value={data.title}
          onChange={(e) => handleInputChange(e)}
        />
        <SelectInput
          title="Course category"
          error={fieldstatustext["category"]}
          name="category"
          placeholder="Choose category"
          options={categories}
          value={data.category}
          onChange={(e) => handleInputChange(e)}
        />
        <SelectInput
          title="Course Name"
          error={fieldstatustext["course"]}
          name="course"
          placeholder="Choose Course Name"
          options={courseNames}
          value={data.course}
          onChange={(e) => handleInputChange(e)}
        />
        <FormItem>
          <ButtonContainer>
            <BtnCancel onClick={onCancel}>Cancel</BtnCancel>
            {id ? (
              <BtnSubmit onClick={(e) => {
                setModalData({
                  message: "Are you sure want to update ?",
                  title: "Update",
                });
                showConfirmModal(true);
              }}>Update</BtnSubmit>
            ) : (
              <BtnSubmit onClick={addNewProduct}>Submit</BtnSubmit>
            )}
          </ButtonContainer>
        </FormItem>
      </FormContainer>
      <ModalResult
        open={modal}
        message={modalMessage}
        onClose={handleModalClose}
      />
       <ModalConfirm
        open={confirmModal}
        title={modalData.title}
        message={modalData.message}
        ok="Yes"
        cancel="No"
        onClose={handleConfirmModalClose}
      />
    </FormAreaContainer>
  );
};

export default AddAchievers;
