import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    background: #F5F5F5;
    gap: 1rem;
    padding: 1rem;

    @media screen and (max-width: 768px){
       flex-direction: column;
    }
`

export const DashContentContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`