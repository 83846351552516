import styled from "styled-components";

export const ContactSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-image: linear-gradient(#E1F5FE, white);
  margin-top: 2rem;

  
`;
export const ContactContents = styled.div`
  display: flex;
  gap: 2rem;
  margin-top: 2rem;
  width: 1100px;

  position: relative;
  @media screen and (max-width: 768px) {
    margin: 1rem;
    gap: 2rem;
    width: 100%;
    flex-direction: column-reverse;
  }
`;
export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

export const TitlePartOne = styled.h2`
  color: #01579b;
`;
export const TitlePartTwo = styled.h2`
  color: #40c4ff;
`;
export const DetailsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;
export const AddressContainer = styled.div`
  padding: 1rem;
  display: flex;
  @media screen and (max-width: 768px){
        flex-direction: column;
    }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.5rem;
  align-items: flex-start;
  width: 100%;
`;

export const MapContainer = styled.a`
  width: 100%;
  text-decoration: none;
`;
export const Map = styled.img`
  width: 100%;
`;
export const AddressItem = styled.div`
  display: flex;
  color: #01579b;
  gap: 1rem;
  margin-bottom: 1rem;
  align-items: center;
`;
export const AddressData = styled.p`
  margin: 0;
  color: #424242;
`;

export const BtnSubmit = styled.button`
    color: #fff;
    box-sizing:border-box;
    font-size: 1rem;
    border:0;
    background: #262626;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
    transition: all 200ms;
    &:hover{
      box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    }
`