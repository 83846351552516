import http from "../http-common";
import authHeader from "./auth-header";

class CourseService{

   

    getCoursesNameByCatID(catID){
        return http.get(`/course/all/${catID}`);
    }

    getCoursesNames(){
        return http.get('/course/courseNames');
    }

    getAllCourses(){
        return http.get('/course/all');
    }

    getAllCoursesByCategory(catID){
        return http.get(`/course/all/ByCategory/${catID}`);
    }

    getLimitedCourses(count){
        return http.get(`course/courses/${count}`);
    }
    getCourseById(id){
        return http.get(`/course/courseById/${id}`);
    }


    addNewCourse(data){
        return http.post(`/course/add-new-course`, JSON.stringify(data),{headers: authHeader(), withCredentials: true});

    }

    updateCourse(id, data){
        return http.put(`/course/update/${id}`, JSON.stringify(data),{headers: authHeader(), withCredentials: true});

    }

    deleteCourse(id){
        return http.delete(`/course/delete/${id}`,{headers: authHeader(), withCredentials: true});
    }

     // delete uploaded images from storage
     deleteUploadedImages(images){
        const files = { files: images}
        const data =  JSON.stringify(files);

        return http.put('/products/delete-images', data, { headers: authHeader(), withCredentials: true });
    }

  
}


export default new CourseService();