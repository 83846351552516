import React from 'react'
// leader 
import { Backdrop,} from '@mui/material';
import loaderImage from '../../assets/logos/logo.svg'
import { LoaderBackdrop, LoaderMain } from './LoaderElements';



const PDFLoader = (props) => {

    return (
        <LoaderBackdrop open={props.loader} >
            <LoaderMain src={loaderImage}/>
          </LoaderBackdrop>
    )
}

export default PDFLoader
