import {
  DASHBOARD,
  ADD_COURSE,
  MANAGE_COURSE,
  ADD_ACHIEVERS,
  VIEW_ACHIEVERS,
  ADD_PUBLICATIONS,
  MANAGE_PUBLICATIONS,
  ENQUIRIES,
  ADD_NEWS,
  MANAGE_NEWS,
  ADD_TESTIMONIALS,
  VIEW_TESTIMONIALS,
  EDIT_COURSE,
  EDIT_ACHIEVERS,
  EDIT_TESTIMONIALS,
  EDIT_NEWS,
  EDIT_PUBLICATIONS,
  ADD_DEMO,
  VIEW_DEMO,
  EDIT_DEMO,
  VIEW_ENTRIES,
  EDIT_DETAILS,
  ADD_STUDY_MATERIALS,
  MANAGE_STUDY_MATERIALS,
  EDIT_STUDY_MATERIALS,
  GENERAL,
} from "../actions/types";

const initialState = { title: "overview", subTitle: "dashboard/overview" };

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case DASHBOARD:
      return {
        title: "dashboard",
        subTitle: "/ ",
      };
    case ADD_COURSE:
      return {
        title: "add course",
        subTitle: "dashboard / courses / ",
      };
    case MANAGE_COURSE:
      return {
        title: "manage course",
        subTitle: "dashboard / courses / ",
      };
    case EDIT_COURSE:
      return {
        title: "edit course",
        subTitle: "dashboard / courses / ",
      };
    case ADD_ACHIEVERS:
      return {
        title: "add achievers",
        subTitle: "dashboard / achievers / ",
      };
    case VIEW_ACHIEVERS:
      return {
        title: "view achievers",
        subTitle: "dashboard / achievers / ",
      };
    case EDIT_ACHIEVERS:
      return {
        title: "edit achievers",
        subTitle: "dashboard / achievers / ",
      };
    case ADD_TESTIMONIALS:
      return {
        title: "add testimonials",
        subTitle: "dashboard / testimonials / ",
      };
    case VIEW_TESTIMONIALS:
      return {
        title: "view testimonials",
        subTitle: "dashboard / testimonials / ",
      };
    case EDIT_TESTIMONIALS:
      return {
        title: "edit testimonials",
        subTitle: "dashboard / testimonials / ",
      };
      
      case ADD_DEMO:
      return {
        title: "add demo class",
        subTitle: "dashboard / demo classes / ",
      };
    case VIEW_DEMO:
      return {
        title: "view demo classes",
        subTitle: "dashboard / demo classes / ",
      };
    case EDIT_DEMO:
      return {
        title: "edit demo classes",
        subTitle: "dashboard / demo classes / ",
      };
    case ADD_PUBLICATIONS:
      return {
        title: "add publications",
        subTitle: "dashboard / publications / ",
      };
    case MANAGE_PUBLICATIONS:
      return {
        title: "manage publications",
        subTitle: "dashboard / publications / ",
      };
    case EDIT_PUBLICATIONS:
      return {
        title: "edit publications",
        subTitle: "dashboard / publications / ",
      };
    case ADD_NEWS:
      return {
        title: "add news",
        subTitle: "dashboard / news / ",
      };
    case MANAGE_NEWS:
      return {
        title: "manage news",
        subTitle: "dashboard / news / ",
      };
    case EDIT_NEWS:
      return {
        title: "edit news",
        subTitle: "dashboard / news / ",
      };

    case ENQUIRIES:
      return {
        title: "enquiries",
        subTitle: "dashboard / ",
      };

      case GENERAL:
      return {
        title: "general details",
        subTitle: "dashboard / ",
      };

      case VIEW_ENTRIES:
      return {
        title: "view chiraku entries",
        subTitle: "dashboard / chiraku / ",
      };
    case EDIT_DETAILS:
      return {
        title: "edit details",
        subTitle: "dashboard / chiraku / ",
      };

    case ADD_STUDY_MATERIALS:
      return {
        title: "add study materials",
        subTitle: "dashboard / study materials / ",
      };
    case MANAGE_STUDY_MATERIALS:
      return {
        title: "view study materials",
        subTitle: "dashboard / study materials / ",
      };
    case EDIT_STUDY_MATERIALS:
      return {
        title: "edit study materials",
        subTitle: "dashboard / study materials / ",
      };
    default:
      return state;
  }
}
