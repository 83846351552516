import { useEffect, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { Navigate} from "react-router-dom"
import DashContentTitle from "../../components/DashContentTitles"
import { AllProductsContainer, Container, PhonesList, ProductsContainer, ProductsSection, SubNavBar} from "./MyNewsElements"
import noDataCover from '../../assets/images/no_data.svg'
import Loader from '../../components/Loader';
import courseService from "../../services/course.service"
import CourseItem from "../../components/CourseItem"
import { ErrorText, NodataCover, NoDataMessageContainer } from "../../globalStyle"
import DashActionbar from "../../components/DashActionBar"
import { MANAGE_NEWS, MANAGE_PUBLICATIONS } from "../../actions/types"
import publicationService from "../../services/publication.service"
import PublicationItem from "../../components/PublicationItem"
import newsService from "../../services/news.service"
import NewsItem from "../../components/NewsItem"

const MyNews = (props) => {

    const { user: currentUser } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const [news, setNews] = useState([]);
    const [loader, setLoader] = useState(false);

    useEffect(() => {

        dispatch({
            type: MANAGE_NEWS,
          });

        getAllNews();
      }, []
    );


    if (!currentUser) {
        return <Navigate to="/login" />;
    }


    const getAllNews = () => {
        setNews([]);
        setLoader(true);
        newsService.getAllNews()
        .then((res) => {
            
            setNews(res.data);
            setLoader(false);
        }).catch(() => {
                setLoader(false);
        });
        
    }




    const searchProducts = (tag) => {
        if(tag !== ''){
            courseService.getAllCourses()
            .then((res) => {
                setNews(res.data.products);
            });
        } else {
            
            getAllNews();
        }
        
    }

    const handleDeleteProduct = (e) => {
        const  newCourses = news.filter( el => el._id !== e);
        setNews(newCourses);
    }



    const newsList = news.map((item, index) => {
        return <NewsItem
        key={item._id}
        data={item}
        edit={true}
        onDeleteItem = {handleDeleteProduct}
        />
    })
    
    return (
        <Container>
            {/* <DashActionbar onChange= {(tag)=> searchProducts(tag)}/> */}
            <AllProductsContainer>
                <DashContentTitle title= "all products"/>
                <ProductsSection>
                    <ProductsContainer>
                        <PhonesList>
                            {news && newsList}
                            {(news.length === 0 && loader === false) ? (
                            <NoDataMessageContainer>
                                <NodataCover src={noDataCover}/>
                                <ErrorText>No courses found!</ErrorText>
                            </NoDataMessageContainer>
                            ):(null)}
                        </PhonesList>
                        
                    </ProductsContainer>
                </ProductsSection>
            <Loader loader={loader}/>
            </AllProductsContainer>
        </Container>
        
    )
}

export default MyNews;
