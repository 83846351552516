import React, { useEffect, useState } from "react";
import { Container } from "../../globalStyle";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { TabList, TabPanel, TabContext } from "@mui/lab";
import {
  AchieverConrainer,
  AchieverContainer,
  CenterPanel,
  ContentContainer,
  Desc,
  ListContainer,
  NotLink,
  RightPanel,
  RightPanelTitle,
  TabContainer,
  TestimonialContainer,
} from "./NewsElements";
import { useParams } from "react-router-dom";
import courseService from "../../services/course.service";
import AchieverItem from "../../components/AchieverItem";
import achieverService from "../../services/achiever.service";
import CarouselAuto from "../../components/CarouselAuto";
import CarouselAchievers from "../../components/CarouselAchievers";
import YoutubeEmbed from "../../components/YoutubeEmbed";
import SubjectItem from "../../components/SubjectItem";
import testimonialService from "../../services/testimonial.service";
import newsService from "../../services/news.service";
import NewsHeroSection from "../../components/NewsHeroSection";
import { format } from "date-fns";

const News = () => {
  const { id } = useParams();

  const [category, setCategory] = useState({
    name: "",
    _id: "",
  });
  const [news, setNews] = useState({
    photo: "",
    title: "",
    description: "",
    category: "",
    link: "",
    vacancies: "",
    course: "",
    date: new Date(),
    postDate: "",
  });

  useEffect(() => {
    getNewsDetails();
  }, []);

  const getNewsDetails = () => {
    newsService
      .getNewsById(id)
      .then((res) => {
        
        setNews(res.data);
      })
      .catch(() => {});
  };

  return (
    <Container>
      <NewsHeroSection
        title={news.title}
        date={news.date}
        photo={news.photo}
        category={news.category.name}
        vacancies={news.vacancies}
        course={news.course}
      />
      <ContentContainer>
        <Desc>{news.description}</Desc>
        {news.category.name === "Job Notification" ? (
          <>
            <NotLink href={news.link} target="_blank">View Notification</NotLink>
          </>
        ) : (
          <>
          </>
        )}
      </ContentContainer>
    </Container>
  );
};

export default News;
