import http from "../http-common";
import authHeader from "./auth-header";

class MaterialsService{

    getAllMaterialsByCourse(id){
        return http.get(`/materials/all/byCourseID/${id}`);
    }


    addNewMaterial(data){
        return http.post(`/materials/add-new-material`, JSON.stringify(data),{headers: authHeader(), withCredentials: true});

    }

    getAllMaterials(){
        return http.get(`/materials/all`);
    }

    getMaterials(id){
        return http.get(`/materials/${id}`);
    }

    updateMaterials(id, data){
        return http.put(`/materials/update/${id}`, JSON.stringify(data),{headers: authHeader(), withCredentials: true});

    }

    deleteMaterials(id){
        return http.delete(`/materials/delete/${id}`,{headers: authHeader(), withCredentials: true});
    }

    

  
}


export default new MaterialsService();