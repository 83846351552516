import React from "react";
import {
  BtnCall,
  CallContainer,
  ContactContainer,
  Container,
  ContentContainer,
  CourseDescription,
  CourseIcon,
  CourseTitle,
  DetailsContainer,
  EmbedContainer,
  Linkpath,
  TitleContainer,
  WhatsappContainer,
} from "./ChirakuHeroSectionElements";
import logoWhatsapp from "../../assets/logos/logo_whatsapp.svg";
import { WhatsappIcon } from "../../globalStyle";
import { MdPhone } from "react-icons/md";
import YoutubeEmbedVideo from "../YoutubeEmbedVideo";

const ChirakuHeroSection = (props) => {
  const { title, photo, video, call, whatsapp } = props;
  const uri = encodeURI(
    "https://wa.me/+91" +
      whatsapp +
      "?text=I'm inquiring about " +
      title
  );
  return (
    <Container  bg={photo}>
      <img src={photo} style={{visibility: "hidden", width: "100%"}} />
      {/* <ContentContainer>
        <Linkpath>
          Home / Chiraku
        </Linkpath>
        <DetailsContainer>
          <CourseIcon src={photo} />
          <TitleContainer>
            <CourseTitle>{title}</CourseTitle>
            <ContactContainer>
              <CallContainer href={"tel:+91" + call}>
                <MdPhone />
                <BtnCall>Call Now</BtnCall>
              </CallContainer>
              <WhatsappContainer href={uri}>
                <WhatsappIcon src={logoWhatsapp} />
              </WhatsappContainer>
            // </ContactContainer>
          </TitleContainer>
          {video && (
            <EmbedContainer>
              <YoutubeEmbedVideo link={video} />
            </EmbedContainer>
          )}
        </DetailsContainer>
      </ContentContainer> */}
    </Container>
  );
};

export default ChirakuHeroSection;
