import ServiceItem from "../ServiceItem"
import { ContentTitle, ServicesContents, ServicesSectionContainer, TitleContainer, TitlePartOne, TitlePartTwo } from "./ServicesElements"
import icHardware from '../../assets/icons/ic_hardware.svg'
import icSoftware from '../../assets/icons/ic_software.svg'
import icDatatransfer from '../../assets/icons/ic_data_transfer.svg'
import icEWaste from '../../assets/icons/ic_ewaste.svg'
import icWarranty from '../../assets/icons/ic_warranty.svg'
import { useEffect, useState } from "react"
import NewsItemSmall from "../NewsItemSmall"
import CarouselNews from "../CarouselNews"
import newsService from "../../services/news.service"


const NewsSection = () => {
    const [news, setNews] = useState([]);


  useEffect(()=> {
    getNews();
  },[]);

    const getNews = () => {
        newsService
          .getAllNews()
          .then((res) => {
            setNews(res.data);
          })
          .catch(() => {});
      };
    

 
    return (
        <ServicesSectionContainer>
            {/* <ContentTitle>Latest News</ContentTitle> */}
            
            <ServicesContents>
                {news && <CarouselNews data={news}/>}
            </ServicesContents>
        </ServicesSectionContainer>
    )
}

export default NewsSection
