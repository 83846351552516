import React from "react";
import { RiFileExcel2Fill } from "react-icons/ri";
import { BtnExport } from "./ExcelExportElements";
import XLSX from "sheetjs-style";
import { saveAs } from "file-saver";
import {format} from 'date-fns'

const ExcelExport = ({ excelData, fileName }) => {
    const exportData = JSON.parse(JSON.stringify(excelData));
    exportData.forEach(item => {
        item.date = format(new Date(item.date), 'dd/MM/yyyy');
        delete item['_id'];
    });
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const extension = ".xlsx";

  

  const exportToExcel = async () => {
    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    saveAs(data, fileName + extension);
  };
  return (
    <BtnExport
      variant="outlined"
      endIcon={<RiFileExcel2Fill />}
      onClick={(e) => exportToExcel()}
    >
      Export
    </BtnExport>
  );
};

export default ExcelExport;
