import { Tooltip } from "@mui/material";
import { useState } from "react";
import {
  MdDelete,
  MdEdit,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Row } from "../../globalStyle";
import achieverService from "../../services/achiever.service";
import ModalConfirm from "../ModalConfirm";
import {
  ActionButton,
  ActionButtons,
  BtnExpand,
  BtnKnowMore,
  CoverContainer,
  DataContainer,
  SDesc,
  ServiceItemContainer,
  SIcon,
  STitle,
} from "./MyAchieversItemElements";

const MyAchieversItem = (props) => {
  const { data, edit, onDeleteItem } = props;

  const navigate = useNavigate();

  const [confirmModal, showConfirmModal] = useState(false);
  const [modalData, setModalData] = useState({
    message: "",
    title: "",
  });

  const handleConfirmModalClose = (e) => {
    if (e) {
      onDelete();
    }
    showConfirmModal(false);
  };

  const [expand, setExpand] = useState(false);

  const onDelete = () => {
    achieverService
      .deleteAchievers(data._id)
      .then((res) => {
        
        onDeleteItem(data._id);
        showConfirmModal(false);
      })
      .catch((err) => console.log(err));
  };

  return (
    <ServiceItemContainer>
      <CoverContainer>
        <SIcon src={data.photo} />
      </CoverContainer>
      <DataContainer>
        <STitle>{data.title}</STitle>
        <Row style={{ gap: "1rem" }}>
          {data.course?.title && <SDesc>{data.course?.title}</SDesc>}
          {data.category?.name && <SDesc>{data.category?.name}</SDesc>}
        </Row>
      </DataContainer>
      {edit && (
        <ActionButtons>
          <Tooltip title="edit">
            <ActionButton
              bg="#757575"
              aria-label=""
              component="span"
              size="small"
              onClick={() => {
                navigate(
                  `/dashboard/achievers/edit-achievers/${data._id}`
                );
              }}
            >
              <MdEdit />
            </ActionButton>
          </Tooltip>
          <Tooltip title="delete">
            <ActionButton
              bg="#B71C1C"
              aria-label=""
              component="span"
              size="small"
              onClick={() => {
                // setModalCover(confirmDialogCover)
                setModalData({
                  message: "Are you sure want to delete ?",
                  title: "Delete",
                });
                showConfirmModal(true);
              }}
            >
              <MdDelete />
            </ActionButton>
          </Tooltip>
        </ActionButtons>
      )}
      <ModalConfirm
        open={confirmModal}
        title={modalData.title}
        message={modalData.message}
        ok="Yes"
        cancel="No"
        onClose={handleConfirmModalClose}
      />
    </ServiceItemContainer>
  );
};

export default MyAchieversItem;
