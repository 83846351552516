import { FaPhoneAlt } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import { MdPlace } from "react-icons/md";
import { format } from "date-fns";
import {
  AddressData,
  AddressItem,
} from "../ContactSection/ContactSectionElements";
import {
  BtnKnowMore,
  DataContainer,
  MessageContainer,
  SDate,
  SDesc,
  ServiceItemContainer,
  SIcon,
  SSubject,
  STitle,
} from "./EnquiryItemElements";

const EnquiryItem = (props) => {
  const { data } = props;
  return (
    <ServiceItemContainer to={`/courses/course/${data._id}`}>
      <DataContainer>
        <STitle>{data.name}</STitle>
        <SDate>{format(new Date(data.date), "dd MMM yyy p")}</SDate>
      </DataContainer>

      <DataContainer>
        <AddressItem>
          <MdPlace />
          <AddressData>{data?.place}</AddressData>
        </AddressItem>
        <AddressItem>
          <FaPhoneAlt />
          <AddressData>+91 {data?.phone}</AddressData>
        </AddressItem>
        <AddressItem>
          <IoMail />
          <AddressData>{data?.email}</AddressData>
        </AddressItem>
      </DataContainer>
      <MessageContainer>
        <SSubject>{data?.course}</SSubject>
        <SSubject>{data?.subject}</SSubject>
        <SDesc>{data?.message}</SDesc>
      </MessageContainer>
    </ServiceItemContainer>
  );
};

export default EnquiryItem;
