import React, { useState } from "react";
import {
  AddressContainer,
  AddressData,
  AddressItem,
  BtnSubmit,
  ContactContents,
  ContactSectionContainer,
  DetailsContainer,
  FormContainer,
  Map,
  MapContainer,
  TitleContainer,
  TitlePartOne,
  TitlePartTwo,
} from "./ContactSectionElements";
import logo from '../../assets/logos/logo.svg';
import TextInputBox from "../TextInputBox/TextInputBox";
import SelectInputBox from "../SelectInputBox/SelectInputBox";
import TextareaInputBox from "../TextareaInputBox/TextareaInputBox";
import enquiryService from "../../services/enquiry.service";
import ModalResult from "../ModalResult";
import { CompAddressContainer, CompDetails, CompIcon, CompLogo, TxtLabel } from "../../pages/ContactUs/ContactUsElements";
const ContactSectionForm = () => {
  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
    course: "",
  });

  const [fieldstatustext, setFieldstatusText] = useState({
    name: "",
    email: "",
    phone: "",
    course: "",
  });

  const [subjects, setSubjects] = useState([
    {
      value: "ssc",
      title: "SSC",
    },
    {
      value: "psc",
      title: "PSC",
    },
    {
      value: "rrb",
      title: "RRB",
    },
    {
      value: "bankCoaching",
      title: "Bank Coaching",
    },
  ]);

  const [modal, showModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const handleModalClose = (e) => {
    showModal(false);
  };

  const handleInputChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    setData({
      ...data,
      [name]: value,
    });

    setFieldstatusText({
      ...fieldstatustext,
      [name]: "",
    });
  };

  const sendQuery = () => {
    if (validateForm()) {
      enquiryService
        .submitNewEnquiry(data)
        .then((res) => {
          setModalMessage(
            "Thank you, your enquiry has been submitted successfully!"
          );
          showModal(true);
          onReset();
        })
        .catch((err) => console.log(err));
    }
  };

  const validateForm = () => {
    if (data.name === "") {
      setFieldstatusText({
        ...fieldstatustext,
        name: "* required",
      });
      alert("name required!");
      return false;
    }

    // if (data.email !== "")
    //   if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data.email)) {
    //     setFieldstatusText({
    //       ...fieldstatustext,
    //       email: "* email ID not valid!",
    //     });
    //     return false;
    //   }

    if (data.phone === "") {
      setFieldstatusText({
        ...fieldstatustext,
        phone: "* Phone number required!",
      });
      alert("Phone number required!");
      return false;
    } else if (data.phone.length !== 10) {
      setFieldstatusText({
        ...fieldstatustext,
        phone: "* 10 digits required!",
      });
      return false;
    }

    // if (data.place === "") {
    //   setFieldstatusText({
    //     ...fieldstatustext,
    //     place: "* required",
    //   });
    //   alert("place required!");
    //   return false;
    // }

    if (data.course === "") {
      setFieldstatusText({
        ...fieldstatustext,
        course: "* required",
      });
      alert("course prefer required!");
      return false;
    }

    // if (data.message === "") {
    //   setFieldstatusText({
    //     ...fieldstatustext,
    //     message: "* required",
    //   });
    //   alert("message required!");
    //   return false;
    // }

    return true;
  };

  const onReset = () => {
    setData({
      name: "",
      email: "",
      phone: "",
      course: "",
    });
    setFieldstatusText({
      name: "",
      email: "",
      phone: "",
      course: "",
    });
  };

  return (
    <ContactSectionContainer>
      <TitleContainer>
        <TitlePartTwo>Contact Us</TitlePartTwo>
      </TitleContainer>
      <ContactContents>
        <CompDetails>
          <CompLogo to="/">
            <CompIcon src={logo} />
          </CompLogo>
          <CompAddressContainer>
            <TxtLabel>City Gallery, YMCA</TxtLabel>
            <TxtLabel>Near Paragon Hotel, Kannur Road</TxtLabel>
            <TxtLabel>Kozhikode - 673001</TxtLabel>
            <TxtLabel>cubitcalicut@gmail.com | +91 894 33 66 588</TxtLabel>
          </CompAddressContainer>
        </CompDetails>
        <FormContainer>
          <TextInputBox
            name="name"
            type="text"
            textTransform="capitalize"
            placeholder="Full Name"
            maxLength={60}
            value={data.name}
            error={fieldstatustext["name"]}
            onChange={(e) => handleInputChange(e)}
          />
          <TextInputBox
            type="tel"
            name="phone"
            error={fieldstatustext["phone"]}
            placeholder="phone number"
            maxLength={10}
            value={data.phone}
            onChange={(e) => handleInputChange(e)}
          />
          <TextInputBox
            type="text"
            name="email"
            placeholder="example@abc.com"
            value={data.email}
            error={fieldstatustext["email"]}
            onChange={(e) => handleInputChange(e)}
          />
          <SelectInputBox
            title="course"
            error={fieldstatustext["course"]}
            name="course"
            placeholder="Course Prefer"
            options={subjects}
            value={data.course}
            onChange={(e) => handleInputChange(e)}
          />
          <BtnSubmit onClick={sendQuery}>Submit</BtnSubmit>
        </FormContainer>
      </ContactContents>
      <ModalResult
        open={modal}
        message={modalMessage}
        onClose={handleModalClose}
      />
    </ContactSectionContainer>
  );
};

export default ContactSectionForm;
