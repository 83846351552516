import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction:column;
    width: 100%;
  align-items: center;
  background: linear-gradient(#fff,#f5f5f5);

  
    
`
export const ProductsContainer = styled.div`
    display: flex;
    flex-direction:column;
    padding: 1rem;
`

export const AllProductsContainer = styled.div`
    width: 100%;
    max-width: 1100px;
    

`
export const PhonesList =styled.div`
    margin-bottom: 1rem;
  display: flex;
  gap: 1rem;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 2rem;
  width: 1100px;

  position: relative;
  @media screen and (max-width: 768px) {
    gap: 1rem;
    width: 100%;
  }
    
`
export const ProductsSection = styled.div`
    width: 100%;
    height: 500px;
    overflow-y: scroll;
    @media screen and (max-width: 768px){
       height: 100%;
    }


    &::-webkit-scrollbar-track
    {
        background-color: #f5f5f5;
        border-radius: 0 0.5rem 0.5rem 0;
    }

   &::-webkit-scrollbar
    {   
        
        width: 6px;
        background-color: #f5f5f5;
    }

   &::-webkit-scrollbar-thumb
    {
        border-radius: 10px;
        background-color: #f5f5f5;
    }
`
export const SubNavBar = styled.div`
    display: flex;
    margin-top: 1rem;
`

export const BtnLoadMore = styled.div`
    text-decoration: none;
    color: #fff;
    background: #FC3535;
    padding: 16px 24px;
    border-radius: 4px;
    text-transform: capitalize;
    font-weight: 600;
    transition: all 400ms;
    display: flex;
    justify-content: center;
    align-self: center;
    width: 100px;
    cursor: pointer;
    &:hover{
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
`