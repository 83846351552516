import React, { useState, } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { BtnForgot, BtnLogin, ButtonContainer, FieldContainer, FieldName, InputStatusText, LoginContainer, LoginInTitle, TextInput } from './LoginElements'
import { login } from "../../actions/auth";
import { useNavigate } from 'react-router-dom';



const Login = (props) => {

    const [user, setUser] = useState({
        username: '',
        password: ''
    });

    const [fieldstatustext, setFieldstatusText] = useState({
        username: '',
        password: ''
        
    });

    const { isLoggedIn } = useSelector(state => state.auth);
    const { message } = useSelector(state => state.message);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [modal, showModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('Login error!');
    const [loader, setLoader] = useState(false);

   

    const onSignIn = (e) => {
        e.preventDefault();
        
        if(user.username === ''){
            setFieldstatusText({
                ...fieldstatustext,
                username: '* required!'
            });
            return
        } 

        if(user.password === '' || user.password.length < 7){
            setFieldstatusText({
                ...fieldstatustext,
                password: '* Enter valid password!'
            });
            return;
        } else {
            setFieldstatusText({
                ...fieldstatustext,
                password: ''
            });
        }

        setLoader(true);
        dispatch(login(user.username, user.password))
        .then(() => {
            setLoader(false);
            navigate("/dashboard");
            window.location.reload();
        })
        .catch(() => {
            alert("User not found!");
        });

    }

    // if (isLoggedIn) {
    //     return <Redirect to="/dashboard" />;
    // }



    const handleDataChange = (e) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value
        })

        if(e.target.value !== ''){
            setFieldstatusText({
                ...fieldstatustext,
                [e.target.name]: ''
            });
        }
        
    }

    const handleModalClose = (e) => {
        showModal(false);
    }

    return (
        <LoginContainer>
            <LoginInTitle>SIGN IN</LoginInTitle>

            <FieldContainer>
                <FieldName>Username</FieldName>
                <TextInput 
                type="text" 
                name="username" 
                value={user.username}
                onChange={handleDataChange}/>
                <InputStatusText>{fieldstatustext['username']}</InputStatusText>
            </FieldContainer>
            <FieldContainer>
                <FieldName>Password</FieldName>
                <TextInput
                type="password" 
                minLength="7" 
                name="password" 
                value={user.password}
                onChange={handleDataChange}/>
                <InputStatusText>{fieldstatustext['password']}</InputStatusText>
            </FieldContainer>
           

            <ButtonContainer>
                {/* <BtnLogin to="/admin/dashboard">SIGN IN</BtnLogin> */}

                <BtnLogin onClick={onSignIn}>SIGN IN</BtnLogin>
                {/* <BtnForgot>Forgot password?</BtnForgot> */}
            </ButtonContainer>

            {/* <ModalError open={modal} message={modalMessage} 
            onClose= {handleModalClose} /> */}
            {/* <Loader loader={loader}/> */}
        </LoginContainer>
    )
}

export default Login
