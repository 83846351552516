import http from '../http-common';
import authHeader from "./auth-header";

class ProductsService {


     // delete uploaded images from storage
     deleteUploadedImages(images){
        const files = { files: images}
        const data =  JSON.stringify(files);

        return http.put('/products/delete-images', data, { headers: authHeader(), withCredentials: true });
    }

}

export default new ProductsService();