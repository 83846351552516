import { Tooltip } from "@mui/material";
import { useState } from "react";
import {
  MdDelete,
  MdEdit,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Row } from "../../globalStyle";
import demoClassService from "../../services/demoClass.service";
import testimonialService from "../../services/testimonial.service";
import ModalConfirm from "../ModalConfirm";
import YoutubeEmbed from "../YoutubeEmbed";
import {
  ActionButton,
  ActionButtons,
  BtnExpand,
  BtnKnowMore,
  CoverContainer,
  DataContainer,
  SCat,
  SDesc,
  ServiceItemContainer,
  SIcon,
  STitle,
} from "./MyDemoElements";

const MyDemoItem = (props) => {
  const { data, edit, onDeleteItem } = props;

  const navigate = useNavigate();

  const [confirmModal, showConfirmModal] = useState(false);
  const [modalData, setModalData] = useState({
    message: "",
    title: "",
  });

  const handleConfirmModalClose = (e) => {
    if (e) {
      onDelete();
    }
    showConfirmModal(false);
  };

  const [expand, setExpand] = useState(false);

  const onDelete = () => {
    demoClassService
      .deleteDemoClasses(data._id)
      .then((res) => {
        
        onDeleteItem(data._id);
        showConfirmModal(false);
      })
      .catch((err) => console.log(err));
  };

  return (
    <ServiceItemContainer>
      {edit && (
        <ActionButtons>
          <Tooltip title="edit">
            <ActionButton
              bg="#757575"
              aria-label=""
              component="span"
              size="small"
              onClick={() => {
                navigate(
                  `/dashboard/demo/edit-demo-classes/${data._id}`
                );
              }}
            >
              <MdEdit />
            </ActionButton>
          </Tooltip>
          <Tooltip title="delete">
            <ActionButton
              bg="#B71C1C"
              aria-label=""
              component="span"
              size="small"
              onClick={() => {
                // setModalCover(confirmDialogCover)
                setModalData({
                  message: "Are you sure want to delete ?",
                  title: "Delete",
                });
                showConfirmModal(true);
              }}
            >
              <MdDelete />
            </ActionButton>
          </Tooltip>
        </ActionButtons>
      )}
      <YoutubeEmbed link={data.link}/>
      <DataContainer>
      <STitle>{data.title}</STitle>
        <SDesc>{data.description}</SDesc>
        <Row style={{ gap: "1rem", alignSelf:"center"}}>
          {data.course?.title && <SCat>{data.course?.title}</SCat>}
          {data.category?.name && <SCat>{data.category?.name}</SCat>}
        </Row>
      </DataContainer>
      
      <ModalConfirm
        open={confirmModal}
        title={modalData.title}
        message={modalData.message}
        ok="Yes"
        cancel="No"
        onClose={handleConfirmModalClose}
      />
    </ServiceItemContainer>
  );
};

export default MyDemoItem;
