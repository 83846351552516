import DashContentTitle from "../../components/DashContentTitles";
import { useDispatch, useSelector } from "react-redux";
import {
  BtnCancel,
  BtnSubmit,
  BtnUpload,
  ButtonContainer,
  CategoriesInput,
  CategoriesOption,
  CurrencyType,
  EmbedContainer,
  FormAreaContainer,
  FormContainer,
  FormItem,
  FormTitle,
  ImagePickerContainer,
  InputText,
  PickedImage,
  PickerIcon,
  PickerText,
  PreviewContainer,
  PriceInputContainer,
} from "./AddNewCourseElements";
import icPicker from "../../assets/icons/ic_image_picker.svg";
import { useEffect, useRef, useState } from "react";
import ProductsService from "../../services/products-service";
import UploadImagesService from "../../services/upload-files-service";
import { Navigate, Redirect, useNavigate, useParams } from "react-router-dom";
import ModalResult from "../../components/ModalResult";
import { ADD_COURSE, EDIT_COURSE } from "../../actions/types";
import categoryService from "../../services/category.service";
import SelectInput from "../../components/SelectInput/SelectInput";
import TextinputHorizontal from "../../components/TextInputHorizontal/TextinputHorizontal";
import TextareaInput from "../../components/TextareaInput/Textareainput";
import courseService from "../../services/course.service";
import MultiTextInput from "../../components/MultiTextInput/MultiTextInput";
import YoutubeEmbed from "../../components/YoutubeEmbed";
import MultiFieldInput from "../../components/MultiFieldInputHorizontal/MultiFieldInput";

const AddNewCourse = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id, category } = useParams();

  const imageInput = useRef(null);

  const [modal, showModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const [data, setData] = useState({
    photo: "",
    title: "",
    description: "",
    video: "",
    category: "",
    call: "",
    whatsapp: "",
    syllabus: [],
    pattern: [],
    eligibility: [],
  });

  const [fieldstatustext, setFieldstatusText] = useState({
    photo: {
      text: "",
      color: "",
    },
    title: "",
    description: "",
    video: "",
    category: "",
    syllabus: "",
    call: "",
    whatsapp: "",
    pattern: "",
    eligibility: "",
  });

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  const [photo, setPhoto] = useState();
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [uploadImageStatus, setUploadImageStatus] = useState(false);

  useEffect(() => {
    if (id) {
      dispatch({
        type: EDIT_COURSE,
      });
      getDetails();
    } else {
      dispatch({
        type: ADD_COURSE,
      });
    }

    getCategories();
  }, []);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  const getDetails = () => {
    courseService
      .getCourseById(id)
      .then((res) => {
        setData({
          photo: res.data.photo,
          title: res.data.title,
          description: res.data.description,
          video: res.data.video,
          category: res.data.category._id,
          syllabus: res.data.syllabus,
          pattern: res.data.pattern,
          eligibility: res.data.eligibility,
          call: res.data.call,
          whatsapp: res.data.whatsapp,
        });
        setPhoto(res.data.photo);
      })
      .catch(() => {});
  };

  const getCategories = () => {
    categoryService.getAllCourseCategories().then((res) => {
      setCategories(
        res.data.map((cat) => ({ value: cat._id, title: cat.name }))
      );
    });
  };

  const onChangePhoto = (e) => {
    if (e.target.files && e.target.files[0]) {
      setPhoto(URL.createObjectURL(e.target.files[0]));
      setSelectedPhoto(e.target.files[0]);
      setData({
        ...data,
        photo: "",
      });
    }
  };

  const handleInputChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    setData({
      ...data,
      [name]: value,
    });

    setFieldstatusText({
      ...fieldstatustext,
      [name]: "",
    });

  
  };

  const onHandleTitleChange = (e) => {
    
    setData({
      ...data,
      [e.name]: {...data[e.name],title: e.value},
    });
  
  };


  const onHandleUpload = (e) => {
    
    setData({
      ...data,
      [e.name]: {...data[e.name], photo: e.value},
    });
  
  };

  const handleMultiInputAdd = (name, item) => {
    setData((prev) => ({
      ...data,
      [name]: [...prev[name], item],
    }));
  };

  const handleMultiInputUpdate = (name, item, index) => {
    
    setData((prev) => ({
      ...data,
      [name]: [...prev[name].filter((_, i) => i !== index), item],
    }));
  };

  const onDeleteMultiInputItem = (name, index) => {
    setData((prev) => ({
      ...data,
      [name]: prev[name].filter((_, i) => i !== index),
    }));
  };

  

  const handleModalClose = (e) => {
    showModal(false);
    if (id) {
      navigate(-1);
    }
  };

  const addNewProduct = () => {

    if (validateForm()) {
      const course = {
        photo: data.photo,
        title: data.title,
        description: data.description,
        video: data.video,
        category: data.category,
        syllabus: data.syllabus,
        pattern: data.pattern,
        eligibility: data.eligibility,
        call: data.call,
        whatsapp: data.whatsapp,
      };
      courseService
        .addNewCourse(course)
        .then((res) => {
          setModalMessage("Course added successfully!");
          showModal(true);
          onReset();
        })
        .catch((err) => console.log(err));
    }
  };

  const updateProduct = () => {
    if (validateForm()) {
      courseService
        .updateCourse(id, data)
        .then((res) => {
          setModalMessage("Course details updated successfully!");
          showModal(true);
        })
        .catch((err) => console.log(err));
    }
  };

  const onCancel = () => {
    if (id) {
      navigate(-1);
      return;
    }

    if (data.photo !== "") {
      let nameSplit = data.photo.split("/");
      let key = nameSplit[nameSplit.length - 1];
      UploadImagesService.deleteUploadedImages([key])
        .then((result) => {})
        .catch((err) => console.log(err));
      setData({
        photo: "",
        title: "",
        description: "",
        video: "",
        category: "",
        call: "",
        whatsapp: "",
        syllabus: [],
        pattern: [],
        eligibility: [],
      });
    } else {
      setData({
        photo: "",
        title: "",
        description: "",
        video: "",
        category: "",
        call: "",
        whatsapp: "",
        syllabus: [],
        pattern: [],
        eligibility: [],
      });
    }

    setPhoto(null);
    setSelectedPhoto(null);
  };

  const onReset = () => {
    setData({
      photo: "",
      title: "",
      description: "",
      video: "",
      category: "",
      call: "",
      whatsapp: "",
      syllabus: [],
      pattern: [],
      eligibility: [],
    });
    setFieldstatusText({
      photo: "",
      title: "",
      description: "",
      video: "",
      category: "",
      call: "",
      whatsapp: "",
      syllabus: "",
      pattern: "",
      eligibility: "",
    });
    setPhoto(null);
    setSelectedPhoto(null);
  };

  const uploadImage = () => {
    if (selectedPhoto === null) {
      setFieldstatusText({
        ...fieldstatustext,
        photo: {
          text: "No photos selected!",
          color: "#B71C1C",
        },
      });
      return;
    }

    UploadImagesService.upload(selectedPhoto, (event) => {
      setUploadImageStatus(true);
    })
      .then((res) => {
        setData({
          ...data,
          photo: res.data.Location,
        });
        setFieldstatusText({
          ...fieldstatustext,
          photo: {
            text: "Photos Uploaded Successfully!",
            color: "#2E7D32",
          },
        });
        setUploadImageStatus(false);
      })
      .catch((err) => {
        setFieldstatusText({
          ...fieldstatustext,
          photo: {
            text: "Please try agin. Photos not uploaded!",
            color: "#F57F17",
          },
        });
        setUploadImageStatus(false);
      });
  };

  const validateForm = () => {
    if (data.photo === "") {
      setFieldstatusText({
        ...fieldstatustext,
        photo: {
          text: "No Photo uploaded!",
          color: "#2E7D32",
        },
      });
      alert("No Photo uploaded!");
      return false;
    }

    if (data.title === "") {
      setFieldstatusText({
        ...fieldstatustext,
        title: "* required",
      });
      alert("Title required!");
      return false;
    }

    if (data.call === "") {
      setFieldstatusText({
        ...fieldstatustext,
        call: "* Phone number required!",
      });
      alert("Phone number required!");
      return false;
    } else if (data.call.length !== 10) {
      setFieldstatusText({
        ...fieldstatustext,
        call: "* 10 digits required!",
      });
      return false;
    }

    if (data.whatsapp === "") {
      setFieldstatusText({
        ...fieldstatustext,
        whatsapp: "* Phone number required!",
      });
      alert("Phone number required!");
      return false;
    } else if (data.whatsapp.length !== 10) {
      setFieldstatusText({
        ...fieldstatustext,
        whatsapp: "* 10 digits required!",
      });
      return false;
    }

    if (data.category === "") {
      setFieldstatusText({
        ...fieldstatustext,
        category: "* required",
      });
      alert("Category required!");
      return false;
    }

    return true;
  };

  const syllabusList = data.syllabus.map((item, index) => {
    return (
      <MultiFieldInput
        name="syllabus"
        index={index}
        content={item}
        key={index}
        preview={true}
        onDelete={onDeleteMultiInputItem}
        onUpdate={handleMultiInputUpdate}
      />
    );
  });

  const eligibilityList = data.eligibility.map((item, index) => {
    return (
      <MultiFieldInput
        name="eligibility"
        index={index}
        content={item}
        key={index}
        preview={true}
        onDelete={onDeleteMultiInputItem}
        onUpdate={handleMultiInputUpdate}
      />
    );
  });

  const patternList = data.pattern.map((item, index) => {
    return (
      <MultiFieldInput
        name="pattern"
        index={index}
        content={item}
        key={index}
        preview={true}
        onDelete={onDeleteMultiInputItem}
      />
    );
  });

  return (
    <FormAreaContainer>
      <DashContentTitle />
      <FormContainer>
        <FormItem>
          <FormTitle>Cover Image :</FormTitle>

          <ImagePickerContainer onClick={() => imageInput.current.click()}>
            {photo ? (
              <>
                <PickedImage src={photo} />
                <PickerText
                  style={{
                    background: "rgba(0,0,0,0.3)",
                    padding: ".3rem .6rem",
                    borderRadius: ".3rem",
                  }}
                >
                  Change Image
                </PickerText>
              </>
            ) : (
              <>
                <PickerIcon src={icPicker} />
                <PickerText>Upload Image</PickerText>
              </>
            )}
          </ImagePickerContainer>
          {data.photo === "" && photo && (
            <BtnUpload onClick={uploadImage}>Upload</BtnUpload>
          )}

          <input
            ref={imageInput}
            type="file"
            accept="image/png, image/jpeg"
            style={{ visibility: "hidden" }}
            onChange={(e) => onChangePhoto(e)}
          />
        </FormItem>
        <TextinputHorizontal
          type="text"
          textTransform="capitalize"
          title="title"
          error={fieldstatustext["title"]}
          placeholder="Course Title"
          name="title"
          maxLength={200}
          value={data.title}
          onChange={(e) => handleInputChange(e)}
        />
        <TextareaInput
          type="text"
          title="description"
          error={fieldstatustext["description"]}
          placeholder="Description"
          name="description"
          rows="4"
          value={data.description}
          onChange={(e) => handleInputChange(e)}
        />
        <EmbedContainer>
          <YoutubeEmbed link={data.video ? data.video : ""} />
        </EmbedContainer>

        <TextinputHorizontal
          type="text"
          textTransform="capitalize"
          title="Video link"
          error={fieldstatustext["video"]}
          placeholder="Paste url"
          name="video"
          maxLength={600}
          value={data.video}
          onChange={(e) => handleInputChange(e)}
        />

        <TextinputHorizontal
          type="tel"
          title="contact number"
          error={fieldstatustext["call"]}
          placeholder="Contact number"
          name="call"
          maxLength={10}
          value={data.call}
          onChange={(e) => handleInputChange(e)}
        />

        <TextinputHorizontal
          type="tel"
          title="whatsapp number"
          error={fieldstatustext["whatsapp"]}
          placeholder="Whatsapp contact number"
          name="whatsapp"
          maxLength={10}
          value={data.whatsapp}
          onChange={(e) => handleInputChange(e)}
        />

        <SelectInput
          title="category"
          error={fieldstatustext["category"]}
          name="category"
          placeholder="Choose category"
          options={categories}
          value={data.category}
          onChange={(e) => handleInputChange(e)}
        />

        <MultiFieldInput
          preview={false}
          title="Syllabus"
          name="syllabus"
          onAdd={(k, v) => handleMultiInputAdd(k, v)}
        />
        <PreviewContainer>{data.syllabus && syllabusList}</PreviewContainer>

        <MultiFieldInput
          preview={false}
          title="Eligibility"
          name="eligibility"
          onAdd={(k, v) => handleMultiInputAdd(k, v)}
        />
        <PreviewContainer>{data.eligibility && eligibilityList}</PreviewContainer>

        <MultiFieldInput
          preview={false}
          title="Pattern"
          name="pattern"
          onAdd={(k, v) => handleMultiInputAdd(k, v)}
        />
        <PreviewContainer>{data.pattern && patternList}</PreviewContainer>

{/* <MultiFieldInput
        type="syllabus"
        index={0}
        content={null}
        key={0}
        preview={true}
        title="Syllabus"
        // onDelete={onDeleteMultiInputItem}
        onUpload={onHandleUpload}
        onChangeTitle={onHandleTitleChange}
      /> */}

        {/* <MultiTextInput
          preview={false}
          title="Eligibility"
          name="eligibility"
          onAdd={(k, v) => handleMultiInputAdd(k, v)}
        />
        <PreviewContainer>
          {data.eligibility && eligibilityList}
        </PreviewContainer>

        <MultiTextInput
          preview={false}
          title="Pattern"
          name="pattern"
          onAdd={(k, v) => handleMultiInputAdd(k, v)}
        />
        <PreviewContainer>{data.pattern && patternList}</PreviewContainer> */}

        <FormItem>
          <ButtonContainer>
            <BtnCancel onClick={onCancel}>Cancel</BtnCancel>
            {id ? (
              <BtnSubmit onClick={updateProduct}>Update</BtnSubmit>
            ) : (
              <BtnSubmit onClick={addNewProduct}>Submit</BtnSubmit>
            )}
          </ButtonContainer>
        </FormItem>
      </FormContainer>
      <ModalResult
        open={modal}
        message={modalMessage}
        onClose={handleModalClose}
      />
    </FormAreaContainer>
  );
};

export default AddNewCourse;
