import React from "react";
import {
  BtnCall,
  CallContainer,
  ContactContainer,
  Container,
  ContentContainer,
  CourseDescription,
  CourseIcon,
  CourseTitle,
  DetailsContainer,
  EmbedContainer,
  Linkpath,
  TitleContainer,
  WhatsappContainer,
} from "./CourseHeroSectionElements";
import logoWhatsapp from "../../assets/logos/logo_whatsapp.svg";
import { WhatsappIcon } from "../../globalStyle";
import { MdPhone } from "react-icons/md";
import YoutubeEmbedVideo from "../YoutubeEmbedVideo";

const CourseHeroSection = (props) => {
  const { title, description, photo, category, video, call, whatsapp } = props;
  const uri = encodeURI(
    "https://wa.me/+91" +
      whatsapp +
      "?text=I'm inquiring about " +
      title +
      " course"
  );
  return (
    <Container>
      <ContentContainer>
        <Linkpath>
          Home / Courses / {category} / {title}
        </Linkpath>
        <DetailsContainer>
          <CourseIcon src={photo} />
          <TitleContainer>
            <CourseTitle>{title}</CourseTitle>
            <CourseDescription>{description}</CourseDescription>
            <ContactContainer>
              <CallContainer href={"tel:+91" + call}>
                <MdPhone />
                <BtnCall>Call Now</BtnCall>
              </CallContainer>
              <WhatsappContainer href={uri}>
                <WhatsappIcon src={logoWhatsapp} />
              </WhatsappContainer>
            </ContactContainer>
          </TitleContainer>
          

          
          {video && (
            <div>
            <EmbedContainer>
              <YoutubeEmbedVideo link={video} />
            </EmbedContainer>
            </div>
          )}
        </DetailsContainer>
      </ContentContainer>
    </Container>
  );
};

export default CourseHeroSection;
