import React from "react";
import {
  ContactContainer,
  Container,
  ContentContainer,
  CourseDescription,
  CourseIcon,
  CourseLink,
  CourseTitle,
  DetailsContainer,
  DetailsItem,
  Linkpath,
  SpecialDetailsContainer,
  TitleContainer,
} from "./NewsHeroSectionSectionElements";
import { format } from "date-fns";

const NewsHeroSection = (props) => {
  const { title, date, vacancies, photo, category, course } = props;
  return (
    <Container>
      <ContentContainer>
        <Linkpath>
          Home / News / {category}
        </Linkpath>
        <DetailsContainer>
          <CourseIcon src={photo} />
          <TitleContainer>
            <CourseTitle>{title}</CourseTitle>
            
          </TitleContainer>
        </DetailsContainer>
        {category === "Job Notification" ? (
              <SpecialDetailsContainer>
              <DetailsItem>{vacancies} Vacancies</DetailsItem>
              <DetailsItem>Last date : {format(new Date(date), "dd MMMM yyy")}</DetailsItem>
              </SpecialDetailsContainer>
            ):(
              <SpecialDetailsContainer>
               <CourseLink to={`/courses/course/${course}`}>View Course Details</CourseLink>
              <DetailsItem>Starting from {format(new Date(date), "do MMMM yyy")}</DetailsItem>
              </SpecialDetailsContainer>
            )}
      </ContentContainer>
    </Container>
  );
};

export default NewsHeroSection;
