import {
    BtnKnowMore,
    DataContainer,
  NewTag,
  SDesc,
  ServiceItemContainer,
  SIcon,
  STitle,
} from "./NewsItemSmallElements";

const NewsItemSmall = (props) => {
  const { data } = props;
  return (
    <ServiceItemContainer to={`/news/news/${data._id}`}>
      <SIcon src={data.photo} />
      <STitle>{data.title}</STitle>
      {data.category.name === "New Batch" && (
        <NewTag>New Batch</NewTag>
      )}
      
    </ServiceItemContainer>
  );
};

export default NewsItemSmall;
