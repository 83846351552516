import React, { useState } from "react";
import {
  AddressContainer,
  AddressData,
  AddressItem,
  BtnSubmit,
  ContactContents,
  ContactSectionContainer,
  DetailsContainer,
  FormContainer,
  Map,
  MapContainer,
  TitleContainer,
  TitlePartOne,
  TitlePartTwo,
} from "./ContactSectionElements";
import map from "../../assets/images/map.svg";
import { FaPhoneAlt, FaWhatsapp } from "react-icons/fa";
import { IoLocationSharp, IoMail } from "react-icons/io5";
import { Column } from "../../globalStyle";
import TextinputHorizontal from "../TextInputHorizontal/TextinputHorizontal";
import TextInputBox from "../TextInputBox/TextInputBox";
import SelectInputBox from "../SelectInputBox/SelectInputBox";
import TextareaInputBox from "../TextareaInputBox/TextareaInputBox";
import enquiryService from "../../services/enquiry.service";
import ModalResult from "../ModalResult";
const ContactSection = () => {
  const [data, setData] = useState({
    name: "",
    place: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const [fieldstatustext, setFieldstatusText] = useState({
    name: "",
    place: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const [subjects, setSubjects] = useState([
    {
      value: "course",
      title: "course",
    },
    {
      value: "publications",
      title: "publications",
    },
    {
      value: "others",
      title: "others",
    },
  ]);

  const [modal, showModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const handleModalClose = (e) => {
    showModal(false);
  };

  const handleInputChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    setData({
      ...data,
      [name]: value,
    });

    setFieldstatusText({
      ...fieldstatustext,
      [name]: "",
    });
  };

  const sendQuery = () => {
    if (validateForm()) {
      
      enquiryService
        .submitNewEnquiry(data)
        .then((res) => {
          setModalMessage(
            "Thank you, your enquiry has been submitted successfully!"
          );
          showModal(true);
          onReset();
        })
        .catch((err) => console.log(err));
    }
  };

  const validateForm = () => {
    if (data.name === "") {
      setFieldstatusText({
        ...fieldstatustext,
        name: "* required",
      });
      alert("name required!");
      return false;
    }

    if(data.email !== "")
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data.email)) {
        setFieldstatusText({
          ...fieldstatustext,
          email: "* email ID not valid!",
        });
        return false;
      }

    if (data.phone === "") {
      setFieldstatusText({
        ...fieldstatustext,
        phone: "* Phone number required!",
      });
      alert("Phone number required!");
      return false;
    } else if (data.phone.length !== 10) {
      setFieldstatusText({
        ...fieldstatustext,
        phone: "* 10 digits required!",
      });
      return false;
    }

    

    if (data.place === "") {
      setFieldstatusText({
        ...fieldstatustext,
        place: "* required",
      });
      alert("place required!");
      return false;
    }

    if (data.subject === "") {
      setFieldstatusText({
        ...fieldstatustext,
        subject: "* required",
      });
      alert("subject required!");
      return false;
    }

    if (data.message === "") {
      setFieldstatusText({
        ...fieldstatustext,
        message: "* required",
      });
      alert("message required!");
      return false;
    }

    return true;
  };

  const onReset = () => {
    setData({
      name: "",
      place: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
    });
    setFieldstatusText({
      name: "",
      place: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
    });
  };

  return (
    <ContactSectionContainer>
      <TitleContainer>
        <TitlePartTwo>GET IN TOUCH</TitlePartTwo>
      </TitleContainer>
      <ContactContents>
        <DetailsContainer>
          <MapContainer
            href="https://goo.gl/maps/TaVsBxtqkqq8yHfGA"
            target="_blank"
          >
            <Map src={map} />
          </MapContainer>

          <AddressContainer>
            <Column>
              <AddressItem>
                <FaPhoneAlt />
                <AddressData>+91 894 33 66 588</AddressData>
              </AddressItem>
              <AddressItem>
                <IoLocationSharp />
                <AddressData>
                  City Gallery
                  <br />
                  YMCA, Near Paragon Hotel
                  <br />
                  Kannur road Kozhikode
                </AddressData>
              </AddressItem>
            </Column>
            <Column>
              <AddressItem>
                <FaWhatsapp />
                <AddressData>+91 949 77 13 838</AddressData>
              </AddressItem>
              <AddressItem>
                <IoMail />
                <AddressData>cubitcalicut@gmail.com</AddressData>
              </AddressItem>
            </Column>
          </AddressContainer>
        </DetailsContainer>
        <FormContainer>
          <TextInputBox
            name="name"
            type="text"
            textTransform="capitalize"
            placeholder="Full Name"
            maxLength={60}
            value={data.name}
            error={fieldstatustext["name"]}
            onChange={(e) => handleInputChange(e)}
          />
          <TextInputBox
            type="text"
            name="email"
            placeholder="example@abc.com"
            value={data.email}
            error={fieldstatustext["email"]}
            onChange={(e) => handleInputChange(e)}
          />
          <TextInputBox
            type="tel"
            name="phone"
            error={fieldstatustext["phone"]}
            placeholder="phone number"
            maxLength={10}
            value={data.phone}
            onChange={(e) => handleInputChange(e)}
          />
          <TextInputBox
            name="place"
            type="text"
            textTransform="capitalize"
            placeholder="Place"
            maxLength={60}
            value={data.place}
            error={fieldstatustext["place"]}
            onChange={(e) => handleInputChange(e)}
          />
          <SelectInputBox
            title="subject"
            error={fieldstatustext["subject"]}
            name="subject"
            placeholder="Choose subject"
            options={subjects}
            value={data.subject}
            onChange={(e) => handleInputChange(e)}
          />
          <TextareaInputBox
            type="text"
            error={fieldstatustext["message"]}
            placeholder="message..."
            name="message"
            rows="4"
            value={data.message}
            onChange={(e) => handleInputChange(e)}
          />
          <BtnSubmit onClick={sendQuery}>Submit</BtnSubmit>
        </FormContainer>
      </ContactContents>
      <ModalResult
        open={modal}
        message={modalMessage}
        onClose={handleModalClose}
      />
    </ContactSectionContainer>
  );
};

export default ContactSection;
