import { useEffect, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { Navigate} from "react-router-dom"
import DashContentTitle from "../../components/DashContentTitles"
import { AllProductsContainer, Container, PhonesList, ProductsContainer, ProductsSection, SubNavBar} from "./MyMaterialsElements"
import noDataCover from '../../assets/images/no_data.svg'
import Loader from '../../components/Loader';
import courseService from "../../services/course.service"
import CourseItem from "../../components/CourseItem"
import { ErrorText, NodataCover, NoDataMessageContainer } from "../../globalStyle"
import DashActionbar from "../../components/DashActionBar"
import { MANAGE_STUDY_MATERIALS, VIEW_ACHIEVERS } from "../../actions/types"
import publicationService from "../../services/publication.service"
import PublicationItem from "../../components/PublicationItem"
import achieverService from "../../services/achiever.service"
import MyAchieversItem from "../../components/MyAchieversItem"
import MyMaterialsItem from "../../components/MyMaterialsItem"
import materialsService from "../../services/materials.service"

const MyMaterials = (props) => {

    const { user: currentUser } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const [materials, setMaterials] = useState([]);
    const [loader, setLoader] = useState(false);

    useEffect(() => {

        dispatch({
            type: MANAGE_STUDY_MATERIALS,
          });

        getMaterials();
      }, []
    );


    if (!currentUser) {
        return <Navigate to="/login" />;
    }


    const getMaterials = () => {
        setMaterials([]);
        setLoader(true);
        materialsService.getAllMaterials()
        .then((res) => {
            
            setMaterials(res.data);
            setLoader(false);
        }).catch(() => {
                setLoader(false);
        });
        
    }




    const searchProducts = (tag) => {
        if(tag !== ''){
            courseService.getAllCourses()
            .then((res) => {
                setMaterials(res.data.products);
            });
        } else {
            
            getMaterials();
        }
        
    }

    const handleDeleteProduct = (e) => {
        const  newCourses = materials.filter( el => el._id !== e);
        setMaterials(newCourses);
    }



    const materialsList = materials.map((item, index) => {
        return <MyMaterialsItem
        key={item._id}
        data={item}
        edit={true}
        onDeleteItem = {handleDeleteProduct}
        />
    })
    
    return (
        <Container>
            {/* <DashActionbar onChange= {(tag)=> searchProducts(tag)}/> */}
            <AllProductsContainer>
                <DashContentTitle title= "all products"/>
                <ProductsSection>
                    <ProductsContainer>
                        <PhonesList>
                            {materials && materialsList}
                            {(materials.length === 0 && loader === false) ? (
                            <NoDataMessageContainer>
                                <NodataCover src={noDataCover}/>
                                <ErrorText>No achievers found!</ErrorText>
                            </NoDataMessageContainer>
                            ):(null)}
                        </PhonesList>
                        
                    </ProductsContainer>
                </ProductsSection>
            <Loader loader={loader}/>
            </AllProductsContainer>
        </Container>
        
    )
}

export default MyMaterials;
