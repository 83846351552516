import { useEffect, useState } from "react"
import { useSelector } from 'react-redux'
import { Navigate} from "react-router-dom"
import DashContentTitle from "../../components/DashContentTitles"
import { AllProductsContainer, Container, PhonesList, ProductsContainer, ProductsSection, SubNavBar} from "./CategoriesElements"
import noDataCover from '../../assets/images/no_data.svg'
import Loader from '../../components/Loader';
import courseService from "../../services/course.service"
import CourseItem from "../../components/CourseItem"
import { ErrorText, NodataCover, NoDataMessageContainer } from "../../globalStyle"
import DashActionbar from "../../components/DashActionBar"
import PageTitle from "../../components/PageTitles"
import ServiceItem from "../../components/ServiceItem"
import categoryService from "../../services/category.service"
import PageTitleWithBackground from "../../components/PageTitlesWithBackground"
import logoBank from "../../assets/logos/bank_logo.png";
import logoPSC from "../../assets/logos/psc_logo.png";
import logoRRB from "../../assets/logos/rrb_logo.png";
import logoSSC from "../../assets/logos/ssc_logo.png";
import logoCET from "../../assets/logos/cet_logo.svg";

const Categories = (props) => {

    
    const [courses, setCourses] = useState([]);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        getCategories();
      }, []
    );




    // const getCourses = () => {
    //     setCourses([]);
    //     setLoader(true);
    //     courseService.getAllCourses()
    //     .then((res) => {
            
    //         setCourses(res.data);
    //         setLoader(false);
    //     }).catch(() => {
    //             setLoader(false);
    //     });
        
    // }

    const getCategories = () => {
        setCourses([]);
        setLoader(true);
        categoryService.getAllCourseCategories().then((res) => {
            setCourses(res.data);
            setLoader(false);
        }).catch(() => {
                setLoader(false);
        });
        
      };

      const setLogo = (name) => {
        switch (name) {
          case "bank":
            return logoBank;
          case "ssc":
            return logoSSC;
          case "psc":
            return logoPSC;
          case "rrb":
            return logoRRB;
          case "cet":
            return logoCET;
    
          default:
            return "";
        }
      };

    const coursesList = courses.map((item, index) => {
        return <ServiceItem
        key={item._id}
        data={item}
        logo={setLogo(item.name.toLowerCase())}
        />
    })
    
    return (
        <Container>
            {/* <DashActionbar onChange= {(tag)=> searchProducts(tag)}/> */}
            <AllProductsContainer>
                <PageTitleWithBackground title= "Courses" subTitle="Our Courses"/>
                <ProductsSection>
                    <ProductsContainer>
                        <PhonesList>
                            {courses && coursesList}
                            {(courses.length === 0 && loader === false) ? (
                            <NoDataMessageContainer>
                                <NodataCover src={noDataCover}/>
                                <ErrorText>No categories found!</ErrorText>
                            </NoDataMessageContainer>
                            ):(null)}
                        </PhonesList>
                        
                    </ProductsContainer>
                </ProductsSection>
            <Loader loader={loader}/>
            </AllProductsContainer>
        </Container>
        
    )
}

export default Categories
