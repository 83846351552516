import { Button, IconButton } from "@mui/material";
import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";

export const ServiceItemContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  background: #fff;
  border: 1px solid #ededed;
  border-radius: 12px;
  padding: 1rem;
  text-decoration: none;
  position: relative;

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 1rem;
    gap: 0.5rem;
    border-radius: 16px;
  }
`;

export const CoverContainer = styled.div`
  background: #f5f5f5;
  position: relative;
  width: 100%;
  padding-top: 56.25%;
`;


export const STitle = styled.p`

  color: #212121;
  font-weight: 500;
  margin-top: 0;
  @media screen and (max-width: 768px) {
    font-size: 10px;
  }
`;
export const SDesc = styled.p`
  color: #424242;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 24px;
  margin: 0;

`;
export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BtnKnowMore = styled(Button)`
  background-color: #01579b !important;
  text-transform: capitalize !important;
`;

export const ActionButtons = styled.div`
 flex: 1;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const ActionButton = styled(IconButton)`
  color: ${(props) => props.bg} !important;
`;

export const BtnExpand = styled(Button)`
  align-self: flex-end;
`;
