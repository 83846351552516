import React, { useEffect, useState } from "react";
import CourseHeroSection from "../../components/CourseHeroSection";
import { Container } from "../../globalStyle";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { TabList, TabPanel, TabContext } from "@mui/lab";
import {
  AchieverConrainer,
  AchieverContainer,
  CenterPanel,
  ContentContainer,
  ListContainer,
  RightPanel,
  RightPanelTitle,
  TabContainer,
  TestimonialContainer,
} from "./CouseElements";
import { useParams } from "react-router-dom";
import courseService from "../../services/course.service";
import AchieverItem from "../../components/AchieverItem";
import achieverService from "../../services/achiever.service";
import CarouselAuto from "../../components/CarouselAuto";
import CarouselAchievers from "../../components/CarouselAchievers";
import YoutubeEmbed from "../../components/YoutubeEmbed";
import SubjectItem from "../../components/SubjectItem";
import testimonialService from "../../services/testimonial.service";
import demoClassService from "../../services/demoClass.service";
import { Tabs } from "@mui/material";
import DemoYoutubeEmbed from "../../components/DemoYoutubeEmbed";
import materialsService from "../../services/materials.service";
import MaterialsItem from "../../components/MaterialsItem";

const Course = () => {
  const { id } = useParams();

  const [value, setValue] = useState("1");
  const [course, setCourse] = useState({
    _id: "",
    photo: "",
    title: "",
    description: "",
    video:"",
    call: "",
    whatsapp: "",
    category: {
      name: "",
    },
    eligibility: [],
    pattern: [],
    syllabus: [],
  });

  const [achievers, setAchievers] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [demoClasses, setDemoClasses] = useState([]);
  const [materials, setMaterials] = useState([]);

  useEffect(() => {
    getCourseDetails();
    
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getCourseDetails = () => {
    courseService
      .getCourseById(id)
      .then((res) => {
        
        setCourse(res.data);
        getAchievers(res.data.category._id);
    getDemoClasses();
    getStudyMaterials();
    getAllTestimonials();
      })
      .catch(() => {});
  };

  const getAchievers = (catID) => {
    achieverService
      .getAllAchieversByCategory(catID)
      .then((res) => {
        
        setAchievers(res.data);
      })
      .catch(() => {});
  };

  const getDemoClasses = () => {
    demoClassService
      .getAllDemoByCategory(id)
      .then((res) => {
        
        setDemoClasses(res.data);
      })
      .catch(() => {});
  };

  const getStudyMaterials = () => {
    materialsService
      .getAllMaterialsByCourse(id)
      .then((res) => {
        
        setMaterials(res.data);
      })
      .catch(() => {});
  };

  const getAllTestimonials = () => {
    testimonialService
      .getAllTestimonials()
      .then((res) => {
        
        setTestimonials(res.data);
      })
      .catch(() => {});
  };

  const achieverList = achievers.map((item, index) => {
    return <AchieverItem key={item._id} data={item} />;
  });

  const testimonialList = testimonials.map((item, index) => {
    return (
      <YoutubeEmbed
        link={item.link}
        title={item.title}
        description={item.description}
      />
    );
  });

  const DemoClassList = demoClasses.map((item, index) => {
    return (
      <DemoYoutubeEmbed
        link={item.link}
        title={item.title}
        description={item.description}
      />
    );
  });

  const materialsList = materials.map((item, index) => {
    return <MaterialsItem key={item._id} data={item} />;
  });

  const syllabusList = course.syllabus.map((item, index) => {
    return <SubjectItem key={item._id} data={item} />;
  });

  const patternList = course.pattern.map((item, index) => {
    return <SubjectItem key={item._id} data={item} />;
  });

  const eligibilityList = course.eligibility.map((item, index) => {
    return <SubjectItem key={item._id} data={item} />;
  });

  return (
    <Container>
      <CourseHeroSection
        title={course.title}
        description={course.description}
        photo={course.photo}
        category={course.category.name}
        video = {course.video}
        call={course.call}
        whatsapp={course.whatsapp}
      />
      <ContentContainer>
        <CenterPanel>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="Tab"
                variant="scrollable"
                allowScrollButtonsMobile={true}
              >
                <Tab label="Exam Pattern" value="2" />
                <Tab label="Syllabus" value="1" />
                
                <Tab label="Eligibility" value="3" />
                {achievers.length > 0 && <Tab label="Achievers" value="4" />}
                {demoClasses.length > 0 && (
                  <Tab label="Demo Classes" value="5" />
                )}
                {materials.length > 0 && (
                  <Tab label="Study Materials" value="6" />
                )}
              </Tabs>
            </Box>

            {/* Syllbus Tab */}
            <TabPanel value="1">
              <TabContainer>{course.syllabus && syllabusList}</TabContainer>
            </TabPanel>
            <TabPanel value="2">
              <TabContainer>{course.pattern && patternList}</TabContainer>
            </TabPanel>
            <TabPanel value="3">
              <TabContainer>
                {course.eligibility && eligibilityList}
              </TabContainer>
            </TabPanel>
            {achievers.length > 0 && (
              <TabPanel value="4">
                <AchieverContainer>
                  {achievers && <CarouselAchievers items={achievers} />}
                </AchieverContainer>
              </TabPanel>
            )}

            {demoClasses.length > 0 && (
              <TabPanel value="5">
                <AchieverContainer>
                  {demoClasses && DemoClassList}
                </AchieverContainer>
              </TabPanel>
            )}

            {materials.length > 0 && (
              <TabPanel value="6">
                <AchieverContainer>
                  {materials && materialsList}
                </AchieverContainer>
              </TabPanel>
            )}
          </TabContext>
        </CenterPanel>
        <RightPanel>
          <TestimonialContainer>
            <RightPanelTitle>Testimonials</RightPanelTitle>
            <ListContainer>{testimonials && testimonialList}</ListContainer>
          </TestimonialContainer>
        </RightPanel>
      </ContentContainer>
    </Container>
  );
};

export default Course;
