export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const DASHBOARD = "DASHBOARD";

export const ADD_COURSE = "ADD_COURSE";
export const MANAGE_COURSE = "MANAGE_COURSE";
export const EDIT_COURSE = "EDIT_COURSE";

export const ADD_ACHIEVERS = "ADD_ACHIEVERS";
export const VIEW_ACHIEVERS = "VIEW_ACHIEVERS";
export const EDIT_ACHIEVERS = "EDIT_ACHIEVERS";

export const ADD_PUBLICATIONS = "ADD_PUBLICATIONS";
export const MANAGE_PUBLICATIONS = "MANAGE_PUBLICATIONS";
export const EDIT_PUBLICATIONS = "EDIT_PUBLICATIONS";

export const ADD_TESTIMONIALS = "ADD_TESTIMONIALS";
export const VIEW_TESTIMONIALS = "VIEW_TESTIMONIALS";
export const EDIT_TESTIMONIALS = "EDIT_TESTIMONIALS";

export const ADD_DEMO = "ADD_DEMO";
export const VIEW_DEMO = "VIEW_DEMO";
export const EDIT_DEMO = "EDIT_DEMO";

export const ADD_NEWS = "ADD_NEWS";
export const MANAGE_NEWS = "MANAGE_NEWS";
export const EDIT_NEWS = "EDIT_NEWS";

export const ENQUIRIES = "ENQUIRIES";
export const GENERAL = "GENERAL";

export const EDIT_DETAILS = "EDIT_DETAILS";
export const VIEW_ENTRIES = "VIEW_ENTRIES";

export const ADD_STUDY_MATERIALS = "ADD_STUDY_MATERIALS";
export const MANAGE_STUDY_MATERIALS = "MANAGE_STUDY_MATERIALS";
export const EDIT_STUDY_MATERIALS = "EDIT_STUDY_MATERIALS";