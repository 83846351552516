import { Button } from "@mui/material";
import styled from "styled-components";
import {Link as LinkR} from 'react-router-dom'

export const ServiceItemContainer = styled(LinkR)`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    background: #fff;
    border: 1px solid #EDEDED;
    border-radius: 12px;
    padding: 1rem;
    cursor: pointer;
    text-decoration: none;

    @media screen and (max-width: 768px){
       width:120px;
       height: 120px;
       padding: 1rem;
       justify-content: center;
       gap: 0.5rem;
       border-radius: 16px;
    }
`

export const SIcon = styled.img`
    height: 120px;
    width: 120px;
    padding: 12px;
    border-radius: 12px;
    object-fit: contain;
    background: #E1F5FE;
    @media screen and (max-width: 768px){
       width:60px;
       height: 60px;
    }
`
export const STitle = styled.h3`
    color: #01579B;
    margin: 0;
    text-transform: capitalize;
    @media screen and (max-width: 768px){
       font-size: 10px;
       
    }
`
export const SDesc = styled.p`
    color: rgba(15, 15, 15, 0.7);
    font-weight: 300;
    display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;

    @media screen and (max-width: 768px){
       display: none;
    }
`
export const DataContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

export const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    border-radius: 12px;
    background: #fafafa;
    border: 1px solid #f5f5f5;
`

export const SDate = styled.p`
    color: #424242;
    font-weight: 300;
    font-size: 14px;
    @media screen and (max-width: 768px){
       font-size: 10px;
       
    }
`

export const SSubject = styled.p`
    color: #212121;
    margin: 0;
    text-transform: capitalize;
    @media screen and (max-width: 768px){
       font-size: 10px;
       
    }
`