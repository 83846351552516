import styled from "styled-components";
import {NavLink as LinkR} from 'react-router-dom';

export const NavbarContainer = styled.div`
    background: #fff;
    justify-content: center;
    display: flex;
    height: 76px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 10;

`
export const NavbarContentContainer = styled.div`
    width: 1100px;
    color: #FC3535;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding: 1rem;
   
`
export const NavLogo = styled.img`
    height: 43px;
`

export const NavLinksContainer = styled.div`
    @media screen and (max-width: 768px){
        display: none;
    }
`
export const LinksContainer = styled.div`
    display: flex;
`

export const NavLink = styled(LinkR)`
    color: #01579B;
    transition: all 300ms;
    text-decoration: none;
    padding: 10px 20px;
    font-weight: 600;

    &:hover{
        color: #80D8FF;
    }
  
`

export const NavLogoLink = styled(LinkR)`
    
  
`

export const NavButtonsContainer = styled.div`
    font-weight: 600;
    @media screen and (max-width: 768px){
        display: none;
    }
`

export const BtnLogin = styled(LinkR)`
    background: #FC3535;
    border: 0;
    border-radius: 4px;
    padding: 8px 18px;
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    transition: all 400ms;
    &:hover{
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }
`

export const SearchContainer = styled.div`
    padding: 8px;
    margin: 1rem;
    background: #0F0F0F;
    border: 1px solid #FC3535;
    box-sizing: border-box;
    border-radius: 10px;
    position: relative;
    width: 250px;

    @media screen and (max-width: 768px){
        width: 100%;
        margin: 0;
        position: relative;
        border:0;
        display: flex;
        align-items: center;
        justify-content: end;
        gap: .3rem;
    }
    
`
export const SearchInput = styled.input`
    border:0;
    outline:none;
    background: #0F0F0F;
    color: #fff;

    &::placeholder{
        color: #FC3535;
    }

    
    @media screen and (max-width: 768px){
        width: ${props => props.width};
        /* opacity: ${props => props.width === "0" ? 0 : 1}; */
        padding: ${props => props.width === "0" ? 0 : "8px"};
        border: ${props => props.width === "0" ? 0 : "1px solid #F2F3FF"};
        border-radius: 8px;
        transition: all 300ms;
            &::placeholder{
            color: #F2F3FF;
        }
    }
    
`
export const SearchIcon = styled.img`
    cursor: pointer;
    color: #fff;
    display: none;

    @media screen and (max-width: 768px){
        display: flex;

    }
`
export const SearchItemsContainer = styled.div`
    position: absolute;
    top: 75px;
    left: 0;
    border-radius: 4px;
    width: 250px;
    max-height: 600px;
    display: flex;
    flex-direction: column;
    row-gap: .2rem;
    overflow-y: scroll;
    z-index: 10;
    background: #f5f5f5;

    @media screen and (max-width: 768px){
        position: fixed;
        width: 100%;
        max-height: calc(100vh - 75px);
    }

    &::-webkit-scrollbar {
    display: none;
    }
`

export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 768px){
        display: flex;
    }
`

export const MobileNavContainer = styled.div`
    
    display: none;

    @media screen and (max-width: 768px) {
    display: block;
    overflow: hidden;
    position: fixed;
    top: 75px;
    right: 0;
    background: #E1F5FE;
    height: ${props => props.height};
    border-radius: 0 0 ${props => props.height === "0" ? "50vw" : "0"} ${props => props.height === "0" ? "100px" : "0"}; 
    width: 100vw;
    z-index: 100;
    transition: all 400ms;
    }

`