import { Button } from "@mui/material";
import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";

export const ServiceItemContainer = styled(LinkR)`
  display: flex;
  width: 320px;
  align-items: center;
  gap: 1rem;
  background: #fff;
  box-shadow: 0px 8px 40px rgba(49, 32, 138, 0.05);
  border-radius: 18px;
  padding: 1rem;
  cursor: pointer;
  text-decoration: none;
  animation: zoom-in 500ms;
  transition: all 300ms;
  position: relative;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 60px;
    border-radius: 12px;
  }

  &:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    transform: scale(1.05);
  }
`;

export const SIcon = styled.img`
  width: 120px;
  height: 120px;
  object-fit: contain;
  @media screen and (max-width: 768px) {
    width: 60px;
    height: 60px;
  }
`;
export const STitle = styled.h2`
  text-transform: uppercase;
  color: #01579b;
  font-weight: 600;
  margin: 0;
  flex: 1;
  text-align: center;
`;
export const SDesc = styled.p`
  color: rgba(15, 15, 15, 0.5);
  font-weight: 300;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  gap: 1rem;
  @media screen and (max-width: 768px) {
    flex-direction: row;
    align-items:center;
  }
`;

export const BtnKnowMore = styled(Button)`
  background-color: #01579b !important;
  text-transform: capitalize !important;
`;

export const BtnView = styled.div`
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  background: #01579b;
  padding: 8px 16px;
  font-size: 12px;
  border-radius: 6px;
  
`;
