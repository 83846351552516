import styled from "styled-components";

export const InputContainer = styled.div`
  display: flex;
  width: 100%;
  @media screen and (max-width: 768px) {
    // width: 300px;
    width: 100%;
    justify-content: center;
  }
`;

export const InputBox = styled.input`
  outline: none;
  border: ${(props) => (props.error === "" ? "none" : "1px solid #E53935")};
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  border-radius: 4px;
  background: #fff;
  padding: 12px 18px;
  font-size: 1rem;
  width: 560px;
  @media screen and (max-width: 768px) {
    width: 300px;
  }
`;

export const InputBoxContainer = styled.div``;

export const InputError = styled.p`
  font-size: 0.9rem;
  margin: 0;
  margin-top: 5px;
  color: #E53935;
  font-weight: 300;
`;
