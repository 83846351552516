import http from "../http-common";
import authHeader from "./auth-header";

class CategoryService{

   
    getAllcategories(){
        return http.get('/category/all');
    }

    getAllCourseCategories(){
        return http.get('/category/course/all');
    }

    getAllNewsTypes(){
        return http.get('/newsType/all');
    }



  
}


export default new CategoryService();