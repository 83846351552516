import { Button } from "@mui/material";
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction:column;
    
`
export const ProductsContainer = styled.div`
    display: flex;
    flex-direction:column;
    
`

export const AllProductsContainer = styled.div`
    
    flex: 1;
    display: flex;
    flex-direction:column;
    gap: 1rem;

`
export const PhonesList =styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    
    gap: 1rem;
    margin-bottom: 2rem;
`
export const ProductsSection = styled.div`
    width: 100%;
    height: 500px;
    overflow-y: scroll;
`
export const SubNavBar = styled.div`
    display: flex;
    margin-top: 1rem;
`

export const BtnLoadMore = styled.div`
    text-decoration: none;
    color: #fff;
    background: #FC3535;
    padding: 16px 24px;
    border-radius: 4px;
    text-transform: capitalize;
    font-weight: 600;
    transition: all 400ms;
    display: flex;
    justify-content: center;
    align-self: center;
    width: 100px;
    cursor: pointer;
    &:hover{
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
`

