import http from "../http-common";
import authHeader from "./auth-header";

class DemoClassService{

    getAllDemoByCategory(id){
        return http.get(`/demoClass/all/byCourseID/${id}`);
    }


    addNewDemo(data){
        return http.post(`/demoClass/add-new-demo`, JSON.stringify(data),{headers: authHeader(), withCredentials: true});

    }

    getAllDemoClasses(){
        return http.get(`/demoClass/all`);
    }

    getDemoClass(id){
        return http.get(`/demoClass/${id}`);
    }

    updateDemoClasses(id, data){
        return http.put(`/demoClass/update/${id}`, JSON.stringify(data),{headers: authHeader(), withCredentials: true});

    }

    deleteDemoClasses(id){
        return http.delete(`/demoClass/delete/${id}`,{headers: authHeader(), withCredentials: true});
    }

    

  
}


export default new DemoClassService();