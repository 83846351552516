import { useEffect, useState } from "react";
import { MdAssignment } from "react-icons/md";
import AchievementsSection from "../../components/AchievementsSection";
import ContactSection from "../../components/ContactSection";
import HeroCaption from "../../components/HeroCaption";
import NewsSection from "../../components/NewsSection";
import PopIcons from "../../components/PopIcons";
import ServicesSection from "../../components/ServicesSection";
import TestimonialSection from "../../components/TestimonialSection";
import YoutubeEmbed from "../../components/YoutubeEmbed";
import generalService from "../../services/general.service";
import {
  CaptionContainer, ContentContainer, EmbedContainer, HeroContainer, HomeContainer, TestLink
} from "./HomeElements";

const Home = () => {
  const [links, setLinks] = useState({
    intro: "",
    test: ""
  });

  useEffect(() => {
    getTestLink();
  }, []);
  const getTestLink = () => {
      generalService.getGeneralLinks()
      .then((res) => {
          setLinks({
            intro: res.data.introLink,
            test: res.data.testLink
          });
      }).catch(() => {

      });
  }

  return (
    <HomeContainer>
      <HeroContainer>
        <ContentContainer>
          <CaptionContainer>
            {/* <NetVector src={netVector}/> */}
            <HeroCaption />
            <EmbedContainer>
            {links.test && <TestLink href={links.test} target="_blank"> <MdAssignment/>Try out Mock Test</TestLink>}
            { links.intro && <YoutubeEmbed link={links.intro}/>}
            </EmbedContainer>
          </CaptionContainer>
        </ContentContainer>
      </HeroContainer>
        <NewsSection />
      <ServicesSection />
      <AchievementsSection />
      <TestimonialSection/>
      <ContactSection />
      <PopIcons call="8943366588" whatsapp="8943366588"/>
    </HomeContainer>
  );
};

export default Home;
