import React from "react";
import Carousel from 'react-material-ui-carousel'
import AchieverItem from "../AchieverItem";

const CarouselAchievers = (props) => {

    const {items} = props;

    const itemsList = items.map((item, index) => {
        return <AchieverItem key={item._id} data={item} edit={true} />;
      });
    

    return (
        <Carousel animation="slide" indicators={false}>
            {
                itemsList
            }
        </Carousel>
    )
};

export default CarouselAchievers;
