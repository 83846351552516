import styled from "styled-components";

export const Container = styled.div`
    padding: 1rem;
    background: #fff;
    border-radius: .5rem;
    border: 1px solid #EDEDED;
    flex: 1;
`

export const MainTitle = styled.h2`
    margin: 0;
    text-transform: capitalize;
`
export const SubTitle = styled.p`
    text-transform: capitalize;
    color: #A6A6A6;
    font-weight: 500;
`
export const SubTitleCotainer = styled.div`
    display: flex;
    gap: .5rem;
`