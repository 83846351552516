import { IcExpand, MenuItemContainer, MenuItemIcon, MenuItemTitle } from "./DashboardMenuItemElements"
import icArrow from '../../assets/icons/ic_expand.svg'

const DashboardSubMenuItem = (props) => {

    const {icon, title, expand, to} = props;
    return (
        
        <MenuItemContainer to={to}>
            {
                icon ? (
                    <MenuItemIcon src={icon}/>
                ) : (
                    null
                )
            }
            <MenuItemTitle>{title}</MenuItemTitle>

            {
                expand ? (
                    <IcExpand src={icArrow}/>
                ) : (
                    null
                )
            }
           
        </MenuItemContainer>
    )
}

export default DashboardSubMenuItem
