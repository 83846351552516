import React from "react"
import { BtnShop } from "../../pages/Home/HomeElements"
import { CaptionContainer,HeroSubTitle,HeroTitle, StrongText, WelcomeText,} from "./HeroCaptionElemenets"

const HeroCaption = () => {
    return (
        <CaptionContainer>
            <HeroTitle><StrongText>Where </StrongText>Dreams</HeroTitle>
            <HeroTitle><StrongText>Meet </StrongText>Success</HeroTitle>
            <HeroSubTitle>We need to bring learning to people instead of people to learning</HeroSubTitle>
            <BtnShop to= '/courses/categories'>View courses</BtnShop>
        </CaptionContainer>
    )
}

export default HeroCaption
