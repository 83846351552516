import React, { useState } from "react";
import {
  AddressContainer,
  AddressData,
  AddressItem,
  BtnSubmit,
  ContactContents,
  ContactSectionContainer,
  DetailsContainer,
  FormContainer,
  Map,
  MapContainer,
  TitleContainer,
  TitlePartOne,
  TitlePartTwo,
} from "./ChirakuContactSectionElements";
import map from "../../assets/images/map.svg";
import { FaPhoneAlt, FaWhatsapp } from "react-icons/fa";
import { IoLocationSharp, IoMail } from "react-icons/io5";
import { Column } from "../../globalStyle";
import TextinputHorizontal from "../TextInputHorizontal/TextinputHorizontal";
import TextInputBox from "../TextInputBox/TextInputBox";
import SelectInputBox from "../SelectInputBox/SelectInputBox";
import TextareaInputBox from "../TextareaInputBox/TextareaInputBox";
import enquiryService from "../../services/enquiry.service";
import ModalResult from "../ModalResult";
import { useEffect } from "react";
import courseService from "../../services/course.service";
import chirakuRegistrationService from "../../services/chirakuRegistration.service";
const ChirakuContactSection = () => {
  const [data, setData] = useState({
    name: "",
    place: "",
    email: "",
    phone: "",
    course: "",
  });

  const [fieldstatustext, setFieldstatusText] = useState({
    name: "",
    place: "",
    email: "",
    phone: "",
    course: "",
  });

  const [courseNames, setCourseNames] = useState([]);


  const [modal, showModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const handleModalClose = (e) => {
    showModal(false);
  };
  

  useEffect(() => {
    getCourseNames();
  
  }, []);


  const getCourseNames = (catID) => {
    courseService.getCoursesNames(catID).then((res) => {
      setCourseNames(
        res.data.map((cat) => ({ value: cat._id, title: cat.title }))
      );
    });
  };

  const handleInputChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    setData({
      ...data,
      [name]: value,
    });
  };




  const sendQuery = () => {
    if (validateForm()) {
      
      chirakuRegistrationService
        .registerNew(data)
        .then((res) => {
          setModalMessage("Your registation completed successfully!");
          showModal(true);
          onReset();
        })
        .catch((err) => console.log(err));
    }
  };

  const validateForm = () => {
    if (data.name === "") {
      setFieldstatusText({
        ...fieldstatustext,
        name: "* required",
      });
      alert("name required!");
      return false;
    }
    if (data.phone === "") {
      setFieldstatusText({
        ...fieldstatustext,
        phone: "* Phone number required!",
      });
      alert("Phone number required!");
      return false;
    }

    if (data.place === "") {
      setFieldstatusText({
        ...fieldstatustext,
        place: "* required",
      });
      alert("place required!");
      return false;
    }

    if (data.course === "") {
      setFieldstatusText({
        ...fieldstatustext,
        course: "* required",
      });
      alert("course required!");
      return false;
    }


    return true;
  };


  const onReset = () => {
    setData({
      name: "",
      place: "",
    email: "",
    phone: "",
    course: "",
    });
    setFieldstatusText({
      name: "",
      place: "",
      email: "",
      phone: "",
      course: "",
    });
  };

  return (
    <ContactSectionContainer>
      
      <ContactContents>
      <TitlePartTwo>Register Now</TitlePartTwo>
        <FormContainer>
          <TextInputBox
            name="name"
            type="text"
            textTransform="capitalize"
            placeholder="Full Name"
            maxLength={60}
            value={data.name}
            error={fieldstatustext["name"]}
            onChange={(e) => handleInputChange(e)}
          />
          <TextInputBox
            type="text"
            name="email"
            placeholder="example@abc.com"
            value={data.email}
            error={fieldstatustext["email"]}
            onChange={(e) => handleInputChange(e)}
          />
          <TextInputBox
            type="tel"
            name="phone"
            error={fieldstatustext["phone"]}
            placeholder="phone number"
            maxLength={10}
            value={data.phone}
            onChange={(e) => handleInputChange(e)}
          />
          <TextInputBox
            name="place"
            type="text"
            textTransform="capitalize"
            placeholder="Place"
            maxLength={60}
            value={data.place}
            error={fieldstatustext["place"]}
            onChange={(e) => handleInputChange(e)}
          />
          <SelectInputBox
            title="course"
            error={fieldstatustext["course"]}
            name="course"
            placeholder="Choose course"
            options={courseNames}
            value={data.course}
            onChange={(e) => handleInputChange(e)}
          />
          <BtnSubmit onClick={sendQuery}>Submit</BtnSubmit>
        </FormContainer>
      </ContactContents>
      <ModalResult
        open={modal}
        message={modalMessage}
        onClose={handleModalClose}
      />
    </ContactSectionContainer>
  );
};

export default ChirakuContactSection;
