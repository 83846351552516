import { useSelector } from "react-redux";
import { Container, MainTitle, SubTitle, SubTitleCotainer } from "./DashContentElements"

const DashContentTitle = () => {
    const { title, subTitle } = useSelector((state) => state.dashboardTitles);
    return (
        <Container>
            <MainTitle>{title}</MainTitle>
            <SubTitleCotainer>
                <SubTitle>{subTitle}</SubTitle>
                <SubTitle style={{color: "#000"}}>{title}</SubTitle>
            </SubTitleCotainer>
            
        </Container>
    )
}

export default DashContentTitle
