import styled from "styled-components";

export const Container = styled.div`
    background: #F5F5F5;
     height:100vh;
     min-height: 700px;
`
export  const SigninContainer = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 80px;
    
`

