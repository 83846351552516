import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { ADD_PUBLICATIONS, EDIT_PUBLICATIONS } from "../../actions/types";
import icPicker from "../../assets/icons/ic_image_picker.svg";
import DashContentTitle from "../../components/DashContentTitles";
import ModalConfirm from "../../components/ModalConfirm";
import ModalResult from "../../components/ModalResult";
import TextareaInput from "../../components/TextareaInput/Textareainput";
import TextinputHorizontal from "../../components/TextInputHorizontal/TextinputHorizontal";
import publicationService from "../../services/publication.service";
import {
  default as uploadFilesService,
  default as UploadImagesService,
} from "../../services/upload-files-service";
import {
  BtnCancel,
  BtnSubmit,
  BtnUpload,
  ButtonContainer,
  FormAreaContainer,
  FormContainer,
  FormItem,
  FormTitle,
  ImagePickerContainer,
  PickedImage,
  PickerIcon,
  PickerText,
} from "./AddPublicationsElements";

const AddPublications = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const imageInput = useRef(null);

  const [modal, showModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const [confirmModal, showConfirmModal] = useState(false);
  const [modalData, setModalData] = useState({
    message: "",
    title: "",
  });
  const handleConfirmModalClose = (e) => {
    if (e) {
      updateProduct();
    }
    showConfirmModal(false);
  };

  const [data, setData] = useState({
    photo: "",
    title: "",
    description: "",
    call: "",
    whatsapp: "",
    price: "",
  });

  const [fieldstatustext, setFieldstatusText] = useState({
    photo: "",
    title: "",
    description: "",
    call: "",
    whatsapp: "",
    price: "",
  });

  const [photo, setPhoto] = useState();
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [uploadImageStatus, setUploadImageStatus] = useState(false);

  useEffect(() => {
    if (id) {
      dispatch({
        type: EDIT_PUBLICATIONS,
      });
      getDetails();
    } else {
      dispatch({
        type: ADD_PUBLICATIONS,
      });
    }
  }, []);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  const getDetails = () => {
    publicationService
      .getPublication(id)
      .then((res) => {
        setData({
          photo: res.data.photo,
          title: res.data.title,
          description: res.data.description,
          call: res.data.call,
          whatsapp: res.data.whatsapp,
          price: res.data.price,
        });
        setPhoto(res.data.photo);
      })
      .catch(() => {});
  };

  const onChangePhoto = (e) => {
    if (e.target.files && e.target.files[0]) {
      setPhoto(URL.createObjectURL(e.target.files[0]));
      setSelectedPhoto(e.target.files[0]);
      setData({
        ...data,
        photo: "",
      });
    }
  };

  const handleInputChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    setData({
      ...data,
      [name]: value,
    });

    setFieldstatusText({
      ...fieldstatustext,
      [name]: "",
    });
  };

  const handleModalClose = (e) => {
    showModal(false);
    if (id) {
      navigate(-1);
    }
  };

  const addNewProduct = () => {
    if (validateForm()) {
      publicationService
        .addNewPublication(data)
        .then((res) => {
          setModalMessage("Publication Details added successfully!");
          showModal(true);
          onReset();
        })
        .catch((err) => console.log(err));
    }
  };

  const updateProduct = () => {
    if (validateForm()) {
      publicationService
        .updatePublication(id, data)
        .then((res) => {
          setModalMessage("Publication details updated successfully!");
          showModal(true);
        })
        .catch((err) => console.log(err));
    }
  };

  const onCancel = () => {
    if (id) {
      navigate(-1);
      return;
    }

    if (data.photo !== "") {
      let nameSplit = data.photo.split("/");
      let key = nameSplit[nameSplit.length - 1];
      uploadFilesService
        .deleteUploadedImages([key])
        .then((result) => {})
        .catch((err) => console.log(err));
      setData({
        photo: "",
        title: "",
        description: "",
        call: "",
        whatsapp: "",
        price: "",
      });
    } else {
      setData({
        photo: "",
        title: "",
        description: "",
        call: "",
        whatsapp: "",
        price: "",
      });
    }

    setPhoto(null);
    setSelectedPhoto(null);
  };

  const onReset = () => {
    setData({
      photo: "",
      title: "",
      description: "",
      call: "",
      whatsapp: "",
      price: "",
    });
    setFieldstatusText({
      photo: "",
      title: "",
      description: "",
      call: "",
      whatsapp: "",
      price: "",
    });
    setPhoto(null);
    setSelectedPhoto(null);
  };

  const uploadImage = () => {
    if (selectedPhoto === null) {
      setFieldstatusText({
        ...fieldstatustext,
        photo: {
          text: "No photos selected!",
          color: "#B71C1C",
        },
      });
      return;
    }

    UploadImagesService.upload(selectedPhoto, (event) => {
      setUploadImageStatus(true);
    })
      .then((res) => {
        setData({
          ...data,
          photo: res.data.Location,
        });
        setFieldstatusText({
          ...fieldstatustext,
          photo: {
            text: "Photos Uploaded Successfully!",
            color: "#2E7D32",
          },
        });
        setUploadImageStatus(false);
      })
      .catch((err) => {
        setFieldstatusText({
          ...fieldstatustext,
          photo: {
            text: "Please try agin. Photos not uploaded!",
            color: "#F57F17",
          },
        });
        setUploadImageStatus(false);
      });
  };

  const validateForm = () => {
    if (data.photo === "") {
      setFieldstatusText({
        ...fieldstatustext,
        photo: {
          text: "No Photo uploaded!",
          color: "#2E7D32",
        },
      });
      alert("No Photo uploaded!");
      return false;
    }

    if (data.title === "") {
      setFieldstatusText({
        ...fieldstatustext,
        title: "* required",
      });
      alert("Title required!");
      return false;
    }
    if (data.description === "") {
      setFieldstatusText({
        ...fieldstatustext,
        description: "* required",
      });
      alert("Description required!");
      return false;
    }

    if (data.call === "") {
      setFieldstatusText({
        ...fieldstatustext,
        call: "* Phone number required!",
      });
      alert("Phone number required!");
      return false;
    } else if (data.call.length !== 10) {
      setFieldstatusText({
        ...fieldstatustext,
        call: "* 10 digits required!",
      });
      return false;
    }

    if (data.whatsapp === "") {
      setFieldstatusText({
        ...fieldstatustext,
        whatsapp: "* Phone number required!",
      });
      alert("Phone number required!");
      return false;
    } else if (data.whatsapp.length !== 10) {
      setFieldstatusText({
        ...fieldstatustext,
        whatsapp: "* 10 digits required!",
      });
      return false;
    }

    return true;
  };

  return (
    <FormAreaContainer>
      <DashContentTitle />

      <FormContainer>
        <FormItem>
          <FormTitle>Cover Image :</FormTitle>

          <ImagePickerContainer onClick={() => imageInput.current.click()}>
            {photo ? (
              <>
                <PickedImage src={photo} />
                <PickerText
                  style={{
                    background: "rgba(0,0,0,0.3)",
                    padding: ".3rem .6rem",
                    borderRadius: ".3rem",
                  }}
                >
                  Change Image
                </PickerText>
              </>
            ) : (
              <>
                <PickerIcon src={icPicker} />
                <PickerText>Upload Image</PickerText>
              </>
            )}
          </ImagePickerContainer>
          {data.photo === "" && photo && (
            <BtnUpload onClick={uploadImage}>Upload</BtnUpload>
          )}

          <input
            ref={imageInput}
            type="file"
            accept="image/png, image/jpeg"
            style={{ visibility: "hidden" }}
            onChange={(e) => onChangePhoto(e)}
          />
        </FormItem>
        <TextinputHorizontal
          type="text"
          textTransform="capitalize"
          title="title"
          error={fieldstatustext["title"]}
          placeholder="Course Title"
          name="title"
          maxLength={200}
          value={data.title}
          onChange={(e) => handleInputChange(e)}
        />
        <TextareaInput
          type="text"
          title="description"
          error={fieldstatustext["description"]}
          placeholder="Description"
          name="description"
          rows="4"
          value={data.description}
          onChange={(e) => handleInputChange(e)}
        />

        <TextinputHorizontal
          type="number"
          title="price"
          error={fieldstatustext["price"]}
          placeholder="price"
          name="price"
          value={data.price}
          onChange={(e) => handleInputChange(e)}
        />

        <TextinputHorizontal
          type="tel"
          title="contact number"
          error={fieldstatustext["call"]}
          placeholder="Contact number"
          name="call"
          maxLength={10}
          value={data.call}
          onChange={(e) => handleInputChange(e)}
        />

        <TextinputHorizontal
          type="tel"
          title="whatsapp number"
          error={fieldstatustext["whatsapp"]}
          placeholder="Whatsapp contact number"
          name="whatsapp"
          maxLength={10}
          value={data.whatsapp}
          onChange={(e) => handleInputChange(e)}
        />

        <FormItem>
          <ButtonContainer>
            <BtnCancel onClick={onCancel}>Cancel</BtnCancel>
            {id ? (
              <BtnSubmit
                onClick={(e) => {
                  setModalData({
                    message: "Are you sure want to update ?",
                    title: "Update",
                  });
                  showConfirmModal(true);
                }}
              >
                Update
              </BtnSubmit>
            ) : (
              <BtnSubmit onClick={addNewProduct}>Submit</BtnSubmit>
            )}
          </ButtonContainer>
        </FormItem>
      </FormContainer>
      <ModalResult
        open={modal}
        message={modalMessage}
        onClose={handleModalClose}
      />
      <ModalConfirm
        open={confirmModal}
        title={modalData.title}
        message={modalData.message}
        ok="Yes"
        cancel="No"
        onClose={handleConfirmModalClose}
      />
    </FormAreaContainer>
  );
};

export default AddPublications;
