import {
  BtnUpdate,
  DashBoardSubMenuItems,
  DashLogo,
  InputWnumber,
  LogoContainer,
  LogoutContainer,
  MenuContainer,
  MenuItemsContainer,
  WFormItems,
} from "./DashboardMenuElements";
import logo from "../../assets/logos/logo_white.svg";
import icHome from "../../assets/icons/ic_home.svg";
import icProducts from "../../assets/icons/ic_products.svg";
import icUser from "../../assets/icons/ic_user.svg";
import icWhatsapp from "../../assets/icons/ic_whatsapp.svg";
import DashboardMenuItem from "../DashboardMenuItem";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/auth";
import React, { useEffect, useState } from "react";
import DashboardSubMenuItem from "../DashboardSubMenuItem";
import productsService from "../../services/products-service";
import { FaWhatsapp } from "react-icons/fa";
import { BiLogInCircle, BiNews } from "react-icons/bi";
import { HiAcademicCap, HiMail } from "react-icons/hi";
import {
  MdAssignment,
  MdDashboard,
  MdOutlineWork,
  MdShield,
} from "react-icons/md";
import { ImBooks } from "react-icons/im";
import { RiEditBoxLine, RiVideoChatFill } from "react-icons/ri";
import { GiVideoConference } from "react-icons/gi";
import { SiBookstack } from "react-icons/si";

const DashboardMenu = () => {
  const dispatch = useDispatch();
  const [expansion, setExpansion] = useState("0");
  const [modal, showModal] = React.useState(false);
  const [active, setActive] = React.useState("all Products");
  const [modalMessage, setModalMessage] = useState("");
  const [business, setBusiness] = useState({
    businessNumber: "",
    id: "",
  });

  const [dropdown, setDropdown] = useState({
    dashboard: "0",
    courses: "0",
    whatsapp: "0",
    achievers: "0",
    testimonials: "0",
    news: "0",
    publications: "0",
    demo: "0",
    materials: "0",
    chiraku: "0"
  });

  useEffect(() => {
  }, []);

  const onClickLogout = () => {
    dispatch(logout());
  };

  const handleDropdown = (parent) => {
    dropdown[parent] === "0"
      ? setDropdown({ ...dropdown, [parent]: "100px" })
      : setDropdown({ ...dropdown, [parent]: "0" });
  };




  return (
    <MenuContainer>
      <MenuItemsContainer className="active-nav-dash">
        <DashboardMenuItem
          icon={<MdDashboard />}
          title="dashboard"
          to="/dashboard"
        />

        <DashboardMenuItem
          icon={<HiMail />}
          title="Enquiries"
          to="/dashboard/enquiries"
        />

        <DashboardMenuItem
          icon={<MdShield />}
          title="Chiraku"
          expand={true}
          onClick={() => handleDropdown("chiraku")}
        />
        <DashBoardSubMenuItems height={dropdown["chiraku"]}>
          <DashboardSubMenuItem
            title="Edit Details"
            to="/dashboard/chiraku/edit-details"
          />
          <DashboardSubMenuItem
            title="View Entries"
            to="/dashboard/chiraku/view-entries"
          />
        </DashBoardSubMenuItems>
        <DashboardMenuItem
          icon={<HiAcademicCap />}
          title="courses"
          expand={true}
          onClick={() => handleDropdown("courses")}
        />
        <DashBoardSubMenuItems height={dropdown["courses"]}>
          <DashboardSubMenuItem
            title="Add Courses"
            to="/dashboard/courses/add-course"
          />
          <DashboardSubMenuItem
            title="Manage Courses"
            to="/dashboard/courses/my-courses"
          />
        </DashBoardSubMenuItems>

        <DashboardMenuItem
          icon={<ImBooks />}
          title="publications"
          expand={true}
          onClick={() => handleDropdown("publications")}
        />
        <DashBoardSubMenuItems height={dropdown["publications"]}>
          <DashboardSubMenuItem
            title="Add publications"
            to="/dashboard/publications/add-publications"
          />
          <DashboardSubMenuItem
            title="Manage publications"
            to="/dashboard/publications/my-publications"
          />
        </DashBoardSubMenuItems>

        <DashboardMenuItem
          icon={<BiNews />}
          title="latest news"
          expand={true}
          onClick={() => handleDropdown("news")}
        />
        <DashBoardSubMenuItems height={dropdown["news"]}>
          <DashboardSubMenuItem
            title="Add News"
            to="/dashboard/news/add-news"
          />
          <DashboardSubMenuItem
            title="Manage News"
            to="/dashboard/news/my-news"
          />
        </DashBoardSubMenuItems>
        <DashboardMenuItem
          icon={<MdOutlineWork />}
          title="achievers"
          expand={true}
          onClick={() => handleDropdown("achievers")}
        />
        <DashBoardSubMenuItems height={dropdown["achievers"]}>
          <DashboardSubMenuItem
            title="Add Achievers"
            to="/dashboard/achievers/add-achievers"
          />
          <DashboardSubMenuItem
            title="View Achievers"
            to="/dashboard/achievers/my-achievers"
          />
        </DashBoardSubMenuItems>
        <DashboardMenuItem
          icon={<RiVideoChatFill />}
          title="testimonials"
          expand={true}
          onClick={() => handleDropdown("testimonials")}
        />
        <DashBoardSubMenuItems height={dropdown["testimonials"]}>
          <DashboardSubMenuItem
            title="Add testimonials"
            to="/dashboard/testimonial/add-testimonial"
          />
          <DashboardSubMenuItem
            title="View testimonials"
            to="/dashboard/testimonials/my-testimonials"
          />
        </DashBoardSubMenuItems>

        <DashboardMenuItem
          icon={<GiVideoConference />}
          title="demo classes"
          expand={true}
          onClick={() => handleDropdown("demo")}
        />
        <DashBoardSubMenuItems height={dropdown["demo"]}>
          <DashboardSubMenuItem
            title="Add demo Classes"
            to="/dashboard/demo/add-demo-classes"
          />
          <DashboardSubMenuItem
            title="View demo classes"
            to="/dashboard/demo/my-demo-classes"
          />
        </DashBoardSubMenuItems>
        <DashboardMenuItem
          icon={<SiBookstack />}
          title="study materials"
          expand={true}
          onClick={() => handleDropdown("materials")}
        />
        <DashBoardSubMenuItems height={dropdown["materials"]}>
          <DashboardSubMenuItem
            title="Add study materials"
            to="/dashboard/materials/add-materials"
          />
          <DashboardSubMenuItem
            title="View study materials"
            to="/dashboard/materials/my-materials"
          />
        </DashBoardSubMenuItems>
        <DashboardMenuItem
          icon={<RiEditBoxLine />}
          title="General"
          to="/dashboard/general"
        />
        {/* <DashboardMenuItem
          icon={<RiWhatsappFill />}
          title="whatsapp business"
          expand={true}
          onClick={() => handleDropdown("whatsapp")}
        />
        <WFormItems height={dropdown["whatsapp"]}>
          <InputWnumber
            type="text"
            value={business.businessNumber}
            onChange={onChangeContact}
          />
          <BtnUpdate onClick={() => showModal(true)}>Update</BtnUpdate>
        </WFormItems> */}
        <LogoutContainer>
          <DashboardMenuItem
            icon={<BiLogInCircle />}
            title="Logout"
            onClick={onClickLogout}
          />
        </LogoutContainer>
      </MenuItemsContainer>
      {/* <ModalConfirm open={modal}
            title = "Update"
            message= "Are you sure want to update ?"
            cover={confirmDialogCover}
            onClose= {handleModalClose} /> */}
    </MenuContainer>
  );
};

export default DashboardMenu;
