import styled from "styled-components";

export const ServicesSectionContainer  = styled.div`
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    padding: 2rem;
    background: #fff;
    @media screen and (max-width: 768px){
      padding: 1rem;
    }
`
export const CarouselContainer = styled.div`
    width: 768px;
    @media screen and (max-width: 768px){
        gap: 0.5rem;
        width: 100%;
    }
`
export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
`

export const TitlePartOne = styled.h2`
    color: #40C4FF;
`
export const TitlePartTwo = styled.h2`
    color: #01579B;
`
