import styled from "styled-components";

export const ContactSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  margin-top: 2rem;
  
`;
export const ContactContents = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;
  padding: 2rem;
  background-image: linear-gradient( #fff, #eee) ;
  width: 600px;
  box-shadow: #E1F5FE 0px 18px 50px -10px;
  border-radius: 18px;
  @media screen and (max-width: 768px) {
    margin: 1rem;
    padding: 1rem;
    gap: 2rem;
    width: 100%;
    flex-direction: column;
  }
`;
export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;


export const TitlePartTwo = styled.h2`
  color: #07559A;
  padding-bottom: 6px;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.5rem;
  align-items: flex-start;
  width: 100%;
`;




export const BtnSubmit = styled.button`
    color: #fff;
    box-sizing:border-box;
    font-size: 1rem;
    border:0;
    background: #262626;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
    transition: all 200ms;
    &:hover{
      box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    }
`