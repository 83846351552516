import React from "react";
import {
  InputBox,
  InputBoxContainer,
  PrefixText
} from "./PrefixedInputBoxElements";

const PrefixedInputBox = (props) => {
  const {
    error,
    onChange,
    placeholder,
    name,
    maxLength,
    value,
    type,
    textTransform,
    min,
    max,
  } = props;

  return (
    <InputBoxContainer>
      <PrefixText>+91</PrefixText>
      <InputBox
        textTransform={textTransform}
        error={error}
        type={type}
        min={min}
        max={max}
        placeholder={placeholder}
        name={name}
        maxLength={maxLength}
        value={value}
        onChange={(e) => onChange(e)}
      />
    </InputBoxContainer>
  );
};

export default PrefixedInputBox;
