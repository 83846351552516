import DashContentTitle from "../../components/DashContentTitles";
import { useDispatch, useSelector } from "react-redux";
import {
  BtnCancel,
  BtnSubmit,
  BtnUpload,
  ButtonContainer,
  CategoriesInput,
  CategoriesOption,
  CurrencyType,
  EmbedContainer,
  FormAreaContainer,
  FormContainer,
  FormItem,
  FormTitle,
  ImagePickerContainer,
  InputText,
  PickedImage,
  PickerIcon,
  PickerText,
  PreviewContainer,
  PriceInputContainer,
} from "./ManageChirakuElements";
import icPicker from "../../assets/icons/ic_image_picker.svg";
import { useEffect, useRef, useState } from "react";
import ProductsService from "../../services/products-service";
import UploadImagesService from "../../services/upload-files-service";
import { Navigate, Redirect, useNavigate, useParams } from "react-router-dom";
import ModalResult from "../../components/ModalResult";
import { ADD_COURSE, EDIT_COURSE, EDIT_DETAILS } from "../../actions/types";
import categoryService from "../../services/category.service";
import SelectInput from "../../components/SelectInput/SelectInput";
import TextinputHorizontal from "../../components/TextInputHorizontal/TextinputHorizontal";
import TextareaInput from "../../components/TextareaInput/Textareainput";
import courseService from "../../services/course.service";
import MultiTextInput from "../../components/MultiTextInput/MultiTextInput";
import YoutubeEmbed from "../../components/YoutubeEmbed";
import chirakuService from "../../services/chiraku.service";

const ManageChiraku = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const imageInput = useRef(null);

  const [modal, showModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const [data, setData] = useState({
    photo: "",
    title: "",
    description: "",
    video:"",
    call: "",
    whatsapp: ""
  });

  const [fieldstatustext, setFieldstatusText] = useState({
    photo: {
      text: "",
      color: "",
    },
    title: "",
    description: "",
    video:"",
    call: "",
    whatsapp: ""
  });

  const [update, setUpdate] = useState(false);
  const [photo, setPhoto] = useState("");
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [uploadImageStatus, setUploadImageStatus] = useState(false);

  useEffect(() => {

    
      getDetails();
      dispatch({
        type: EDIT_DETAILS,
      });
  }, []);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  const getDetails = () => {
    chirakuService
      .getChirakuDetails()
      .then((res) => {
        setData(res.data);
        setPhoto(res.data.photo);

        if(res.data) setUpdate(true);
      })
      .catch(() => {});
  };




  const onChangePhoto = (e) => {
    if (e.target.files && e.target.files[0]) {
      setPhoto(URL.createObjectURL(e.target.files[0]));
      setSelectedPhoto(e.target.files[0]);
      setData({
        ...data,
        photo: "",
      });
    }
  };

  const handleInputChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    setData({
      ...data,
      [name]: value,
    });

    setFieldstatusText({
      ...fieldstatustext,
      [name]: "",
    });

  };


  const handleModalClose = (e) => {
    showModal(false);
  };

  const addNewProduct = () => {
    if (validateForm()) {
      const chiraku = {
        photo: data.photo,
        title: data.title,
        description: data.description,
        video: data.video,
        call: data.call,
        whatsapp: data.whatsapp
      };
      chirakuService
        .addChirakuDetails(chiraku)
        .then((res) => {
          setModalMessage("Chiraku details added successfully!");
          showModal(true);
          onReset();
        })
        .catch((err) => console.log(err));
    }
  };

  const updateProduct = () => {
    if (validateForm()) {
      chirakuService
        .updateChirakuDetails(data._id, data)
        .then((res) => {
          setModalMessage("Chiraku details updated successfully!");
          showModal(true);
        })
        .catch((err) => console.log(err));
    }
  };



  const onReset = () => {
    setFieldstatusText({
      photo: "",
      title: "",
      description: "",
      video:"",
      call: "",
      whatsapp: ""
    });
  };

  const uploadImage = () => {
    if (selectedPhoto === null) {
      setFieldstatusText({
        ...fieldstatustext,
        photo: {
          text: "No photos selected!",
          color: "#B71C1C",
        },
      });
      return;
    }

    UploadImagesService.upload(selectedPhoto, (event) => {
      setUploadImageStatus(true);
    })
      .then((res) => {
        setData({
          ...data,
          photo: res.data.Location,
        });
        setFieldstatusText({
          ...fieldstatustext,
          photo: {
            text: "Photos Uploaded Successfully!",
            color: "#2E7D32",
          },
        });
        setUploadImageStatus(false);
      })
      .catch((err) => {
        setFieldstatusText({
          ...fieldstatustext,
          photo: {
            text: "Please try agin. Photos not uploaded!",
            color: "#F57F17",
          },
        });
        setUploadImageStatus(false);
      });
  };

  const validateForm = () => {
    if (data.photo === "") {
      setFieldstatusText({
        ...fieldstatustext,
        photo: {
          text: "No Photo uploaded!",
          color: "#2E7D32",
        },
      });
      alert("No Photo uploaded!");
      return false;
    }

    if (data.title === "") {
      setFieldstatusText({
        ...fieldstatustext,
        title: "* required",
      });
      alert("Title required!");
      return false;
    }

    if (data.call === "") {
      setFieldstatusText({
        ...fieldstatustext,
        call: "* Phone number required!",
      });
      alert("Phone number required!");
      return false;
    } else if (data.call.length !== 10) {
      setFieldstatusText({
        ...fieldstatustext,
        call: "* 10 digits required!",
      });
      return false;
    }

    if (data.whatsapp === "") {
      setFieldstatusText({
        ...fieldstatustext,
        whatsapp: "* Phone number required!",
      });
      alert("Phone number required!");
      return false;
    } else if (data.whatsapp.length !== 10) {
      setFieldstatusText({
        ...fieldstatustext,
        whatsapp: "* 10 digits required!",
      });
      return false;
    }

    return true;
  };

  return (
    <FormAreaContainer>
      <DashContentTitle />
      <FormContainer>
        <FormItem>
          <FormTitle>Cover Image :</FormTitle>

          <ImagePickerContainer onClick={() => imageInput.current.click()}>
            {photo ? (
              <>
                <PickedImage src={photo} />
                <PickerText
                  style={{
                    background: "rgba(0,0,0,0.3)",
                    padding: ".3rem .6rem",
                    borderRadius: ".3rem",
                  }}
                >
                  Change Image
                </PickerText>
              </>
            ) : (
              <>
                <PickerIcon src={icPicker} />
                <PickerText>Upload Image</PickerText>
              </>
            )}
          </ImagePickerContainer>
          {data?.photo === "" && photo && (
            <BtnUpload onClick={uploadImage}>Upload</BtnUpload>
          )}

          <input
            ref={imageInput}
            type="file"
            accept="image/png, image/jpeg"
            style={{ visibility: "hidden" }}
            onChange={(e) => onChangePhoto(e)}
          />
        </FormItem>
        <TextinputHorizontal
          type="text"
          textTransform="capitalize"
          title="title"
          error={fieldstatustext["title"]}
          placeholder="Course Title"
          name="title"
          maxLength={200}
          value={data?.title}
          onChange={(e) => handleInputChange(e)}
        />
        <TextareaInput
          type="text"
          title="description"
          error={fieldstatustext["description"]}
          placeholder="Description"
          name="description"
          rows="4"
          value={data?.description}
          onChange={(e) => handleInputChange(e)}
        />
        <EmbedContainer>
          <YoutubeEmbed link={data?.video ? data?.video : ""} />
        </EmbedContainer>

        <TextinputHorizontal
          type="text"
          textTransform="capitalize"
          title="Video link"
          error={fieldstatustext["video"]}
          placeholder="Paste url"
          name="video"
          maxLength={600}
          value={data?.video}
          onChange={(e) => handleInputChange(e)}
        />

<TextinputHorizontal
          type="tel"
          title="contact number"
          error={fieldstatustext["call"]}
          placeholder="Contact number"
          name="call"
          maxLength={10}
          value={data?.call}
          onChange={(e) => handleInputChange(e)}
        />

        <TextinputHorizontal
          type="tel"
          title="whatsapp number"
          error={fieldstatustext["whatsapp"]}
          placeholder="Whatsapp contact number"
          name="whatsapp"
          maxLength={10}
          value={data?.whatsapp}
          onChange={(e) => handleInputChange(e)}
        />

        <FormItem>
          <ButtonContainer>
            {update ? (
              <BtnSubmit onClick={updateProduct}>Update</BtnSubmit>
            ) : (
              <BtnSubmit onClick={addNewProduct}>Submit</BtnSubmit>
            )}
          </ButtonContainer>
        </FormItem>
      </FormContainer>
      <ModalResult
        open={modal}
        message={modalMessage}
        onClose={handleModalClose}
      />
    </FormAreaContainer>
  );
};

export default ManageChiraku;
