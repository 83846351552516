import styled from "styled-components";

export const MenuContainer = styled.div`
    width: 300px;
    background: #262626;
    height: calc(100vh - 80px - 2rem);
    min-height: 600px;
    border-radius: .5rem;
    overflow-y: scroll;


    @media screen and (max-width: 768px){
        min-height: 300px;
        max-height: 400px;
        height: fit-content;
        width: 100%;
        overflow-y: auto;
    }

    &::-webkit-scrollbar-track
    {
        background-color: #262626;
        border-radius: 0 0.5rem 0.5rem 0;
    }

   &::-webkit-scrollbar
    {   
        
        width: 6px;
        background-color: #f5f5f5;
    }

   &::-webkit-scrollbar-thumb
    {
        border-radius: 10px;
        background-color: rgba(255, 255, 255, 0.1);
    }

`

export const LogoContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 2rem;
    @media screen and (max-width: 768px){
       display: none;
    }

`
export const DashLogo = styled.img`
    width: 100px;

`

export const MenuItemsContainer = styled.div`
    padding: 1rem;
    
`

export const DashBoardSubMenuItems = styled.div`
    margin-left: 2rem;
    height: ${props => props.height};
    overflow: hidden;
    transition: all 400ms;
`
export const WFormItems = styled.div`
    margin-left: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.5rem;
    height: ${props => props.height};
    overflow: hidden;
    transition: all 400ms;
`
export const InputWnumber = styled.input`
    color: #fff;
    background: rgba(248, 250, 252, 0.1);
    border-radius: 4px;
    padding: 12px 19px;
    border: 0;
    font-size: 1rem;
    font-weight: 500;
    outline: none;
    margin-top: .3rem;
`
export const BtnUpdate = styled.div`
    background: #FC3535;
    border-radius: 4px;
    color: #fff;
    padding: 12px 19px;
    width: fit-content;
    font-weight: 600;
    cursor: pointer;

`

export const LogoutContainer = styled.div`
    @media screen and (max-width: 768px){
       display: none;
    }
`