import styled from "styled-components";

export const ContentContainer = styled.div`
  width: 100%;
  max-width: 1100px;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  min-height: 60vh;
  animation: zoom-in 500ms;
`;

export const Desc = styled.p`
  line-height: 24px;
  color: #424242;
  padding: 1rem;
`

export const NotLink = styled.a`
  line-height: 24px;
  color: #05A8F4;
  text-decoration: none;
  border: 2px solid #05A8F4;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin: 1rem;

`