import React from 'react';
import { Container, MenuTitle } from './MenuItemElements';

const MenuItem = (props) => {
    const {title, to} = props;
  return (
    <Container to={to}>
            <MenuTitle>{title}</MenuTitle>
    </Container>
    );
};

export default MenuItem;
