import http from "../http-common";
import authHeader from "./auth-header";

class ChirakuService{


    addChirakuDetails(data){
        return http.post(`/chiraku/add-chiraku-details`, JSON.stringify(data),{headers: authHeader(), withCredentials: true});

    }


    getChirakuDetails(){
        return http.get(`/chiraku/all`);
    }



    updateChirakuDetails(id, data){
        return http.put(`/chiraku/update/${id}`, JSON.stringify(data),{headers: authHeader(), withCredentials: true});

    }

 

    

  
}


export default new ChirakuService();