import { Link as LinkR } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background: #fff;
  animation: slide-in-top 500ms;
  

`;
export const ContentContainer = styled.div`
  width: 100%;
  max-width: 1100px;
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  
`;

export const Linkpath = styled.p`
  color: #212121;
`;

export const DetailsContainer = styled.div`
  display: flex;
  gap: 2rem;
  margin-top: 24px;
  padding: 24px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  border-radius: 12px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    
  }
`;

export const SpecialDetailsContainer = styled.div`
  display: flex;
  gap: 2rem;
  margin-top: 24px;
  align-items: center;
  align-self: center;
`;
export const CourseIcon = styled.img`
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  width: 300px;
  height: 400px;
  object-fit: cover;
  background: #fff;
  border-radius: 12px;
  @media screen and (max-width: 768px) {
    align-self: center;
    width: 100%;
    height: unset;
    object-fit: contain;
  }
`;
export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
export const CourseTitle = styled.h2`
  color: #212121;
  margin: 0;
  text-transform: capitalize;
`;

export const TxtPrice = styled.h2`
  color: #000;
  margin: 0;
  text-transform: capitalize;
`;

export const Desc = styled.p`
  color: #616161;
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5625;
  margin-bottom: 15px;
`;

export const DetailsItem = styled.p`
  background: #fff;
  padding: 12px 18px;
  border-radius: 8px;
  margin: 0;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  cursor: default;
`;

export const CourseLink = styled(LinkR)`
  background: #0476bd;
  padding: 12px 18px;
  border-radius: 8px;
  margin: 0;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
`;

export const ContactContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  margin: 24px 0;
`;

export const WhatsappContainer = styled.a`
  cursor: pointer;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: all 200ms;
  &:hover {
    transform: scale(1.08);
  }
`;
export const BtnCall = styled.p`
  margin: 0;
  font-size: 14px;
`;

export const CallContainer = styled.a`
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: #fc3535;
  border: 2px solid #fc3535;
  padding: 12px 18px;
  border-radius: 12px;
  text-transform: capitalize;
  font-weight: 600;
  transition: all 200ms;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  &:hover {
    background: #fc3535;
    color: #fff;
    transform: scale(1.05);
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px,
      rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px,
      rgba(0, 0, 0, 0.07) 0px 16px 16px;
  }
`;
