import React from "react";
import {
  InputBox,
  InputBoxContainer,
  InputContainer,
  InputError,
  InputTitle,
} from "./TextareaInputElemants";

const TextareaInput = (props) => {
  const { title, error, onChange, placeholder, name, value, rows} = props;
  return (
    <InputContainer>
      <InputTitle>{title} :</InputTitle>
      <InputBoxContainer>
        <InputBox
          error={error}
          placeholder={placeholder}
          name={name}
          value={value}
          rows= {rows}
          onChange={(e) => onChange(e)}
        />
        <InputError>{error}</InputError>
      </InputBoxContainer>
    </InputContainer>
  );
};

export default TextareaInput;
