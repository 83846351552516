import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { DASHBOARD } from '../../actions/types';
import MenuItem from '../../components/MenuItem';
import { ContentContainer } from './DashboardOverviewElements';

const DashboardOverview = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({
            type: DASHBOARD,
        });
     }, []);
     
  return(
    <ContentContainer>
        <MenuItem title="view enquiries" to="/dashboard/enquiries"/>
        <MenuItem title="view chiraku entries" to="/dashboard/chiraku/view-entries"/>
        <MenuItem title="view publications" to="/dashboard/publications/my-publications"/>
        <MenuItem title="view courses" to="/dashboard/courses/my-courses"/>
        <MenuItem title="view news" to="/dashboard/news/my-news"/>
        
    </ContentContainer>
  );
};

export default DashboardOverview;
