import { Tooltip } from "@mui/material";
import { useState } from "react";
import { FaFileAlt } from "react-icons/fa";
import {
  MdDelete,
  MdEdit,
  MdFileDownload,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Column, Row } from "../../globalStyle";
import achieverService from "../../services/achiever.service";
import materialsService from "../../services/materials.service";
import ModalConfirm from "../ModalConfirm";
import {
  ActionButton,
  ActionButtons,
  BtnExpand,
  BtnKnowMore,
  CoverContainer,
  DataContainer,
  SDesc,
  ServiceItemContainer,
  SIcon,
  STitle,
} from "./MyMaterialsItemElements";

const MaterialsItem = (props) => {
  const { data, edit, onDeleteItem } = props;

  const navigate = useNavigate();

  const [confirmModal, showConfirmModal] = useState(false);
  const [modalData, setModalData] = useState({
    message: "",
    title: "",
  });

  const handleConfirmModalClose = (e) => {
    if (e) {
      onDelete();
    }
    showConfirmModal(false);
  };

  const [expand, setExpand] = useState(false);

  const onDelete = () => {
    materialsService
      .deleteMaterials(data._id)
      .then((res) => {
        
        onDeleteItem(data._id);
        showConfirmModal(false);
      })
      .catch((err) => console.log(err));
  };

  const downloadFile = () => {
    let a = document.createElement('a');
	a.href = data.file;
	a.download = data.title;
	a.click();
  }

  return (
    <ServiceItemContainer>
        <FaFileAlt/>
        <STitle>{data.title}</STitle>
        <ActionButtons>
          <Tooltip title="Download">
            <ActionButton
              bg="#01579B"
              aria-label=""
              component="span"
              size="small"
              onClick={downloadFile}
            >
              <MdFileDownload />
            </ActionButton>
          </Tooltip>
          
        </ActionButtons>
    </ServiceItemContainer>
  );
};

export default MaterialsItem;
