import http from '../http-common';



export const login = (username,password) => {
    return http.post('/auth/signin', {
        username,
        password,
    },{withCredentials: true})
    .then((response) => {
        if(response.data.accessToken){
            localStorage.setItem("cubiten", JSON.stringify(response.data))
        }
        return response.data;
    })
}


export const logout = () => {
    localStorage.removeItem("cubiten");
  };

export default {login,logout};