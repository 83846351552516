import React from 'react'
import { BtnOk, ButtonConatiner, Cover, Message, ModalContainer } from './ModalElements'
import { Dialog, DialogTitle } from '@mui/material';



const ModalConfirm = (props) => {
    const { onClose, open, title, message, cover } = props;

    return (
    
        <Dialog onClose={() => onClose(false)} open={open}>
            
                <DialogTitle >{title}!</DialogTitle>
                <ModalContainer>
                    <Cover src={cover}/>
                    <Message>{message}</Message>
                    <ButtonConatiner>
                        <BtnOk color="#262626" onClick={() => props.onClose(true)}>OK</BtnOk>
                        <BtnOk color="#546E7A" onClick={() => props.onClose(false)}>Cancel</BtnOk>
                    </ButtonConatiner>
            </ModalContainer>
        </Dialog>
    )
}

export default ModalConfirm
