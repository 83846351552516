import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;
export const AboutContainer = styled.div`
  width: 100%;
  max-width: 1100px;
  margin-top: 50px;

  @media screen and (max-width: 768px) {
    margin: 20px;
  }
`;

export const AboutTitle = styled.h1`
  color: #263238;
`;

export const AboutDescription = styled.div`
  font-weight: 200;
  font-size: 1rem;
  line-height: 1.5;
  word-spacing: 0.2rem;
  color: #455a64;
  display: flex;
  height: 300px;
  margin-bottom: 5rem;

  @media screen and (max-width: 768px) {
    height: max-content;
    display: flex;
    flex-direction: ${(props) => props.flexd};
  }
`;

export const DescTitleContainer = styled.div`
  flex: 2;
  justify-content: center;
  background: ${(props) => props.background};
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 3px;
  animation: leftIn 1.5s;
  transform-origin: ${(props) => props.from};

  @keyframes leftIn {
    from {
      transform: scaleX(0);
    }
    to {
      transform: scaleX(1);
    }
  }
`;

export const DescTitle = styled.h4`
  color: #263238;
`;

export const DescDescription = styled.p`
  flex: 5;
  text-align: justify;
  font-size: 1.2rem;

  animation: TextIn 1.5s;

  @media screen and (max-width: 768px) {
    padding: 0 !important;
  }

  @keyframes TextIn {
    from {
      transform: translateX(${(props) => props.from});
    }
    to {
      transform: translateX(0);
    }
  }
`;
