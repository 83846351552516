import { IcExpand, MenuItemContainer, MenuItemContainerLink, MenuItemIcon, MenuItemTitle } from "./DashboardMenuItemElements"
import { MdKeyboardArrowDown } from "react-icons/md";

const DashboardMenuItem = (props) => {

    const { icon, title, expand, onClick, to } = props;
    return (
        <>
      {to ? (
        <MenuItemContainerLink
        to={to}
          end
        >
          {icon ? icon : null}

          <MenuItemTitle>{title}</MenuItemTitle>
        </MenuItemContainerLink>
      ) : (
        <MenuItemContainer onClick={onClick}>
          {icon ? icon : null}

          <MenuItemTitle>{title}</MenuItemTitle>

          {expand ? <MdKeyboardArrowDown /> : null}
        </MenuItemContainer>
      )}
    </>
    )
}

export default DashboardMenuItem
