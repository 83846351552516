import http from "../http-common";
import authHeader from "./auth-header";

class AchieverService{

    getAllAchieversByCategory(id){
        return http.get(`/achiever/all/byCatID/${id}`);
    }


    addNewAchiever(data){
        return http.post(`/achiever/add-new-achiever`, JSON.stringify(data),{headers: authHeader(), withCredentials: true});

    }

    getAllAchievers(){
        return http.get(`/achiever/all`);
    }

    getAchievers(id){
        return http.get(`/achiever/${id}`);
    }

    updateAchievers(id, data){
        return http.put(`/achiever/update/${id}`, JSON.stringify(data),{headers: authHeader(), withCredentials: true});

    }

    deleteAchievers(id){
        return http.delete(`/achiever/delete/${id}`,{headers: authHeader(), withCredentials: true});
    }

    

  
}


export default new AchieverService();