import { Button } from "@mui/material";
import styled from "styled-components";

export const ServiceItemContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #FEEAE1;
  border-radius: 8px;
  cursor: pointer;
  overflow: hidden;
`;

export const CoverContainer = styled.div`
  background: #f5f5f5;
  position: relative;
  width: 100%;
  /* padding-top: 56.25%; */
  aspect-ratio: 2 / 3;
`;

export const SIcon = styled.img`
  /* position: absolute;
  top: 0;
  left: 0; */
  height: 100%;
  width: 100%;
  object-fit: cover;
  /* padding-bottom: 75%; */
  /* object-fit: contain;
  object-position: left; */
`;
export const STitle = styled.p`
  
`;
export const SDesc = styled.p`
  color: rgba(15, 15, 15, 0.5);
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

`;
export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BtnKnowMore = styled(Button)`
  background-color: #01579b !important;
  text-transform: capitalize !important;
`;
