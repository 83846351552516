import { Button } from "@mui/material";
import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";

export const ServiceItemContainer = styled(LinkR)`
  width: 300px;
  height: 200px;
  margin: 10px;
  display: flex;
  align-items: flex-start;
  position: relative;
  transition: all 300ms;
  cursor: pointer;
  text-decoration: none;
  animation: zoom-in 500ms;

  @media screen and (max-width: 768px) {
    margin: 10px auto;
  }

  
`;

export const SIcon = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background: #e1f5fe;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
`;
export const STitle = styled.p`
min-width: 260px;
  font-size: 14px;
  font-weight: 400;
  color: #212121;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
  padding: 6px 12px;
  border-radius: 0 16px 16px 16px;
  margin: 0;

  background: #fff;
  position: absolute;
  left: 30px;
  top: 45px;
  transition: all 300ms;
  &:hover{
    transform: scale(1.2);
  }
`;
export const SDesc = styled.p`
  font-size: 14px;
  color: rgba(15, 15, 15, 0.5);
  display: -webkit-box;
  font-weight: 300;
  margin-top: 3px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const NewTag = styled.p`
    font-size: 12px;
    position: absolute;
    background: #2E7D32;
    color: #fff;
    padding: 4px 8px;
    left: 65px;
    top: 10px;
    border-radius: 8px  8px 8px 0;
`