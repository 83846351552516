import styled from "styled-components";

export const Container = styled.div`
  background: #fff;
  border-radius: 12px;
  width: 100%;
  overflow: hidden;
  text-align: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;
export const EmbedContainer = styled.div`
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  border-radius: 12px 12px 0 0;
`;

export const EmbedIframe = styled.iframe`
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
`;

export const EmbedTitle = styled.p`
  text-transform: capitalize;
`;

export const EmbedDesc = styled.p`
  font-weight: 300;
  font-size: 14px;
  color: #424242;
  line-height: 24px;
`;
