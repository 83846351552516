import React, { useEffect, useState } from "react";
import { Container } from "../../globalStyle";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { TabList, TabPanel, TabContext } from "@mui/lab";
import {
  AchieverConrainer,
  AchieverContainer,
  CenterPanel,
  ContentContainer,
  Desc,
  ListContainer,
  NotLink,
  RightPanel,
  RightPanelTitle,
  TabContainer,
  TestimonialContainer,
} from "./PublicationElements";
import { useParams } from "react-router-dom";
import courseService from "../../services/course.service";
import AchieverItem from "../../components/AchieverItem";
import achieverService from "../../services/achiever.service";
import CarouselAuto from "../../components/CarouselAuto";
import CarouselAchievers from "../../components/CarouselAchievers";
import YoutubeEmbed from "../../components/YoutubeEmbed";
import SubjectItem from "../../components/SubjectItem";
import testimonialService from "../../services/testimonial.service";
import newsService from "../../services/news.service";
import NewsHeroSection from "../../components/NewsHeroSection";
import { format } from "date-fns";
import publicationService from "../../services/publication.service";
import PublicationHeroSection from "../../components/PublicationHeroSection";

const Publication = () => {
  const { id } = useParams();


  const [publication, setPublication] = useState({
    photo: "",
    title: "",
    description: "",
    price: ""
  });

  useEffect(() => {
    getNewsDetails();
  }, []);

  const getNewsDetails = () => {
    publicationService
      .getPublication(id)
      .then((res) => {
        
        setPublication(res.data);
      })
      .catch(() => {});
  };

  return (
    <Container>
      <PublicationHeroSection
        title={publication.title}
        photo={publication.photo}
        price={publication.price}
        desc={publication.description}
        call={publication.call}
        whatsapp={publication.whatsapp}
      />
      <ContentContainer>
      </ContentContainer>
    </Container>
  );
};

export default Publication;
