import styled from "styled-components";

export const SubjectItemContainer =styled.div`
    animation: slide-in-top 500ms;
`
export const SubjectTitle =styled.p`
    font-weight: 500;
    color: #212121;
    text-transform: capitalize;
    padding-left: 12px;
    border-left: 3px solid #40C4FF;
`
export const SubjectDesc =styled.p`
    margin-left: 15px;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    color: #424242;
`

export const TableImage = styled.img`
  width: 100%;
`