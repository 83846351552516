import React from "react";
import PDFViewer from "../PDFViewer";
import {
  SubjectDesc,
  SubjectItemContainer,
  SubjectTitle,
  TableImage,
} from "./SubjectItemElements";

const SubjectItem = ({ data }) => {
  const fileName = data.photo ? data.photo.substring(data.photo.lastIndexOf('/')+1): "";
  const fileType = fileName.split(".").reverse()[0];


  return (
    <SubjectItemContainer>
      <SubjectTitle>{data.title}</SubjectTitle>

      {fileType === "pdf"
        ? data.photo && <PDFViewer file={data.photo} />
        : data.photo && <TableImage src={data.photo} />}
    </SubjectItemContainer>
  );
};

export default SubjectItem;
