import styled from "styled-components";

export const ConatcUsContainer = styled.div`
    width: 100%;
    max-width: 1100px;
    margin-top: 50px;
    min-height: 75vh;
    display: flex;
    align-items: center;
    @media screen and (max-width: 768px){
        margin: 20px;
        flex-direction: column;
        align-items: center;
    }
`

export const ContactCover = styled.img`
    width: 30%;
    object-fit: contain;
    flex: 1;
    animation: In 1s;

    @media screen and (max-width: 768px){
        width: 50%;
        flex-direction: column;
        align-items: center;
    }


    @keyframes In {
        from {
            transform: translateX(-50px);
            
        }
        to {transform: translateX(0)}
    }
`
export const ContactUsTitle = styled.h3`

    animation: DIn 1s;

    @keyframes DIn {
        from {
            transform: translateX(50px);
            
        }
        to {transform: translateX(0)}
    }
`

export const DetailsContainer = styled.div`
    flex: 1;
    margin-left: 2rem;
    align-items: center;
    display: flex;
    flex-direction: column;
    animation: DIn 1s;

    @media screen and (max-width: 768px){
        margin-left: 0;
    }

    @keyframes DIn {
        from {
            transform: translateX(50px);
            
        }
        to {transform: translateX(0)}
    }
`


export const CompLogo = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: none;
    justify-content: center;
`;

export const CompTitleContainer = styled.div`
    justify-self: flex-start;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    margin-left: 10px;
`;

export const CompTitle = styled.h2`
    color: #4082B2;
    justify-self: flex-start;
    font-weight: bold;
    margin: 0;
    text-transform: uppercase;

    
`;

export const CompSubTitle = styled.h4`
    cursor: pointer;
    margin: 0;

`;

export const CompIcon = styled.img`
    width: 180px;
`

export const CompDetails = styled.div`
    margin-top: 1rem;
    flex: 1;
    animation: DIn 1s;

    @keyframes DIn {
        from {
            transform: translateY(-40px);
            
        }
        to {transform: translateX(0)}
    }
`
export const CompAddressContainer = styled.div`
    margin-top: 1.2rem;
    text-align: center;
`
export const TxtLabel = styled.p`
    font-weight: 400;
    font-size: 1rem;
    margin: 0;
    line-height: 1.6;
    text-decoration: none;
    color: #37474F;
    transition: color 200ms;
    text-align: center;
    cursor: pointer;
    &:hover{
        color: #000;
    }
`