import http from "../http-common";
import authHeader from "./auth-header";

class PublicationService{


    addNewPublication(data){
        return http.post(`/publication/add-new-publication`, JSON.stringify(data),{headers: authHeader(), withCredentials: true});

    }

    getAllPublications(){
        return http.get(`/publication/all`);
    }

    getPublication(id){
        return http.get(`/publication/${id}`);
    }

    updatePublication(id, data){
        return http.put(`/publication/update/${id}`, JSON.stringify(data),{headers: authHeader(), withCredentials: true});

    }

    deletePublication(id){
        return http.delete(`/publication/delete/${id}`,{headers: authHeader(), withCredentials: true});
    }

    

  
}


export default new PublicationService();