import http from "../http-common";
import authHeader from "./auth-header";

class NewsService{

   

    // getCoursesNameByCatID(catID){
    //     return http.get(`/course/all/${catID}`);
    // }

    getAllNews(){
        return http.get('/news/all');
    }

    getNewsById(id){
        return http.get(`/news/newsById/${id}`);
    }


    addNews(data){
        return http.post(`/news/add-news`, JSON.stringify(data),{headers: authHeader(), withCredentials: true});

    }

    updateNews(id, data){
        return http.put(`/news/update/${id}`, JSON.stringify(data),{headers: authHeader(), withCredentials: true});

    }

    deleteNews(id){
        return http.delete(`/news/delete/${id}`,{headers: authHeader(), withCredentials: true});
    }

  
}


export default new NewsService();