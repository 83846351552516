import styled from "styled-components";
import {Link as LinkR} from "react-router-dom";

export const FooterContainer = styled.div`
    background: #041562;
    height: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    gap: 2rem;
    border-radius: 2rem 2rem 0 0 ;
`
export const WLogo = styled.img`
    width: 100px;
`

export const NavButtons = styled.div`
    display: flex;
    align-items: center;
    color: #D0D4D8;
    gap: 2rem;

    
`
export const NavButton = styled(LinkR)`
    text-decoration: none;
    color: #fff;
    opacity: 0.7;
    @media screen and (max-width: 768px){
        font-size: 0.9rem;
    }

    &:hover{
        opacity: 1;
    }
`
export const SocialLogos = styled.div`
    display: flex;
    gap: 2rem;
`

export const SocialLink = styled.a`
    opacity: 0.5;
    transition: all 400ms;
    cursor: pointer;
    &:hover{
        opacity: 1;
    }
`
export const SocialLogo = styled.img`
    opacity: 0.5;
    transition: all 400ms;
    cursor: pointer;
    &:hover{
        opacity: 1;
    }
`
export const CopyrightText = styled.p`
    color: #fff;
    opacity: 0.4;
    margin-top: 4rem;
`