import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background: #041562;
  animation: slide-in-top 500ms;
`;
export const ContentContainer = styled.div`
  width: 100%;
  max-width: 1100px;
  min-height: 360px;
  padding: 1rem;
  @media screen and (max-width: 768px) {
    height: fit-content;
  }
`;

export const Linkpath = styled.p`
  color: #f5f5f5;
`;

export const DetailsContainer = styled.div`
  display: flex;
  gap: 12px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
export const CourseIcon = styled.img`
  width: 100px;
  height: 100px;
  object-fit: contain;
  background: #fff;
  padding: 12px;
  border-radius: 12px;
  animation:  zoom-in 500ms;
`;
export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  animation: slide-in-left 500ms;
  flex: 1;
`;
export const CourseTitle = styled.h1`
  color: #fff;
  margin: 0;
  text-transform: capitalize;
`;

export const CourseDescription = styled.p`
  color: #f5f5f5;
  margin: 0;
  font-weight: 300;
`;

export const ContactContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  margin: 24px 0;
`;

export const WhatsappContainer = styled.a`
  cursor: pointer;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: all 200ms;
  &:hover {
    transform: scale(1.08);
  }
`;
export const BtnCall = styled.p`
  margin: 0;
  color: #fff;
  font-size: 14px;
`;

export const CallContainer = styled.a`
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: #fff;
  background: #fc3535;
  padding: 12px 18px;
  border-radius: 18px;
  text-transform: capitalize;
  font-weight: 600;
  transition: all 200ms;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  &:hover {
    background: #f92c2c;
    transform: scale(1.05);
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px,
      rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px,
      rgba(0, 0, 0, 0.07) 0px 16px 16px;
  }
`;

export const EmbedContainer = styled.div`
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  border-radius: 12px 12px 0 0;
  width: 420px;
  margin-bottom: calc(1rem + 18px);
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;