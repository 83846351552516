import styled from "styled-components"
export const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    
`

export const NoDataMessageContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
margin-top: 30px;
width: 100%;
`

export const NodataCover = styled.img`
width: 250px;

animation-name: zoomIn;
animation-duration: 1s;

@keyframes zoomIn {
    from {
        transform: scale(0.9);
    }
    to {transform: scale(1)}
}
`

export const ErrorText = styled.h3`

animation-name: slideDownIn;
animation-duration: 1s;

@keyframes slideDownIn {
    from {
        transform: translateY(-20px);
    }
    to {transform: translateY(0)}
}
`

export const TabContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    font-weight: 600;
`
export const Tab = styled.div`
    cursor: pointer;
    padding: .2rem 0;
    color: ${props => props.isSelected ? "#FC3535" : "#C9C9C9"};
    border-bottom: ${props => props.isSelected ? "2px solid #FC3535" : "2px solid #C9C9C9"};

`

export const Row = styled.div`
    display: flex;
`

export const Column = styled.div`
    display: flex;
    flex-direction: column;
`

export const WhatsappIcon = styled.img`
    width: 40px;
`

