import { Tooltip } from "@mui/material";
import { useState } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import courseService from "../../services/course.service";
import ModalConfirm from "../ModalConfirm";
import {
  ActionButton,
  ActionButtons,
} from "../PublicationItem/PublicationItemElements";
import {
  BtnKnowMore,
  DataContainer,
  SDesc,
  ServiceItemContainer,
  SIcon,
  STitle,
} from "./ServiceItemElements";

const CourseItem = (props) => {
  const { data, edit, onDeleteItem } = props;
  const navigate = useNavigate();

  const [confirmModal, showConfirmModal] = useState(false);
  const [modalData, setModalData] = useState({
    message: "",
    title: "",
  });

  const handleConfirmModalClose = (e) => {
    if (e) {
      onDelete();
    }
    showConfirmModal(false);
  };

  const [expand, setExpand] = useState(false);

  const onDelete = () => {
    courseService
      .deleteCourse(data._id)
      .then((res) => {
        onDeleteItem(data._id);
        showConfirmModal(false);
      })
      .catch((err) => console.log(err));
  };

  return (
    <ServiceItemContainer
      onClick={(e) => {
        if(e.currentTarget == e.target)
          navigate(`/courses/course/${data._id}`);
      }}
    >
      <SIcon src={data.photo} />
      <DataContainer to={`/courses/course/${data._id}`}>
        <STitle>{data.title}</STitle>
        <SDesc>{data.description}</SDesc>
      </DataContainer>
      {edit && (
        <ActionButtons>
          <Tooltip title="edit">
            <ActionButton
              bg="#757575"
              aria-label=""
              component="span"
              size="small"
              onClick={(e) => {
                e.preventDefault();
                navigate(`/dashboard/courses/edit-courses/${data._id}`);
              }}
            >
              <MdEdit />
            </ActionButton>
          </Tooltip>
          <Tooltip title="delete">
            <ActionButton
              bg="#B71C1C"
              aria-label=""
              component="span"
              size="small"
              onClick={(e) => {
                e.preventDefault();
                setModalData({
                  message: "Are you sure want to delete ?",
                  title: "Delete",
                });
                showConfirmModal(true);
              }}
            >
              <MdDelete />
            </ActionButton>
          </Tooltip>
        </ActionButtons>
      )}

      <ModalConfirm
        open={confirmModal}
        title={modalData.title}
        message={modalData.message}
        ok="Yes"
        cancel="No"
        onClose={handleConfirmModalClose}
      />
    </ServiceItemContainer>
  );
};

export default CourseItem;
