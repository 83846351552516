import { Button, IconButton } from "@mui/material";
import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";

export const ServiceItemContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  background: #fff;
  border: 1px solid #ededed;
  border-radius: 8px;
  padding: 1rem;
  margin: 0 1rem;
  text-decoration: none;
  position: relative;
  animation: slide-in-top 500ms;

`;

export const SIcon = styled.img`
  height: 120px;
  width: 120px;
  padding: 12px;
  border-radius: 12px;
  object-fit: contain;
  background: #e1f5fe;
  @media screen and (max-width: 768px) {
    width: 60px;
    height: 60px;
  }
`;
export const STitle = styled.p`
  color: #212121;
  font-weight: 500;
  margin: 0;

`;
export const SDesc = styled.p`
  color: #424242;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  display: -webkit-box;
  margin: 0;
  @media screen and (max-width: 768px) {
    -webkit-line-clamp: ${(props) => (props.expand ? "none" : "2")};
  }
  
  -webkit-box-orient: vertical;
  overflow: hidden;

`;
export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  gap: .5rem;
`;

export const BtnKnowMore = styled(Button)`
  background-color: #01579b !important;
  text-transform: capitalize !important;
`;

export const ActionButtons = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  margin: 0;
  padding-left: 0.5rem;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
`;

export const ActionButton = styled(IconButton)`
  color: ${(props) => props.bg} !important;
`;

export const BtnExpand = styled(Button)`
  align-self: flex-end;
`;
