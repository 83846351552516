import styled from "styled-components";

export const ContactContainer = styled.div`
display: flex;
align-items: center;
flex-direction: column;
gap: 12px;
margin: 24px 0;
position: fixed;
bottom: 12px;
right: 12px;
margin-right: 1rem;
z-index: 10;
@media screen and (max-width: 768px){
    margin-right: 12px;
}
`;

export const WhatsappContainer = styled.a`
cursor: pointer;
text-decoration: none;
-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
transition: all 200ms;
animation: zoom-in 2s infinite alternate;
&:hover {
  transform: scale(1.08);
}
`;
