import styled from "styled-components";

export const ServicesSectionContainer  = styled.div`
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    padding: 2rem;
    background: #e1f5fe8a;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    @media screen and (max-width: 768px){
      width: 100%;
      padding: 0;
    }
    width: 1000px;
    margin: auto;
    border-radius: 12px;
`
export const CarouselContainer = styled.div`
    width: 70%;
    height: 540px;
    @media screen and (max-width: 768px){
        gap: 0.5rem;
        width: 100%;
        height: unset;
    }
`
export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
`

export const TitlePartOne = styled.h2`
    color: #40c4ff;
`
export const TitlePartTwo = styled.h2`
    color: #01579b;
`
