import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import Loader from "../../components/Loader";
import { LoaderMain } from "../Loader/LoaderElements";
import loaderImage from "../../assets/logos/logo.svg";
import { BtnNav, BtnNavContainer, PDFWrapper } from "./PDFViewerElements";
import PDFLoader from "../PDFLoader";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi2";
import { IconButton } from "@mui/material";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFViewer = ({ file }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loader, setLoader] = useState(true);

  const onDocumentLoadSuccess = ({ _pdfInfo }) => {
    setNumPages(_pdfInfo.numPages);
    setLoader(false);
  };

  const onLoadError = ({ _pdfInfo }) => {
    setLoader(false);
  };

  const goToPrevPage = () =>
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

  const goToNextPage = () =>
    setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);

  return (
    <PDFWrapper className="pdf-wrapper">
      <Document
        file={{ url: file }}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={onLoadError}
        loading={<PDFLoader loader={true} />}
      >
        <Page
          pageNumber={pageNumber}
          renderAnnotationLayer={false}
          renderTextLayer={false}
        />
      </Document>
      <PDFLoader loader={loader} />
      <BtnNavContainer>
        <IconButton aria-label="prev" onClick={goToPrevPage} >
          <HiChevronLeft />
        </IconButton>

        <p>
          {pageNumber} / {numPages}
        </p>
        <IconButton aria-label="next" onClick={goToNextPage} >
          <HiChevronRight />
        </IconButton>
      </BtnNavContainer>
    </PDFWrapper>
  );
};

export default PDFViewer;
