import { Button, IconButton } from "@mui/material";
import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";

export const ServiceItemContainer = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
  flex-direction:column ;
  background: #fff;
  border: 1px solid #ededed;
  border-radius: 12px;
  padding: 1rem;
  text-decoration: none;
  position: relative;

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 1rem;
    justify-content: center;
    gap: 0.5rem;
    border-radius: 16px;
  }
`;

export const CoverContainer = styled.div`
  background: #f5f5f5;
  position: relative;
  width: 100%;
  padding-top: 56.25%;
`;

export const SIcon = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  object-fit: cover;
  object-position: left;
`;
export const STitle = styled.p`
margin-bottom: 5px;
  color: #212121;
  font-weight: 500;
`;
export const SDesc = styled.p`
  color: #424242;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
`;

export const SCat = styled.p`
  color: #717171;
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 24px;
  

`;
export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6px;
`;

export const BtnKnowMore = styled(Button)`
  background-color: #01579b !important;
  text-transform: capitalize !important;
`;

export const ActionButtons = styled.div`
  margin: 0;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  align-self: flex-end;
`;

export const ActionButton = styled(IconButton)`
  color: ${(props) => props.bg} !important;
`;

export const BtnExpand = styled(Button)`
  align-self: flex-end;
`;
