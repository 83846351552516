
import styled from "styled-components";

export const LoaderMain = styled.img`

    animation: zoominOut 2s infinite;

    @keyframes zoominOut {
        0% {transform: scale(0.8)}
        50% {transform: scale(1)}
        100% {transform: scale(0.8)}
    }
` 

