import {
  BtnKnowMore,
  CoverContainer,
  DataContainer,
  SDesc,
  ServiceItemContainer,
  SIcon,
  STitle,
} from "./ServiceItemElements";

const AchieverItem = (props) => {
  const { data } = props;
  return (
    <ServiceItemContainer>
      <CoverContainer>
      <SIcon src={data.photo} />
      </CoverContainer>
      <STitle>{data.title}</STitle>
    </ServiceItemContainer>
  );
};

export default AchieverItem;
