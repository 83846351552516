import React from "react";
import { WhatsappIcon } from "../../globalStyle";
import {
  ContactContainer,
  WhatsappContainer,
} from "./PopIconsElements";

import logoWhatsapp from "../../assets/logos/whatsapp.svg";
import logoCall from "../../assets/logos/call.svg";

const PopIcons = ({call,whatsapp}) => {
    
    const uri = encodeURI("https://wa.me/+91"+whatsapp+"?text=I'm inquiring about ");
    
  return (
    <ContactContainer>
      <WhatsappContainer href={"tel:+91" + call}>
        <WhatsappIcon src={logoCall} />
      </WhatsappContainer>
      <WhatsappContainer href={uri}>
        <WhatsappIcon src={logoWhatsapp} />
      </WhatsappContainer>
    </ContactContainer>
  );
};

export default PopIcons;
