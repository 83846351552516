import {
    BtnKnowMore,
    BtnView,
    DataContainer,
  SDesc,
  ServiceItemContainer,
  SIcon,
  STitle,
} from "./ServiceItemElements";

const ServiceItem = (props) => {
  const { data, logo } = props;
  return (
    <ServiceItemContainer to={`/courses/${data.name}/courses`}>
      {/* <SIcon src={logo} />
      <STitle>{data.name}</STitle>
      <BtnView>View courses</BtnView> */}
      <SIcon src={logo} />
       <DataContainer>
        <STitle>{data.name}</STitle>
        <BtnView>View courses</BtnView>
      </DataContainer>
    </ServiceItemContainer>
  );
};

export default ServiceItem;
