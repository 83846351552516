import http from "../http-common";
import authHeader from "./auth-header";

class EnquiryService{

    getAllEnquiries(){
        return http.get(`/enquiry/all`, {headers: authHeader(), withCredentials: true});
    }




    submitNewEnquiry(data){
        return http.post(`/public/enquiry/submit-enquiry`, JSON.stringify(data),{headers: authHeader(), withCredentials: true});

    }

    

  
}


export default new EnquiryService();