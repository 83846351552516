import { useEffect, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { Navigate} from "react-router-dom"
import DashContentTitle from "../../components/DashContentTitles"
import { AllProductsContainer, BtnExport, Container, PhonesList, ProductsContainer, ProductsSection, SubNavBar} from "./ChirakuEntriesElements"
import noDataCover from '../../assets/images/no_data.svg'
import Loader from '../../components/Loader';
import courseService from "../../services/course.service"
import CourseItem from "../../components/CourseItem"
import { ErrorText, NodataCover, NoDataMessageContainer } from "../../globalStyle"
import EnquiryItem from "../../components/EnquiryItem"
import { ENQUIRIES, VIEW_ENTRIES } from "../../actions/types"
import { RiFileExcel2Fill } from "react-icons/ri"
import ExcelExport from "../../components/ExcelExport"
import chirakuRegistrationService from "../../services/chirakuRegistration.service"
import ChirakuEntryItem from "../../components/ChirakuEntryItem"

const ChirakuEntries = (props) => {

    const dispatch = useDispatch();
    const { user: currentUser } = useSelector((state) => state.auth);
    

    const [entries, setEntries] = useState([]);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        dispatch({
            type: VIEW_ENTRIES,
          });
        getEntries();
      }, []
    );


    if (!currentUser) {
        return <Navigate to="/login" />;
    }


    const getEntries = () => {
        setEntries([]);
        setLoader(true);
        chirakuRegistrationService.getAllEntries()
        .then((res) => {
            
            setEntries(res.data.map( item => {
                return {...item, course: item.course.title}
            }));
            setLoader(false);
        }).catch(() => {
                setLoader(false);
        });
        
    }






    const enquiryList = entries.map((item, index) => {
        return <ChirakuEntryItem
        key={item._id}
        data={item}
        />
    })
    
    return (
        <Container>
            {/* <DashActionbar onChange= {(tag)=> searchProducts(tag)}/> */}
            <AllProductsContainer>
                <DashContentTitle/>
                <ExcelExport excelData={entries} fileName="Cubit Chiraku Registrations List"/>
                <ProductsSection>
                    <ProductsContainer>
                        <PhonesList>
                            {entries && enquiryList}
                            {(entries.length === 0 && loader === false) ? (
                            <NoDataMessageContainer>
                                <NodataCover src={noDataCover}/>
                                <ErrorText>No entries found!</ErrorText>
                            </NoDataMessageContainer>
                            ):(null)}
                        </PhonesList>
                        
                    </ProductsContainer>
                </ProductsSection>
            <Loader loader={loader}/>
            </AllProductsContainer>
        </Container>
        
    )
}

export default ChirakuEntries;
