import { Button } from "@mui/material";
import styled from "styled-components";
import {Link as LinkR} from 'react-router-dom'

export const ServiceItemContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    border-radius: 6px;
    padding: 1rem;
    margin: 0 1rem;
    cursor: pointer;
    text-decoration: none;
    animation: slide-in-top 500ms;
    position: relative;
`

export const SIcon = styled.img`
    height: 120px;
    width: 120px;
    padding: 12px;
    border-radius: 12px;
    object-fit: contain;
    background: #E1F5FE;
    @media screen and (max-width: 768px){
       width:60px;
       height: 60px;
       padding: 8px;
    border-radius: 8px;
    }
`
export const STitle = styled.h3`
    color: #01579B;
    margin: 0;
    text-transform: capitalize;
   
`
export const SDesc = styled.p`
margin-top: 4px;
    color: rgba(15, 15, 15, 0.5);
    font-weight: 300;
    display: -webkit-box;
  
  @media screen and (max-width: 768px){
    -webkit-line-clamp: 2;
    }
  -webkit-box-orient: vertical;  
  overflow: hidden;

`
export const DataContainer = styled(LinkR)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-decoration: none;
`

export const BtnKnowMore = styled(Button)`
    background-color: #01579B !important;
    text-transform: capitalize !important;
`