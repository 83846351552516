import React from "react";
import {
  CategoriesInput,
  CategoriesOption,
  InputBoxContainer,
  InputContainer,
  InputError,
  InputTitle,
} from "./SelectInputElements";

const SelectInput = (props) => {
  const { title, name, value, onChange, error, options, placeholder } = props;


  const departmentsOptions = options.map((option) => {
    return (
      <CategoriesOption key={option.value} value={option.value}>
        {option.title}
      </CategoriesOption>
    );
  });

  

  return (
    <InputContainer>
      <InputTitle>{title} :</InputTitle>
      <InputBoxContainer>
        <CategoriesInput
          error={error}
          name={name}
          value={value}
          onChange={(e) => {
            onChange(e);
          }}
        >
          <CategoriesOption value="">{placeholder}</CategoriesOption>
          {options && departmentsOptions}
        </CategoriesInput>
        <InputError>{error}</InputError>
      </InputBoxContainer>
    </InputContainer>
  );
};

export default SelectInput;
