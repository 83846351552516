import { useDispatch, useSelector } from "react-redux";
import { MobileNavContainer, MobileNavOption, NavTitle } from "./MobileNavElements"
import { logout } from "../../actions/auth";
const MobileNav = () => {

    const dispatch = useDispatch();

    const { isLoggedIn } = useSelector(state => state.auth);
    const onClickLogout = () => {
        dispatch(logout());
    }

    return (
        <MobileNavContainer>
            <MobileNavOption to='/'>
                <NavTitle>Home</NavTitle>
            </MobileNavOption>
            <MobileNavOption to='/about'>
                <NavTitle>About</NavTitle>
            </MobileNavOption>
            <MobileNavOption to="/chiraku">
                <NavTitle>Chiraku</NavTitle>
            </MobileNavOption>
            <MobileNavOption to="/courses/categories">
                <NavTitle>Courses</NavTitle>
            </MobileNavOption>
            <MobileNavOption to="/publications">
                <NavTitle>Publications</NavTitle>
            </MobileNavOption>
            <MobileNavOption to="/contact">
                <NavTitle>Contact</NavTitle>
            </MobileNavOption>

            {/* {isLoggedIn === true ? (
                <>
                    <MobileNavOption to='/login'>
                        <NavTitle>Dashboard</NavTitle>
                    </MobileNavOption>

                    <MobileNavOption to='/login' onClick={onClickLogout}>
                        <NavTitle>Log out</NavTitle>
                    </MobileNavOption>
                </>
            ) : (
                <MobileNavOption to='/login'>
                    <NavTitle>Log In</NavTitle>
                </MobileNavOption>
            )} */}
        </MobileNavContainer>
    )
}

export default MobileNav
