import React from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import NewsItemSmall from "../NewsItemSmall";

const CarouselNews = (props) => {

    const {data} = props;
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 3 // optional, default to 1.
          },
          tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 1 // optional, default to 1.
          },
          mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
          }
      };


    const itemsList = data.map((item, index) => {
        return <NewsItemSmall key={item._id} data={item} edit={true} />;
      });
    

    return (
        <Carousel responsive={responsive} autoPlay={true} infinite={true} arrows={false} autoPlaySpeed={3000} >
            {
                itemsList
            }
        </Carousel>
    )
};

export default CarouselNews;
