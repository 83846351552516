import { useEffect, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { Navigate} from "react-router-dom"
import DashContentTitle from "../../components/DashContentTitles"
import { AllProductsContainer, BtnExport, Container, PhonesList, ProductsContainer, ProductsSection, SubNavBar} from "./EnquiriesElements"
import noDataCover from '../../assets/images/no_data.svg'
import Loader from '../../components/Loader';
import courseService from "../../services/course.service"
import CourseItem from "../../components/CourseItem"
import { ErrorText, NodataCover, NoDataMessageContainer } from "../../globalStyle"
import DashActionbar from "../../components/DashActionBar"
import enquiryService from "../../services/enquiry.service"
import EnquiryItem from "../../components/EnquiryItem"
import { ENQUIRIES } from "../../actions/types"
import { RiFileExcel2Fill } from "react-icons/ri"
import ExcelExport from "../../components/ExcelExport"

const Enquiries = (props) => {

    const dispatch = useDispatch();
    const { user: currentUser } = useSelector((state) => state.auth);
    

    const [enquiries, setEnquiries] = useState([]);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        dispatch({
            type: ENQUIRIES,
          });
        getEnquiries();
      }, []
    );


    if (!currentUser) {
        return <Navigate to="/login" />;
    }


    const getEnquiries = () => {
        setEnquiries([]);
        setLoader(true);
        enquiryService.getAllEnquiries()
        .then((res) => {
            
            setEnquiries(res.data);
            setLoader(false);
        }).catch(() => {
                setLoader(false);
        });
        
    }




    const searchProducts = (tag) => {
        if(tag !== ''){
            courseService.getAllCourses()
            .then((res) => {
                setEnquiries(res.data.products);
            });
        } else {
            
            getEnquiries();
        }
        
    }

    const handleDeleteProduct = (e) => {
        const  newCourses = enquiries.filter( el => el._id !== e);
        setEnquiries(newCourses);
    }



    const enquiryList = enquiries.map((item, index) => {
        return <EnquiryItem
        key={item._id}
        data={item}
        edit={true}
        />
    })
    
    return (
        <Container>
            {/* <DashActionbar onChange= {(tag)=> searchProducts(tag)}/> */}
            <AllProductsContainer>
                <DashContentTitle title= "all products"/>
                <ExcelExport excelData={enquiries} fileName="Cubit Enquiries List"/>
                <ProductsSection>
                    <ProductsContainer>
                        <PhonesList>
                            {enquiries && enquiryList}
                            {(enquiries.length === 0 && loader === false) ? (
                            <NoDataMessageContainer>
                                <NodataCover src={noDataCover}/>
                                <ErrorText>No enquiries found!</ErrorText>
                            </NoDataMessageContainer>
                            ):(null)}
                        </PhonesList>
                        
                    </ProductsContainer>
                </ProductsSection>
            <Loader loader={loader}/>
            </AllProductsContainer>
        </Container>
        
    )
}

export default Enquiries
