import { Button, IconButton } from "@mui/material";
import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";

export const ServiceItemContainer = styled(LinkR)`
  width: 225px;
  background: #fff;
  border: 1px solid #ededed;
  border-radius: 12px;
  text-decoration: none;
  animation: slide-in-top 500ms;
  overflow: hidden;
  color: #212121;

  @media screen and (max-width: 768px){
       width: 100%;
       margin: 10px;
    }
  
`;

export const PhotoContainer = styled.div`
  height: 225px;
  background-image: radial-gradient(#f5f5f5, #E0E0E0);
  padding: 12px;
`;
export const SIcon = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
`;
export const STitle = styled.p`
  color: #212121;
  font-weight: 500;
  margin: 0;
  font-size: 16px;
`;
export const SPrice = styled.h3`
  color: #000;
    margin-bottom: 0;
`;
export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  background: #fff;
  padding: 12px;
`;

export const BtnKnowMore = styled(Button)`
  background-color: #01579b !important;
  text-transform: capitalize !important;
`;

export const ActionButtons = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  margin: 0;
  padding-left: 0.5rem;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
`;

export const ActionButton = styled(IconButton)`
  color: ${(props) => props.bg} !important;
`;

export const BtnExpand = styled(Button)`
  align-self: flex-end;
`;
