import React, { useEffect, useState } from "react";
import CourseHeroSection from "../../components/CourseHeroSection";
import {
  Container,
  ErrorText,
  NodataCover,
  NoDataMessageContainer,
} from "../../globalStyle";
import noDataCover from "../../assets/images/no_data.svg";
import {
  AchieverConrainer,
  AchieverContainer,
  CenterPanel,
  ContentContainer,
  Desc,
  EmbedContainer,
  ListContainer,
  RightPanel,
  RightPanelTitle,
  TabContainer,
  TestimonialContainer,
} from "./ChirakuElements";
import { useParams } from "react-router-dom";
import courseService from "../../services/course.service";
import AchieverItem from "../../components/AchieverItem";
import achieverService from "../../services/achiever.service";
import CarouselAuto from "../../components/CarouselAuto";
import CarouselAchievers from "../../components/CarouselAchievers";
import YoutubeEmbed from "../../components/YoutubeEmbed";
import SubjectItem from "../../components/SubjectItem";
import testimonialService from "../../services/testimonial.service";
import demoClassService from "../../services/demoClass.service";
import { Tabs } from "@mui/material";
import DemoYoutubeEmbed from "../../components/DemoYoutubeEmbed";
import materialsService from "../../services/materials.service";
import MaterialsItem from "../../components/MaterialsItem";
import ChirakuHeroSection from "../../components/ChirakuHeroSection";
import chirakuService from "../../services/chiraku.service";
import ChirakuContactSection from "../../components/ChirakuContactSection";
import PopIcons from "../../components/PopIcons";
import YoutubeEmbedVideo from "../../components/YoutubeEmbedVideo";

const Chiraku = () => {
  const { id } = useParams();

  const [value, setValue] = useState("1");
  const [data, setData] = useState({
    _id: "",
    photo: "",
    title: "",
    description: "",
    video: "",
  });

  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    getChirakuDetails();
    getAllTestimonials();
  }, []);

  const getChirakuDetails = () => {
    chirakuService
      .getChirakuDetails()
      .then((res) => {
        setData(res.data);
      })
      .catch(() => {});
  };

  const getAllTestimonials = () => {
    testimonialService
      .getAllChirakuTestimonials()
      .then((res) => {
        setTestimonials(res.data);
      })
      .catch(() => {});
  };

  const testimonialList = testimonials.map((item, index) => {
    return (
      <YoutubeEmbed
        link={item.link}
        title={item.title}
        description={item.description}
      />
    );
  });

  return (
    <Container>
      {data ? (
        <>
          <ChirakuHeroSection
            title={data.title}
            photo={data.photo}
            video={data.video}
            call={data.call}
            whatsapp={data.whatsapp}
          />
          <ContentContainer>
            <CenterPanel>
              <Desc>{data.description}</Desc>
              {data.video && (
                <EmbedContainer>
                  <YoutubeEmbedVideo link={data.video} />
                </EmbedContainer>
              )}
              <ChirakuContactSection />
            </CenterPanel>
            <RightPanel>
              <TestimonialContainer>
                <RightPanelTitle>Testimonials</RightPanelTitle>
                <ListContainer>{testimonials && testimonialList}</ListContainer>
              </TestimonialContainer>
            </RightPanel>
          </ContentContainer>
          <PopIcons call={data.call} whatsapp={data.whatsapp} />
        </>
      ) : (
        <NoDataMessageContainer>
          <NodataCover src={noDataCover} />
          <ErrorText>No details found!</ErrorText>
        </NoDataMessageContainer>
      )}
      
    </Container>
  );
};

export default Chiraku;
