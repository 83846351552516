import styled from "styled-components";

export const Container = styled.div`
    
    border-radius: 12px;
    width: 100%;
    overflow: hidden;
    text-align: center;
`;
export const EmbedContainer = styled.div`
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  border-radius: 12px 12px 0 0;
  
`;

export const EmbedIframe = styled.iframe`
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
`;

export const EmbedTitle = styled.p`
    margin-bottom: 0;
    text-transform: capitalize;
`;

export const EmbedDesc = styled.p`
    font-weight: 300;
    font-size: 14px;
    color: #424242;
    line-height: 24px;
`;