import DashContentTitle from "../../components/DashContentTitles";
import { useDispatch, useSelector } from "react-redux";
import {
  BtnCancel,
  BtnSubmit,
  BtnUpload,
  ButtonContainer,
  CategoriesInput,
  CategoriesOption,
  CurrencyType,
  FormAreaContainer,
  FormContainer,
  FormItem,
  EmbedContainer,
} from "./AddDemoElements";
import icPicker from "../../assets/icons/ic_image_picker.svg";
import { useEffect, useRef, useState } from "react";
import ProductsService from "../../services/products-service";
import UploadImagesService from "../../services/upload-files-service";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import ModalResult from "../../components/ModalResult";
import ModalConfirm from "../../components/ModalConfirm";
import { ADD_DEMO, ADD_TESTIMONIALS, EDIT_DEMO, EDIT_TESTIMONIALS } from "../../actions/types";
import categoryService from "../../services/category.service";
import SelectInput from "../../components/SelectInput/SelectInput";
import TextinputHorizontal from "../../components/TextInputHorizontal/TextinputHorizontal";
import TextareaInput from "../../components/TextareaInput/Textareainput";
import courseService from "../../services/course.service";
import achieverService from "../../services/achiever.service";
import testimonialService from "../../services/testimonial.service";
import YoutubeEmbed from "../../components/YoutubeEmbed";
import demoClassService from "../../services/demoClass.service";

const AddDemo = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const imageInput = useRef(null);

  const [modal, showModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const [confirmModal, showConfirmModal] = useState(false);
  const [modalData, setModalData] = useState({
    message: "",
    title: "",
  });
  const handleConfirmModalClose = (e) => {
    if (e) {
      updateProduct();
    }
    showConfirmModal(false);
  };

  const [embID, setEmbID] = useState("");
  const [data, setData] = useState({
    link: "",
    title: "",
    description: "",
    category: "",
    course: "",
  });

  const [fieldstatustext, setFieldstatusText] = useState({
    link: "",
    title: "",
    description: "",
    category: "",
    course: "",
  });

  const [pId, setPId] = useState();

  const [categories, setCategories] = useState([]);
  const [courseNames, setCourseNames] = useState([]);

  useEffect(() => {
    
    getCategories();
    if (id) {
      dispatch({
        type: EDIT_DEMO,
      });
      getDetails();
    } else {
      dispatch({
        type: ADD_DEMO,
      });
    }
  }, []);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  const getDetails = () => {
    demoClassService
      .getDemoClass(id)
      .then((res) => {
        
        setData({
          link: res.data.link,
          title: res.data.title,
          description: res.data.description,
          category: res.data.category,
          course: res.data.course,
        });
        const splittedLink = res.data.link.split("/");
        setEmbID(splittedLink[splittedLink.length - 1]);
        getCourseNames(res.data.category);
      })
      .catch(() => {});
  };

  const getCategories = () => {
    categoryService.getAllCourseCategories().then((res) => {
      setCategories(
        res.data.map((cat) => ({ value: cat._id, title: cat.name }))
      );
    });
  };

  const getCourseNames = (catID) => {
    courseService.getCoursesNameByCatID(catID).then((res) => {
      setCourseNames(
        res.data.map((cat) => ({ value: cat._id, title: cat.title }))
      );
    });
  };

  const handleInputChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    setData({
      ...data,
      [name]: value,
    });

    if (name == "category") {
      getCourseNames(value);
    }

    // sets embID from Url
    if (name == "link") {
      const splittedLink = value.split("/");
      setEmbID(splittedLink[splittedLink.length - 1]);
    }
  };

  const handleModalClose = (e) => {
    showModal(false);
    if (id) {
      navigate(-1);
    }
  };

  const addNewProduct = () => {
    if (validateForm()) {
      
      demoClassService
        .addNewDemo(data)
        .then((res) => {
          setModalMessage("Demo class added successfully!");
          showModal(true);
          onReset();
        })
        .catch((err) => console.log(err));
    }
  };

  const updateProduct = () => {
    if (validateForm()) {
      demoClassService
        .updateDemoClasses(id, data)
        .then((res) => {
          setModalMessage("Demo class details updated successfully!");
          showModal(true);
        })
        .catch((err) => console.log(err));
    }
  };

  const onCancel = () => {
    if (id) {
      navigate(-1);
    }
  };

  const onReset = () => {
    setData({
      link: "",
      title: "",
      description:"",
      category: "",
      course: "",
    });
    setFieldstatusText({
      link: "",
      title: "",
      description:"",
      category: "",
      course: "",
    });
  };

  const validateForm = () => {
    if (data.link === "") {
      setFieldstatusText({
        ...fieldstatustext,
        link: "* required",
      });
      alert("Video link required!");
      return false;
    }
    if (data.title === "") {
      setFieldstatusText({
        ...fieldstatustext,
        title: "* required",
      });
      alert("Title required!");
      return false;
    }

    if (data.category === "") {
      setFieldstatusText({
        ...fieldstatustext,
        category: "* required",
      });
      alert("Category required!");
      return false;
    }

    return true;
  };

  return (
    <FormAreaContainer>
      {id ? (
        <DashContentTitle title="Update product" />
      ) : (
        <DashContentTitle title="add products" />
      )}

      <FormContainer>
        <EmbedContainer>
          <YoutubeEmbed link={data.link} />
        </EmbedContainer>

        <TextinputHorizontal
          type="text"
          textTransform="capitalize"
          title="Video link"
          error={fieldstatustext["link"]}
          placeholder="Paste url"
          name="link"
          maxLength={600}
          value={data.link}
          onChange={(e) => handleInputChange(e)}
        />
        <TextinputHorizontal
          type="text"
          textTransform="capitalize"
          title="title"
          error={fieldstatustext["title"]}
          placeholder="Enter Title"
          name="title"
          maxLength={20}
          value={data.title}
          onChange={(e) => handleInputChange(e)}
        />
        <TextareaInput
          type="text"
          title="description"
          error={fieldstatustext["description"]}
          placeholder="Description"
          name="description"
          rows="4"
          value={data.description}
          onChange={(e) => handleInputChange(e)}
        />

        <SelectInput
          title="Course category"
          error={fieldstatustext["category"]}
          name="category"
          placeholder="Choose category"
          options={categories}
          value={data.category}
          onChange={(e) => handleInputChange(e)}
        />
        <SelectInput
          title="Course Name"
          error={fieldstatustext["course"]}
          name="course"
          placeholder="Choose Course Name"
          options={courseNames}
          value={data.course}
          onChange={(e) => handleInputChange(e)}
        />
        <FormItem>
          <ButtonContainer>
            <BtnCancel onClick={onCancel}>Cancel</BtnCancel>
            {id ? (
              <BtnSubmit
                onClick={(e) => {
                  setModalData({
                    message: "Are you sure want to update ?",
                    title: "Update",
                  });
                  showConfirmModal(true);
                }}
              >
                Update
              </BtnSubmit>
            ) : (
              <BtnSubmit onClick={addNewProduct}>Submit</BtnSubmit>
            )}
          </ButtonContainer>
        </FormItem>
      </FormContainer>
      <ModalResult
        open={modal}
        message={modalMessage}
        onClose={handleModalClose}
      />
      <ModalConfirm
        open={confirmModal}
        title={modalData.title}
        message={modalData.message}
        ok="Yes"
        cancel="No"
        onClose={handleConfirmModalClose}
      />
    </FormAreaContainer>
  );
};

export default AddDemo;
