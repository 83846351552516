import styled from "styled-components";
import {NavLink as LinkR} from "react-router-dom"

export const MenuItemContainer = styled(LinkR)`
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    cursor: pointer;
    color: #fff;
    opacity: .5;
    text-decoration: none;
    transition: all 200ms;

    &:hover{
        background: rgba(255, 255, 255, 0.1);
        border-radius: 4px;
        opacity: 1;
        
    }

`

export const MenuItemIcon = styled.img`
    width: 1rem;
    height: 1rem;
    color: #fff;
`
export const MenuItemTitle = styled.div`
    text-transform: capitalize; 
    flex: 1;
    margin: 0;
    font-weight: 500;

`

export const IcExpand = styled.img`
    
`