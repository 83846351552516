import { NavbarContentContainer, NavbarContainer, NavLink, NavLinksContainer, NavLogo, NavButtonsContainer, BtnLogin, SearchContainer, SearchInput, SearchIcon, SearchItemsContainer, MobileIcon, MobileNavContainer, NavLogoLink, LinksContainer } from "./Navbar.elements"
import logo from '../../assets/logos/logo.svg'
import icSearch from "../../assets/icons/ic_search_mob.svg"
import { useSelector } from 'react-redux';
import { useState } from "react";
import { Divide as Hamburger } from 'hamburger-react';
import MobileNav from "../MobileNav";

const Navbar = () => {

    const { isLoggedIn } = useSelector(state => state.auth);

    const [searchItems, setSearchItems] = useState([]);
    const [business, setBusiness] = useState();
    const [searchTag, setSearchTag] = useState();
    const [mobileNav, setMobileNav] = useState("0");
    const [toggleSearch, setToggleSearch] = useState("0");



    const handleMobileIcon = () =>  {
      
        mobileNav === "0" ? setMobileNav("calc(100vh - 80px)") : setMobileNav("0")
    }

    const onToggleSearch = () =>  {
        toggleSearch === "0" ? setToggleSearch("100%") : setToggleSearch("0")
    }

    
    


    return (
        <>
            <NavbarContainer>
                <NavbarContentContainer>
                    <NavLogoLink to= '/'>
                    <NavLogo src={logo} />
                    </NavLogoLink>
                    
                    
                    <LinksContainer>
                    <NavLinksContainer className="navbar">
                        <NavLink to= '/about'>About</NavLink>
                        <NavLink to= '/chiraku'>Chiraku</NavLink>
                        <NavLink to= '/courses/categories'>Courses</NavLink>
                        <NavLink to= '/publications'>Publications</NavLink>
                        <NavLink to= '/contact'>Contact</NavLink>
                        
                    </NavLinksContainer>
                    <MobileIcon onClick={handleMobileIcon}>
                        <Hamburger toggled={mobileNav !== "0"} size={30} color="#03579B"/>
                    </MobileIcon>
                    <NavButtonsContainer>
                        {isLoggedIn ? (
                            <BtnLogin to='/dashboard'>DASHBAORD</BtnLogin>
                        ):(
                            <BtnLogin to='/login'>LOGIN</BtnLogin>
                        )}
                        
                    </NavButtonsContainer>
                    </LinksContainer>
                        
                </NavbarContentContainer>
            </NavbarContainer>
            <MobileNavContainer  height={mobileNav} onClick={handleMobileIcon}>
                <MobileNav/>
            </MobileNavContainer>
    </>
    )
}

export default Navbar
