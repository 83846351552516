import styled from "styled-components";

export const CaptionContainer = styled.div`
  flex: 1;

  animation-name: SlideFromLeft;
  animation-duration: 1s;

  @keyframes SlideFromLeft {
    from {
      transform: translateX(-20px);
    }
    to {
      transform: translateX(0);
    }
  }

  @media screen and (max-width: 768px) {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
`;
export const WelcomeText = styled.p`
  color: #1e5128;
  font-size: 1.2rem;
  text-transform: uppercase;
  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
`;
export const HeroSubTitle = styled.p`
  width: 80%;
  opacity: 0.6;
  font-size: 1.2rem;
  margin-bottom: 3rem;
  color: #424242;
  @media screen and (max-width: 768px) {
    text-align: center;
    margin: 0;
  }
`;
export const HeroTitle = styled.h1`
  color: #1b262c;
  text-transform: uppercase;

  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
    text-align: center;
    margin: 0;
  }
`;
export const StrongText = styled.span`
  color: #0f4c75;
`;
