import ServiceItem from "../ServiceItem";
import {
  BtnView,
  ServicesContents,
  ServicesSectionContainer,
  TitleContainer,
  TitlePartOne,
  TitlePartTwo,
} from "./ServicesElements";
import { useEffect } from "react";
import logoBank from "../../assets/logos/bank_logo.png";
import logoPSC from "../../assets/logos/psc_logo.png";
import logoRRB from "../../assets/logos/rrb_logo.png";
import logoSSC from "../../assets/logos/ssc_logo.png";
import logoCET from "../../assets/logos/cet_logo.svg";
import { useState } from "react";
import categoryService from "../../services/category.service";

const ServicesSection = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getCategories();
  }, []);

  //   const getCourses = () => {
  //     courseService
  //       .getLimitedCourses(3)
  //       .then((res) => {
  //         setCourses(res.data);
  //       })
  //       .catch(() => {});
  //   };

  const getCategories = () => {
    categoryService.getAllCourseCategories().then((res) => {
      setCategories(res.data);
    });
  };

  const setLogo = (name) => {
    switch (name) {
      case "bank":
        return logoBank;
      case "ssc":
        return logoSSC;
      case "psc":
        return logoPSC;
      case "rrb":
        return logoRRB;
      case "cet":
        return logoCET;

      default:
        return "";
    }
  };

  const serviceItems = categories.map((item, index) => {
    return (
      <ServiceItem
        key={index}
        data={item}
        logo={setLogo(item.name.toLowerCase())}
      />
    );
  });
  return (
    <ServicesSectionContainer>
      <TitleContainer>
        <TitlePartOne>COURSES</TitlePartOne>
        <TitlePartTwo>WE OFFER</TitlePartTwo>
      </TitleContainer>
      <ServicesContents>{serviceItems}</ServicesContents>
      {/* <BtnView to="/courses/all/courses">View all courses</BtnView> */}
    </ServicesSectionContainer>
  );
};

export default ServicesSection;
