import styled from "styled-components";
import {Link as LinkR} from "react-router-dom"
export const HomeContainer = styled.div`
    margin: 0;
    padding: 0;
    font-weight: 500;
    position: relative;
    
`
export const HeroContainer = styled.div`
    width: 100%;
    height:70vh;
    position: relative;
    background: #fff;

    
    
`
export const ContentContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    display: flex;
    justify-content: space-between;
    max-width: 1100px;

    
`
export const PhonesContainer = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    
`
export const ImgPhone = styled.img`
    width: 350px;
`

export const HeroBg = styled.img`
    width: 100%;
    object-fit: cover;
`

export const CarouselContainer = styled(LinkR)`
    width: 100%;
    display: flex;
    justify-content: center;
    cursor: pointer;
    @media screen and (max-width: 768px){
        padding: 1rem;
        width: fit-content;
    }
    
    
`
export const HeroIphone = styled.img`
    width: 100%;
    max-width: 1100px;
    margin: 50px 0;
    @media screen and (max-width: 768px){
       margin: 0;
    }

`

export const CaptionContainer = styled.div`
    color: #fff;
    flex: 1;
    display: flex;
    @media screen and (max-width: 768px){
       display: flex;
       flex-direction: column;
       align-items: center;
       padding: 1rem;
       gap:1rem;
    }
`

export const WelcomeText = styled.p`
    color: #fff;
    font-size: 1.2rem;
    text-transform: uppercase;
    @media screen and (max-width: 768px){
       font-size: 1rem;
    }
    
`
export const HeroSubTitle = styled.p`
    width: 80%;
    opacity: 0.6;
    margin-bottom: 3rem;
    @media screen and (max-width: 768px){
       text-align: center;
    }
`
export const HeroTitle = styled.h1`
    color: #fff;
    
    @media screen and (max-width: 768px){
       font-size: 1.5rem;
       text-align: center;
    }
`
export const StrongText = styled.span`
    color: #D95757;
`
export const BtnShop = styled(LinkR)`
    text-decoration: none;
    color: #fff;
    background: #FC3535;
    padding: 10px 18px;
    border-radius: 4px;
    text-transform: capitalize;
    font-weight: 600;
    transition: all 400ms;
    &:hover{
        box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    }
`
export const NetVector = styled.img`
    position: absolute;
    top: -20px;
    left: -50px;
    width: 100px;

    @media screen and (max-width: 768px){
        top: -1rem;
        left: 1rem;
    }
`

export const EmbedContainer = styled.div`
display: flex;
flex-direction: column;
gap: 24px;
align-items: center;
    flex:1;
    @media screen and (max-width: 768px){
        width: 100%;
    }
    animation: slide-in-right 500ms;
`

export const TestLink = styled.a`
    text-decoration: none;
    color: #424242;
    border: 2px solid #424242;
    padding: 12px 18px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
    display: flex;
    align-items: center;
    gap: 12px;
`