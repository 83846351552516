import ServiceItem from "../ServiceItem";
import {
  CarouselContainer,
  ServicesSectionContainer,
  TitleContainer,
  TitlePartOne,
  TitlePartTwo,
} from "./AchievementsSectionElements";
import { useEffect, useState } from "react";
import CarouselAuto from "../CarouselAuto";
import achieverService from "../../services/achiever.service";
import AchieverItem from "../AchieverItem";
import CarouselAchievers from "../CarouselAchievers";

const AchievementsSection = () => {
  const [achievers, setAchievers] = useState([]);


  useEffect(()=> {
    getAchievers();
  },[]);

  const getAchievers = () => {
    achieverService
      .getAllAchievers()
      .then((res) => {
        setAchievers(res.data);
      })
      .catch(() => {});
  };

  return (
    <ServicesSectionContainer>
      <TitleContainer>
        <TitlePartOne>OUR</TitlePartOne>
        <TitlePartTwo>ACHIEVEMENTS</TitlePartTwo>
      </TitleContainer>
      <CarouselContainer>
        {achievers && <CarouselAchievers items={achievers} />}
      </CarouselContainer>
    </ServicesSectionContainer>
  );
};

export default AchievementsSection;
